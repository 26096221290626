const _ = require('lodash');
const async = require('async');
const assert = require('assert');
const {i18n: {translate}} = require('fack');
const FileUploaderWidget = require('../../FileUploaderWidget');
const {
    getAuthenticatedAccountId,
    getAuthenticatedAccount,
    getAuthorizationHeaders,
} = require('../../authentication/Account');
const {
    getFileDocumentPreview,
    getFileUploadUrlForDocumentType,
    deleteFileDocument,
} = require('../Document');

const MAX_FILE_SIZE_IN_MB = 20;
const ERROR_FILE_NAME_BASE = 'error_file_';

module.exports = class RentalApplicationFileUploaderWidget extends FileUploaderWidget {
    constructor({documentType}) {
        super({maxFileSizeInMB: MAX_FILE_SIZE_IN_MB});
        this._filesUrls = {};
        this.setDocumentType(documentType);
        this.saveFilesMetaData = [];
    }

    _getAcceptedFiles() {
        return '.jpeg, .jpg, .png, .pdf, .doc, .docx';
    }

    setDocumentType(documentType) {
        this.documentType = documentType;
    }

    _getTranslationKey() {
        return 'rentalApplication';
    }

    _getUploadServerUrl() {
        return getFileUploadUrlForDocumentType(getAuthenticatedAccountId(), this.documentType);
    }

    _getFileDownloadUrl(fileId) {
        return this._filesUrls[fileId];
    }

    setFiles(filesAliases) {
        if (!_.isEqual(filesAliases, this.getFiles())) {
            this.emit('loading-files', true);
            const {documentType} = this;
            async.map(filesAliases, (file, cb) => {
                getFileDocumentPreview(getAuthenticatedAccountId(), documentType, file.id, (err, response, jqXHR) => {
                    if (err) {
                        console.error('Could not get file from server', err);
                        cb(null, {
                            [file.id]: {
                                err: new Error(translate('fileUploader.downloadErrorMessage')),
                                name: ERROR_FILE_NAME_BASE + file.id,
                            },
                        });
                    } else {
                        const contentType = jqXHR.getResponseHeader('content-type');
                        const url = _.includes(contentType, 'image') ? URL.createObjectURL(response) : null;
                        cb(null, {[file.id]: {url, name: file.fileName}});
                    }
                });
            }, (err, dataAsArray) => {
                assert.strictEqual(err, null); // errors are stored in dataAsArray in order to be displayed later by dropzone
                this.emit('loading-files', false);
                const data = _.extend({}, ...dataAsArray);
                this._filesErrors = toCompactObject(data, 'err');
                this._filesUrls = toCompactObject(data, 'url');
                this._filesNames = toCompactObject(data, 'name');
                this._setFiles(filesAliases);
            });
        }
    }

    _setFiles(filesAliases) {
        super._setFiles(filesAliases);
    }

    _addFiles(filesAliases) {
        super._addFiles(filesAliases);
        const {
            myDropzone,
            fileInfos,
            _filesErrors,
        } = this;
        _.each(_filesErrors, (err, fileUniqueIdentifier) => {
            const fileInfo = _.find(fileInfos, {fileUniqueIdentifier: fileUniqueIdentifier});
            myDropzone.emit('error', fileInfo.file, err);
        });
    }

    _addFile(file) {
        this.saveFilesMetaData.push(file);
        return super._addFile(file.id);
    }

    _registerFile(file, id) {
        const fileData = _.find(this.saveFilesMetaData, {id}) || {};
        const fileInfo = {
            ...file,
            name: fileData.fileName,
            metadata: fileData.metadata,
        };
        return super._registerFile(fileInfo, id);
    }

    getFiles() {
        return _.map(this.fileInfos, 'fileUniqueIdentifier');
    }

    _unregisterFile(fileInfo) {
        if (fileInfo) {
            this.fileInfos = _.reject(this.fileInfos, {fileUniqueIdentifier: fileInfo.fileUniqueIdentifier});
        }
    }

    _getFileInfoByFile(file) {
        return _.find(this.fileInfos, fileInfo =>
            (!_.isNil(fileInfo.file.name) && fileInfo.file.name === file.name) ||
            (!_.isNil(fileInfo.file.oldFilename) && fileInfo.file.oldFilename === file.name));
    }

    _onRemovedFile(file) {
        const fileInfo = this._getFileInfoByFile(file);
        if (!fileInfo) {
            super._onRemovedFile(file);
        } else {
            const {fileUniqueIdentifier: fileId} = fileInfo;
            deleteFileDocument(getAuthenticatedAccountId(), this.documentType, fileId, (err) => {
                if (err) {
                    console.error('Could not delete file on server', err);
                    const newFile = this._addFile(fileId);
                    this.myDropzone.emit('error', newFile, new Error(translate('fileUploader.removeErrorMessage')));
                } else {
                    this.emit('removeFinished', fileId);
                    super._onRemovedFile(file);
                }
            });
        }
        this.saveFilesMetaData = _.reject(
            this.saveFilesMetaData,
            filesMetaData => filesMetaData.fileName === file.fileName
        );
    }

    _getUploadRequestHeaders() {
        return getAuthorizationHeaders(getAuthenticatedAccount());
    }

    _getFileUniqueIdentifier(response, file) {
        const updatedFile = response.find((msg) => {
            if (msg.status === 'error') {
                return false;
            }
            // We link the uploaded file vialink id by matching  original filename
            return file.name === msg.file.oldFilename;
        });
        return _.get(updatedFile, 'file.id');
    }

    _shouldDisplayConfirmation(question) {
        // disable confirmation popup when cancelling upload
        return question !== this.myDropzone.options.dictCancelUploadConfirmation;
    }
};

function toCompactObject(values, key) {
    return _.pickBy(_.mapValues(values, key));
}
