const _ = require('lodash');

const AddLinkIfNeeded = require('./AddLinkIfNeeded');
const RealEstateAdTitleGenerator = require('../../../../common/RealEstateAdTitleGenerator');
const isSoldRealEstateAd = require('../../../utils/isSoldRealEstateAd');
const Account = require('../../../authentication/Account');

const SORT_BY_LIST_TO_DISPLAY_RELEVANCE = [
    'relevance',
    'relevanceDev',
];

// @vue/component
module.exports = {
    components: {
        AddLinkIfNeeded,
    },
    props: {
        realEstateAd: {
            type: Object,
            required: true,
        },
        sortBy: {
            type: String,
            default: undefined,
            SORT_BY_LIST_TO_DISPLAY_RELEVANCE,
        },
        lastViewDateForSearch: {
            type: String,
            default: null,
        },
        ofTheLocationText: {
            type: String,
            default: null,
        },
        disableAlreadySeen: Boolean,
        displayedAsHighlighted: Boolean,
        enableLastContactDateTag: Boolean,
        isRowWidthUnderStandardValue: Boolean,
    },
    computed: {
        generatedTitleWithHighlight() {
            return RealEstateAdTitleGenerator.getTitle(
                this.realEstateAd,
                'adOverviewTitle'
            );
        },
        realEstateAdLink() {
            return (!isSoldRealEstateAd(this.realEstateAd) || Account.isSoldAdsViewer())
                ? this.realEstateAd.detailedSheetUrl
                : null;
        },
        realEstateAdDescription() {
            return this.realEstateAd.description;
        },
        displayRelevance() {
            return _.includes(SORT_BY_LIST_TO_DISPLAY_RELEVANCE, this.sortBy) && this.isAdsRelevanceViewer;
        },
        realEstateAdId() {
            return this.realEstateAd.id;
        },
        isAdsRelevanceViewer() {
            return Account.isAdsRelevanceViewer();
        },
    },
    methods: {
        onAdClick(event) {
            this.$emit('click-on-ad', event);
        },
        onBackOnTheMarket(modifiedAd) {
            this.$emit('back-on-the-market', modifiedAd);
        },
    },
};
