const _ = require('lodash');
const util = require('util');
const FieldInputWidget = require('./FieldInputWidget');
const Account = require('../authentication/Account');
const {i18n: {translate}} = require('fack');

module.exports = ContactInputWidget;

function ContactInputWidget($container, data) {
    const authenticatedAccount = !data.ignoreAccountValues ? Account.getAuthenticatedAccount() : null;
    let defaultValue = null;
    switch (data.contactFieldName) {
        case 'name':
            data.placeholderKey = 'placeholder.text';
            defaultValue = getContactName(authenticatedAccount);
            break;
        case 'email':
            _.extend(data, {
                placeholderKey: 'placeholder.email',
                type: 'email',
                'data-bv-emailAddress-message': translate('formErrorMessages.email'),
            });
            defaultValue = getContactEmail(authenticatedAccount);
            break;
        case 'phone':
            defaultValue = getContactPhone(authenticatedAccount);
            _.extend(data, {
                type: 'tel',
                placeholderKey: 'placeholder.phone',
                'data-bv-phone': true,
                'data-bv-phone-country': 'FR',
                'data-bv-phone-message': translate('formErrorMessages.tel'),
            });
            break;
    }
    if (data.required) {
        data.defaultValue = defaultValue;
    } else if (defaultValue) {
        data.placeholder = defaultValue;
    }
    FieldInputWidget.call(this, $container, data);
}

util.inherits(ContactInputWidget, FieldInputWidget);

function getContactEmail(authenticatedAccount) {
    return authenticatedAccount && authenticatedAccount.email || null;
}

function getContactPhone(authenticatedAccount) {
    return authenticatedAccount && authenticatedAccount.contact && authenticatedAccount.contact.phone;
}

function getContactName(authenticatedAccount) {
    if (authenticatedAccount && authenticatedAccount.company && authenticatedAccount.company.name) {
        return authenticatedAccount.company.name;
    }
    return authenticatedAccount && authenticatedAccount.display_name;
}
