const {PUBLIC_BLOG_PATH} = require('../../../../common/data/blogData');

function generatePropertyPriceLink(cityName, relativeUrl) {
    return {
        href: `/prix-immobilier/france/${relativeUrl}`,
        content: `Prix de l’immobilier à ${cityName}`,
    };
}

const PROJECTS_LINKS = [
    {
        title: 'Trouver mon logement',
        image: 'working.jpg',
        links: [
            {href: '/achat-appartement', content: 'Acheter un appartement'},
            {href: '/achat-maison', content: 'Acheter une maison'},
            {href: '/immobilier-neuf', content: 'Acheter un logement neuf'},
            {href: '/achat-terrain', content: 'Acheter un terrain'},
            {href: '/faire-construire-sa-maison', content: 'Faire construire sa maison'},
            {href: '/location-maison', content: 'Louer une maison'},
            {href: '/location-appartement', content: 'Louer un appartement'},
        ],
    },
    {
        title: 'Découvrir mon nouveau quartier',
        image: 'quartier.jpg',
        links: [
            {href: '/immobilier/paris/paris', content: 'Découvrir les quartiers de Paris'},
            {href: '/immobilier/metropole-de-lyon/lyon', content: 'Découvrir les quartiers de Lyon'},
            {href: '/immobilier/bouches-du-rhone/marseille', content: 'Découvrir les quartiers de Marseille'},
            {href: '/immobilier/nord/lille', content: 'Découvrir les quartiers de Lille'},
            {href: '/immobilier/gironde/bordeaux', content: 'Découvrir les quartiers de Bordeaux'},
            {href: '/immobilier/haute-garonne/toulouse', content: 'Découvrir les quartiers de Toulouse'},
            {href: '/decouvrez-votre-futur-quartier', content: 'Découvrir les quartiers d’une autre ville'},
        ],
    },
    {
        title: 'Préparer mon projet',
        image: 'room.jpg',
        attributes: {'credit-simulation': true},
        links: [
            {href: '/decouvrez-votre-futur-quartier', content: 'Découvrir mon futur quartier'},
            {href: '/estimation-immobiliere', content: 'Estimer mon bien'},
            {href: '/vendre-mon-bien', content: 'Vendre mon bien'},
            {href: '/louer-mon-bien', content: 'Mettre en location mon bien'},
            {href: '/investir-en-loi-pinel', content: 'Investir dans le neuf avec la loi Pinel'},
            {href: '/calcul-pret-a-taux-zero', content: 'Simuler mon Prêt à Taux Zéro'},
            {href: '/article/votre-devis-de-demenagement-personnalise', content: 'Préparer mon déménagement'},
        ],
    },
];

const USE_FULL_LINKS = [
    {href: '/mes-alertes', translationKey: 'homePage.mySavedSearches'},
    {href: PUBLIC_BLOG_PATH, translationKey: 'homePage.blogMain'},
    {href: '/acheter-ou-investir-dans-l-immobilier-neuf', translationKey: 'homePage.newPropertyPages'},
    {href: '/agences-immobilieres', translationKey: 'homePage.findAgency'},
];

const FUTURE_LINKS = [
    {
        image: 'sitting.jpg',
        title: 'HomePageContent.yourSearchSimplifiedTitle',
        text: 'HomePageContent.yourSearchSimplifiedText',
    },
    {
        image: 'france3d.jpg',
        title: 'HomePageContent.franceIn3DTitle',
        text: 'HomePageContent.franceIn3DText',
    },
    {
        image: 'neighborhood.jpg',
        title: 'HomePageContent.yourNighborhoodTitle',
        text: 'HomePageContent.yourNighborhoodText',
    },
];

const REAL_ESTATE_PRICE_IN_FRANCE_LINKS = [
    generatePropertyPriceLink('Paris', 'ile-de-france/75000-paris'),
    generatePropertyPriceLink('Marseille', 'provence-alpes-cote-d-azur/13000-marseille'),
    generatePropertyPriceLink('Lyon', 'auvergne-rhone-alpes/69000-lyon'),
    generatePropertyPriceLink('Toulouse', 'occitanie/31000-toulouse'),
    generatePropertyPriceLink('Nice', 'provence-alpes-cote-d-azur/06000-nice'),
    generatePropertyPriceLink('Nantes', 'pays-de-la-loire/44000-nantes'),
    generatePropertyPriceLink('Montpellier', 'occitanie/34000-montpellier'),
    generatePropertyPriceLink('Bordeaux', 'nouvelle-aquitaine/33000-bordeaux'),
    generatePropertyPriceLink('Lille', 'hauts-de-france/59000-lille'),
];

module.exports = {
    PROJECTS_LINKS,
    USE_FULL_LINKS,
    FUTURE_LINKS,
    REAL_ESTATE_PRICE_IN_FRANCE_LINKS,
};
