const _ = require('lodash');
const Account = require('../authentication/Account');
const buildUrl = require('./buildUrl');

module.exports = {
    getDossier,
    createDossier,
    getApplicantDocumentsInDossier,
};

function getDossier(accountId, cb) {
    Account.authAjax({
        url: buildUrl(`/dossier/${accountId}`),
        disableErrorPage: true,
        callback: function (err, data) {
            cb(err, _.get(data, 'dossier'));
        },
    });
}

function createDossier(user, cb) {
    Account.authAjax({
        url: buildUrl(`/dossier/create/${user.id}`),
        disableErrorPage: true,
        callback: function (err, data) {
            cb(err, _.get(data, 'dossier'));
        },
    });
}

function getApplicantDocumentsInDossier(dossier) {
    return dossier.documents;
}
