const _ = require('lodash');

const Account = require('../authentication/Account');
const ServerConfig = require('../ServerConfig');
const {extendWithNames} = require('./extendWithNames');

module.exports = {
    sendTestEmail,
    getEmailStatus,
    getAccountIdsFromEmail,
};

function getEmailInfoFromUrl({url, requiredRole, email, callback}) {
    if (Account.hasRole(requiredRole)) {
        Account.authAjax({
            url,
            type: 'GET',
            data: {
                email,
            },
            serverErrorMessage: 'Server Error',
            disableErrorPage: true,
            callback,
        });
    } else {
        callback(null, undefined);
    }
}

function sendTestEmail(email, callback) {
    Account.authAjax({
        url: '/send_test_email',
        type: 'POST',
        disableErrorPage: true,
        data: {
            email,
        },
        callback,
    });
}

function getEmailStatus(email, cb) {
    getEmailInfoFromUrl({
        url: ServerConfig.config.mailEventsUrl + '/email-status',
        requiredRole: 'emailStatusChecker',
        email,
        callback: (err, result) => {
            let status;
            if (err) {
                status = {
                    error: err.code === 404 ? 'notFound' : 'error',
                };
            } else if (result) {
                status = _.omit(result, '_id');
            } else {
                status = undefined;
            }
            cb(null, status);
        },
    });
}

function getAccountIdsFromEmail(email, cb) {
    getEmailInfoFromUrl({
        url: ServerConfig.config.personalDataUrl + '/accounts-ids-by-email',
        requiredRole: 'dirViewer',
        email,
        callback: (err, results) => {
            if (err) {
                if (err.code === 404) {
                    cb(null, []);
                } else {
                    cb(null, undefined);
                }
            } else if (results) {
                const accountIdsWithSources = _.filter(results, 'accountId');
                Account.getAccountsNames(_.map(accountIdsWithSources, 'accountId'), (_err, accountNames) => {
                    cb(null, extendWithNames(accountIdsWithSources, accountNames));
                });
            } else {
                cb(null, undefined);
            }
        },
    });
}
