const util = require('util');
const FieldInputWidget = require('./FieldInputWidget');
const {i18n: {translate}} = require('fack');
const SelectPicker = require('../utils/SelectPicker');

module.exports = EnumWidget;

function EnumWidget($container, data) {
    if (data.values && data.values.length == 1) {
        this.name = data.name;
        this.setLonelyValue(data.values[0]);
    } else {
        const placeholder = data.placeholder = data.placeholder
            || translate(['selectInput.placeholder.' + data.name, 'selectInput.placeholder.default']);
        this.data = data;
        FieldInputWidget.call(this, $container, data);
        this.$selectPicker = this.getElement().find('select');
        SelectPicker.enable(this.$selectPicker, {
            title: placeholder,
            noneSelectedText: placeholder,
        });
        if (data.disabled) {
            this.$selectPicker.attr('style', 'display: none !important');
        }
    }
    return this;
}

util.inherits(EnumWidget, FieldInputWidget);
const proto = EnumWidget.prototype;

proto.template = require('../templates/fields/enumField.jade');

proto.getFieldValue = function () {
    return this.$input ? this.$input.val() || null : this.getLonelyValue();
};

proto.setFieldValue = function (value) {
    if (this.$selectPicker) {
        this.$selectPicker.selectpicker('val', value);
    } else {
        this.setLonelyValue(value);
    }
};

proto.setLonelyValue = function (value) {
    this.lonelyValue = value;
};

proto.getLonelyValue = function () {
    return this.lonelyValue;
};
