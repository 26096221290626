const translateMixin = require('../mixins/translate');
const i18nMixin = require('../../../vue/components/mixins/i18n');
const template = require('./ApplicantEditor.jade');
const ApplicantDocumentEditor = require('./ApplicantDocumentEditor');

// @vue/component
module.exports = {
    components: {
        ApplicantDocumentEditor,
    },
    mixins: [
        i18nMixin({
            keys: [
                'title',
            ],
        }),
        translateMixin,
    ],
    props: {
        documents: {
            type: Object,
            required: true,
        },
    },
    computed: {
        documentsCategories() {
            return this.$store.getters['rentalApplication/documentsCategories'];
        },
    },
    methods: {
        t(key, options) {
            return this.applicantTranslate(key, options);
        },
        getFilesIds(documentsCategory) {
            return this.documents[documentsCategory];
        },
    },
    template: template(),
};
