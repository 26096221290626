const _ = require('lodash');
const LineClamp = require('line-clamp');

module.exports = findAndClampLines;

function findAndClampLines($element, selector, nbrLines) {
    const $elements = $element.find(selector);
    _.each($elements, domElement => {
        LineClamp(domElement, nbrLines);
    });
}
