const _ = require('lodash');

const Cookies = require('./Cookies');
const getAbTestingOptions = require('./abTesting/getAbTestingOptions');

const AB_TESTING_TARGETS = [
    'contact',
];

const AB_TESTING_OPTIONS = {
    relatedAdsPosition: [
        'relatedAdsAfterDocumentationServices',
        'relatedAdsAfterDescription',
        'relatedAdsAfterSlideShow',
    ],
};

const COOKIE_NAME = 'AB-TESTING';
let savedOptions = {};

module.exports = {
    init,
    getSavedOptions,
    getSavedOptionsForContact,
    getSavedOptionsForEstimator,
};

function init() {
    if (_.isEmpty(AB_TESTING_OPTIONS)) {
        return;
    }
    const cookieValue = Cookies.getJsonCookie(COOKIE_NAME);
    savedOptions = getAbTestingOptions(AB_TESTING_OPTIONS, cookieValue);

    Cookies.setJsonCookie(COOKIE_NAME, savedOptions, {
        path: '/',
        expires: 365,
    });
    return savedOptions;
}

function getSavedOptions() {
    return savedOptions;
}

function getSavedOptionsForContact() {
    return getSavedOptionsFor('contact');
}

function getSavedOptionsForEstimator() {
    return getSavedOptionsFor('estimator');
}

function getSavedOptionsFor(target) {
    if (_.includes(AB_TESTING_TARGETS, target)) {
        return getSavedOptions();
    } else {
        return {};
    }
}
