const RentalApplicationFileUploaderWidget = require('./RentalApplicationFileUploaderWidget');

// @vue/component
module.exports = {
    mixins: [
        require('../../form/components/mixins/fileUploaderMixin'),
    ],
    props: {
        value: {
            type: Array,
            default: undefined,
        },
        documentType: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            autoProcessQueue: false,
            parallelUploads: 10,
        };
    },
    watch: {
        documentType(documentType) {
            this.uploader.setDocumentType(documentType);
        },
    },
    methods: {
        getUploader() {
            return new RentalApplicationFileUploaderWidget({
                documentType: this.documentType,
            });
        },
    },
};
