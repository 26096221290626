const _ = require('lodash');
const i18nMixin = require('../../vue/components/mixins/i18n');
const template = require('./CreditSimulationButton.jade');
const canSimulateCredit = require('../canSimulateCredit');
const {openFinancialPartnerTab} = require('../../financialPartners');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'creditSimulation.',
            keys: [
                'detailedSheetPageButtonMessage',
            ],
        }),
    ],
    props: {
        realEstateAd: {
            type: Object,
            required: true,
        },
        topLink: {
            type: Boolean,
        },
    },
    computed: {
        visible() {
            return canSimulateCredit(this.realEstateAd);
        },
    },
    methods: {
        openFinancialPartnerTab() {
            const {newProperty, postalCode} = this.realEstateAd;
            const linkContextParts = [
                this.topLink ? 'first' : 'second',
                newProperty ? 'new' : 'old',
                'propertyDetailedSheet',
            ];
            openFinancialPartnerTab({
                context: _.camelCase(linkContextParts.join(' ')),
                postalCode,
            });
        },
    },
    template: template(),
};
