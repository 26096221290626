const $ = require('jquery');
const _ = require('lodash');
const {i18n: {translate}} = require('fack');
const {isMobile, isTablet} = require('browser-detect');

const template = require('../templates/savedSearches/savedSearchesView.jade');
const listTemplate = require('../templates/savedSearches/savedSearchesList.jade');
const searchTemplate = require('../templates/savedSearches/savedSearch.jade');
const noResultTemplate = require('../templates/savedSearches/noSavedSearches.jade');
const CompositeVueView = require('../vue/CompositeVueView');
const SavedSearches = require('./SavedSearches');
const Account = require('../authentication/Account');
const NumberFormatter = require('../../common/NumberFormatter');
const BrowserNotifications = require('../notifications/BrowserNotifications');
const CriteriaListFormatter = require('../../common/CriteriaListFormatter');
const EventPack = require('../utils/EventPack');
const i18nMixin = require('../vue/components/mixins/i18n');
const AuthenticationPopup = require('../authentication/AuthenticationPopup');
const components = require('./savedSearches/components');

class SavedSearchesView extends CompositeVueView {
    constructor() {
        super({
            template,
        });
        this._eventPack = new EventPack();
    }

    show(options) {
        const view = this;
        const vueData = this.vueData = {
            translationContext: (isMobile() || isTablet()) ? 'mobileOrTablet' : 'desktop',
            displayDeniedNotificationsMessage: false,
            displayNotificationsBlock: false,
            notificationsEnabled: false,
            savedSearches: [],
            hasError: false,
            isLoading: false,
        };
        // @vue/component
        const vueOptions = this.vueOptions = {
            bemName: 'saved-searches-page',
            components,
            mixins: [
                i18nMixin({
                    prefix: 'savedSearchesView.',
                    keys: [
                        'noSavedSearches',
                        'createSavedSearch',
                        'accountCreationPitch',
                        'createAccount',
                        'error',
                        'savedSearchesTitle',
                    ],
                }),
            ],
            data() {
                return vueData;
            },
            computed: {
                deniedNotificationsMessage() {
                    return this.t('notification.enable.messageNotAllowed', {context: this.translationContext});
                },
                notificationsAction() {
                    return this.notificationsEnabled ? 'disable' : 'enable';
                },
                notificationsBlockText() {
                    return this.t(`notification.${this.notificationsAction}.message`, {context: this.translationContext});
                },
                notificationsButtonText() {
                    return this.t(`notification.${this.notificationsAction}.button`);
                },
                showNotificationState() {
                    return true;
                },
                showPushNotificationState() {
                    return view._hasToShowPushNotificationState();
                },
                showNotificationIcon() {
                    return false;
                },
                criteriaModificationEnabled() {
                    return false;
                },
                criteriaModificationHidden() {
                    return false;
                },
            },
            methods: {
                onCreateSavedSearch() {
                    view.emit('createSaveSearch');
                },
                onCreateAccount() {
                    const authenticationPopup = new AuthenticationPopup();
                    authenticationPopup.showCreateAccount();
                },
                onNotificationsButtonClick() {
                    switch (this.notificationsAction) {
                        case 'enable':
                            BrowserNotifications.enablePushNotifications({silent: false}, (err) => {
                                if (err) {
                                    console.error(err);
                                }
                            });
                            break;
                        case 'disable':
                            BrowserNotifications.disablePushNotifications((err) => {
                                if (err) {
                                    console.error(err);
                                }
                            });
                            break;
                    }
                },
                editCriteria(savedSearchId) {
                    view.emit('editCriteria', savedSearchId);
                },
                deleteSaveSearch(savedSearchId) {
                    view.emit('deleteSaveSearch', savedSearchId);
                },
                editFrequency(savedSearchId) {
                    view.emit('editFrequency', savedSearchId);
                },
                areNotificationsEnabledForSavedSearch,
            },
        };
        super.show(options, vueOptions);
        this._bindEvents();
    }

    hide() {
        this._eventPack.removeAllListeners();
        CompositeVueView.prototype.hide.apply(this, arguments);
    }

    setSavedSearchesCount(savedSearchesCount) {
        _.each(this.$element.find('[data-saved-search-id]'), (elem) => {
            const $saveSearchBlock = $(elem);
            const key = $saveSearchBlock.attr('data-saved-search-id');

            const count = savedSearchesCount[key] || 0;
            $saveSearchBlock.find('.value').text(NumberFormatter.formatNumber(count));
            $saveSearchBlock.toggleClass('newAds', savedSearchesCount[key] > 0);
            if (savedSearchesCount[key] > 1) {
                $saveSearchBlock.find('.unit').text(translate('savedSearchesView.newResults'));
            } else {
                $saveSearchBlock.find('.unit').text(translate('savedSearchesView.newResult'));
            }
        });
    }

    startLoading() {
        _.extend(this.vueData, {
            hasError: false,
            isLoading: true,
        });
        this.$element.find('.savedSearchesList').empty();
    }

    showSavedSearches(savedSearches) {
        this.vueData.savedSearches = savedSearches;
        this._updateSearches(savedSearches);
    }

    _updateSearches(savedSearches) {
        this.vueData.isLoading = false;
        const $savedSearchesList = this.$element.find('.savedSearchesList');
        if (!savedSearches || savedSearches.length == 0) {
            $savedSearchesList.append(this.renderTemplate(noResultTemplate));
        } else {
            _.each(savedSearches, (savedSearch) => {
                savedSearch.criteriaList = CriteriaListFormatter.getCapitalizedCriteria(savedSearch.searchCriteria);
            });
            const account = Account.getAuthenticatedAccount();
            const isRegistered = Account.isRegistered(account);
            $savedSearchesList.append(this.renderTemplate(listTemplate, {
                savedSearches,
                isRegistered,
                areNotificationsEnabledForSavedSearch,
            }));
        }
        this.injectVueViews($savedSearchesList, this.vueOptions);
        this._updatePushNotificationsBlockState();
        this.setSavedSearchesCount(SavedSearches.getSavedSearchesCount());
    }

    _updatePushNotificationsBlockState() {
        const {vueData} = this;
        if (BrowserNotifications.areNotificationsSupported()) {
            vueData.displayNotificationsBlock = true;
            vueData.notificationsEnabled = BrowserNotifications.areNotificationsCurrentlyEnabled();
            vueData.displayDeniedNotificationsMessage = BrowserNotifications.areNotificationsDenied();
        } else {
            vueData.displayNotificationsBlock = false;
            vueData.displayDeniedNotificationsMessage = false;
        }
    }

    showErrorMessage() {
        _.extend(this.vueData, {
            hasError: true,
            isLoading: false,
        });
    }

    showSearchHasError(id) {
        const $saveSearchBlock = this.$element.find('[data-saved-search-id="' + id + '"]');
        $saveSearchBlock.addClass('removeError');
        setTimeout(function () {
            $saveSearchBlock.removeClass('removeError');
        }, 1000);
    }

    updateSavedSearch(savedSearch, previousSavedSearchId) {
        const savedSeachIdToReplace = previousSavedSearchId || savedSearch._id;
        const $saveSearchBlock = this.$element.find(`[data-saved-search-id="${savedSeachIdToReplace}"]`);
        // if previous and new savedSearch ids are different, the new savedSearch element is deleted and
        // the new savedSearch content is copied in the previous savedSearch element.
        if (savedSearch._id && previousSavedSearchId && previousSavedSearchId != savedSearch._id) {
            // this must be done without animation, or else it will remove the saved search added below at the end of animation
            this.removeSavedSearch(null, savedSearch._id, {disableAnimation: true});
        }
        const $newSavedSearchBlock = this._renderSaveSearchBlock(savedSearch);
        $saveSearchBlock.replaceWith($newSavedSearchBlock);
        const {savedSearches} = this.vueData;
        const previousSavedSearchIndex = _.findIndex(savedSearches, {_id: savedSeachIdToReplace});
        savedSearches.splice(previousSavedSearchIndex, 1, savedSearch);
        this.injectVueViews($newSavedSearchBlock, this.vueOptions);
        this.removeElement($saveSearchBlock); // the DOM element has already been removed, it destroys any remaining Vue instance
        this.setSavedSearchesCount(SavedSearches.getSavedSearchesCount());
    }

    _renderSaveSearchBlock(savedSearch) {
        return this.renderTemplate(searchTemplate, {
            savedSearch,
            areNotificationsEnabledForSavedSearch,
        });
    }

    _hasToShowPushNotificationState() {
        return Account.hasPushNotifications() && !BrowserNotifications.areNotificationsUnsupportedOrDenied();
    }

    addSavedSearch(savedSearch) {
        const {$element} = this;
        const {savedSearches} = this.vueData;
        const currentIndex = _.findIndex(savedSearches, {_id: savedSearch._id});
        if (currentIndex >= 0) {
            savedSearches.splice(currentIndex, 0, savedSearch);
            this.updateSavedSearch(savedSearch);
        } else {
            savedSearches.push(savedSearch);
            const $saveSearchBlock = this._renderSaveSearchBlock(savedSearch);
            const $searchList = $element.find('.searchList');
            if ($searchList.length) {
                $searchList.prepend($saveSearchBlock);
                this.injectVueViews($saveSearchBlock, this.vueOptions);
                this.setSavedSearchesCount(SavedSearches.getSavedSearchesCount());
            } else {
                $element.find('.savedSearchesList').empty();
                this._updateSearches([savedSearch]);
            }
        }
    }

    removeSavedSearch(error, id, {disableAnimation = false} = {}) {
        const $element = this.$element; // declare this before because it can be null inside the setTimeout if we have change page
        const $saveSearchBlock = this.$element.find('[data-saved-search-id="' + id + '"]');
        if (error) {
            $saveSearchBlock.removeClass('disappear');
            this.showSearchHasError(id);
            console.warn('remove Saved Search (' + id + ') : error : ', error);
        } else if (disableAnimation) {
            remoteNow.call(this);
        } else {
            setTimeout(() => remoteNow.call(this), 500);
        }

        function remoteNow() {
            this.removeElement($saveSearchBlock);
            const {savedSearches} = this.vueData;
            const currentIndex = _.findIndex(savedSearches, {_id: id});
            if (currentIndex >= 0) {
                savedSearches.splice(currentIndex, 1);
            }
            if ($element.find('[data-saved-search-id]').length == 0) {
                const $savedSearchesList = $element.find('.savedSearchesList');
                $savedSearchesList.empty();
                $savedSearchesList.append(this.renderTemplate(noResultTemplate));
                this.injectVueViews($savedSearchesList, this.vueOptions);
            }
        }
    }

    _bindEvents() {
        this._eventPack.on(this.$element, {
            click: {
                '.enableNotifications': _.bind(this._onActionClicked, this),
                '.createSavedSearch': () => {
                    this.emit('createSaveSearch');
                },
            },
        });
        this._eventPack.on(SavedSearches, 'savedSearchesCountChanged', () => {
            this.setSavedSearchesCount(SavedSearches.getSavedSearchesCount());
        });
        this._eventPack.on(BrowserNotifications, 'notificationEnabledChanged',
            _.bind(this._updatePushNotificationsBlockState, this));
    }

    _onActionClicked(e) {
        const $element = $(e.currentTarget);
        this.emit($element.attr('data-action-type'), $element.attr('data-id'));
    }

    optimisticRemove(id) {
        this.$element.find('.savedSearchBlock[data-saved-search-id=' + id + ']').addClass('disappear');
    }
}

function areNotificationsEnabled(notificationState) {
    return notificationState !== 'never';
}

function areNotificationsEnabledForSavedSearch(savedSearch) {
    const {emailNotificationMode, pushNotificationMode} = savedSearch;
    return areNotificationsEnabled(emailNotificationMode)
        || areNotificationsEnabled(pushNotificationMode);
}

module.exports = SavedSearchesView;
