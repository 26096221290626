const _ = require('lodash');
const namespaceComponents = require('../../vue/components/helpers/namespaceComponents');
const detailedSheetComponents = require('./detailedSheet');
const neighborhoodComponents = require('./neighborhood');
const agencyComponents = require('./agency');
const toggleMarketComponents = require('./toggleMarket');
const overviewComponents = require('./overview');

const NAMESPACE = 'ad';

module.exports = _.extend(namespaceComponents(NAMESPACE, _.extend(
    {
        'emphasised-tags': require('./EmphasisedTags'),
        'price': require('./Price'),
        'position-data-address': require('./PositionDataAddress'),
        'action-button': require('./ActionButton'),
        'modification-link': require('./AdModificationLink'),
        'publication-certificate-link': require('./AdPublicationCertificateLink'),
        'compact-overview': require('./CompactOverview'),
    },
    detailedSheetComponents,
    neighborhoodComponents,
    agencyComponents,
    toggleMarketComponents,
    overviewComponents
)), {
    'highlighted-real-estate-ad-caption': require('./HighlightedRealEstateAdCaption'),
});
