const _ = require('lodash');
const namespaceComponents = require('../../vue/components/helpers/namespaceComponents');

const NAMESPACE = 'search';

module.exports = _.extend(namespaceComponents(NAMESPACE, {
    'sort-selector': require('./SortSelector'),
    'results-count': require('./SearchResultsCount'),
    'criteria-list-container': require('./CriteriaListContainer'),
    'notification-state': require('./NotificationState'),
    'criteria-summary': require('./CriteriaSummary'),
    'property-type-field': require('./PropertyTypeField'),
    'min-max-field': require('./MinMaxField'),
    'edp-field': require('./EdpField'),
    'seo-links': require('./SearchSeoLinks'),
    'seo-content': require('./SearchSeoContent'),
    'seo-container': require('./SearchSeoContainer'),
}), {
    'pagination': require('./Pagination'),
    'line-with-icon-first': require('./LineWithIconFirst'),
});
