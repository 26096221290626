const template = require('./OverviewList.jade');
const OverviewMixin = require('./OverviewMixin');

// @vue/component
module.exports = {
    mixins: [
        OverviewMixin,
    ],
    template: template(),
};
