const {resourceUrl} = require('fack');
const i18nMixin = require('../../vue/components/mixins/i18n');
const template = require('./CreditSimulationArticleBlock.jade');
const {canShowFinancialPartnerCreditSimulationLinks, openFinancialPartnerTab} = require('../../financialPartners');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'creditSimulation.articleBlock.',
            keys: [
                'title',
                'pitch',
                'buttonText',
                'imageAlternativeText',
            ],
        }),
    ],
    props: {
        article: {
            type: Object,
            required: true,
        },
    },
    computed: {
        visible() {
            return (canShowFinancialPartnerCreditSimulationLinks() && this.article.creditSimulationBlockEnabled);
        },
        imageSrc() {
            return resourceUrl('images/creditSimulation/articleBlock.jpg');
        },
    },
    methods: {
        openFinancialPartnerTab() {
            return openFinancialPartnerTab({context: 'blog'});
        },
    },
    template: template(),
};
