const _ = require('lodash');
const template = require('./Contact.jade');
const accountFormTranslate = require('../../utils/accountForm/accountFormTranslate');
const getNameForContactField = require('../../utils/accountForm/getNameForContactField');
const internalValues = require('../../form/components/mixins/internalValues');

// @vue/component
module.exports = {
    mixins: [
        internalValues({
            props: [
                'firstName',
                'lastName',
                'phone',
                'email',
                'overridePhoneInAds',
                'alwaysReceiveCarbonCopy',
            ],
        }),
    ],
    props: {
        firstName: {
            type: String,
            default: undefined,
        },
        lastName: {
            type: String,
            default: undefined,
        },
        phone: {
            type: String,
            default: undefined,
        },
        email: {
            type: String,
            default: undefined,
        },
        overridePhoneInAds: Boolean,
        alwaysReceiveCarbonCopy: Boolean,
        id: {
            type: String,
            required: true,
        },
        role: {
            type: String,
            default: undefined,
        },
        roles: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        displayOverridePhoneInAdsCheckbox() {
            const roles = this.role ? [this.role] : this.roles;
            return _.every(roles, role => !_.includes(['buy', 'rent'], role));
        },
        displayAlwaysReceiveCarbonCopyCheckbox() {
            const roles = this.role ? [this.role] : this.roles;
            return !_.includes(roles, 'report');
        },
    },
    methods: {
        getNameForField(fieldName) {
            return getNameForContactField(fieldName, this.id);
        },
        accountFormTranslate,
    },
    template: template(),
};
