const _ = require('lodash');

const ServerConfig = require('../ServerConfig');
const LocalStorage = require('../utils/localStorage/LocalStorage');
const didomi = require('../lib/didomi');

const DIDOMI_TOKEN_KEY = 'didomi_token';
// all vendor ids are available at didomi console (go to 'didomi-console > notice -> vendors -> selected-vendors')
const GOOGLE_VENDOR_ID = 'google';
const DIDOMI_ACCOUNT_ID = '7d581840-66fa-4f8f-b890-b612c6c34d07';

LocalStorage.registerParser(DIDOMI_TOKEN_KEY, window.atob);
window.didomiOnReady = window.didomiOnReady || [];

module.exports = {
    init,
    getUserConsentToAllowPersonalizedAds,
    getUserConsentToAllowPersonalizedAdsFromLocalStorage,
    DIDOMI_LOCAL_STORAGE_KEY: DIDOMI_TOKEN_KEY,
    onDidomiReady,
};

function onDidomiReady(callback) {
    window.didomiOnReady.push(callback);
}

function init() {
    if (ServerConfig.config.cmpEnabled) {
        didomi(DIDOMI_ACCOUNT_ID);
    } else {
        _.each(window.didomiOnReady, cb => cb()); // simulate Didomi ready, so that the GTM data layers can be filled for the tests
    }
}

function getUserConsentToAllowPersonalizedAds(Didomi) {
    return Boolean(Didomi.getUserConsentStatusForVendor(GOOGLE_VENDOR_ID));
}

function getUserConsentToAllowPersonalizedAdsFromCWT(decodedCWT) {
    const {enabled = []} =
        _.has(decodedCWT, 'vendors.consent') ? _.get(decodedCWT, 'vendors.consent') : _.get(decodedCWT, 'vendors', {});
    return _.includes(enabled, GOOGLE_VENDOR_ID);
}

function getUserConsentToAllowPersonalizedAdsFromLocalStorage() {
    // CWT means "consent web token"
    return getUserConsentToAllowPersonalizedAdsFromCWT(LocalStorage.getValue(DIDOMI_TOKEN_KEY));
}
