const $ = require('jquery');
const _ = require('lodash');

const CompositeVueView = require('../vue/CompositeVueView');
const template = require('../templates/views/suggestionView.jade');
const TextFormatter = require('../../common/TextFormatter');
const PriceFormatter = require('../../common/PriceFormatter');
const EventPack = require('../utils/EventPack');
const RealEstateAdTitleGenerator = require('../../common/RealEstateAdTitleGenerator');
const AddressFormatter = require('../../common/formatters/AddressFormatter');
const {i18n: {translate}} = require('fack');

class SuggestionView extends CompositeVueView {
    constructor() {
        super({
            template,
        });
        this.configuration = {
            animations: {
                openAnimation: 'anim-open-right',
                closeAnimation: 'anim-close',
            },
        };
        this.isMobile = false;
        this._eventPack = new EventPack();
    }

    show(options) {
        if (options.animations) {
            this.configuration.animations = options.animations;
        }
        const {
            realEstateAd,
            similarAds: realEstateAds,
        } = options;
        // @vue/component
        const vueOptions = {
            data() {
                return {
                    realEstateAd,
                    realEstateAds,
                };
            },
            computed: {
                enableLastContactDateTag() {
                    return options.enableLastContactDateTag;
                },
            },
        };
        super.show(_.extend({
            TextFormatter,
            PriceFormatter,
            RealEstateAdTitleGenerator,
            AddressFormatter,
        }, options), vueOptions);
        this.bindEvents();
        this.playOpenAnimation();
        this._updateButtonStatus();
    }

    bindEvents() {
        this._eventPack.on(this.$element, {
            mouseover: {
                '.ad': (event) => {
                    const realEstateAdId = $(event.currentTarget).attr('data-adid');
                    this.emit('mouseoverAd', realEstateAdId);
                },
            },
            mouseout: {
                '.ad': (event) => {
                    const realEstateAdId = $(event.currentTarget).attr('data-adid');
                    this.emit('mouseoutAd', realEstateAdId);
                },
            },
        });
        const $form = this.$element.find('form');
        this._eventPack.on($form, 'submit', event => {
            event.preventDefault(); //avoid sending form
            const ads = _.map($form.find('input[type="checkbox"]:checked'), checkbox => checkbox.name);
            this.emit('sendContact', ads, $form.find('.btnSendSimilarContact'));
        });
        this._eventPack.on($form, 'change', 'input[type="checkbox"]', () => {
            this._updateButtonStatus();
        });
        this._eventPack.onLinkClick($form, '.similarDetailedSheetLink', (event) => {
            const realEstateAdId = $(event.target).attr('data-id');
            event.preventDefault(); //avoid following link
            this.setCloseAnimation('anim-close-left');
            this.emit('showAdSuggestion', realEstateAdId);
        });
        this._eventPack.onLinkClick(this.$element, '.backToAd', (event) => {
            event.preventDefault(); //avoid following link
            this.emit('backToAd');
        });
        this._eventPack.onLinkClick(this.$element, '.linkToAdContainer a', (event) => {
            event.preventDefault(); //avoid following link
            this.emit('backToAd');
        });
    }

    _hide(options, cb) {
        this.configuration.animations = {
            openAnimation: 'anim-open-right',
            closeAnimation: 'anim-close',
        };
        this._eventPack.removeAllListeners();
        super.hide(options);
        cb();
    }

    hide(options, cb) {
        this.playCloseAnimation(_.bind(this._hide, this, options, cb));
    }

    _updateButtonStatus() {
        const $form = this.$element.find('form');
        const $submit = $form.find('.btnSendSimilarContact');
        const checkboxCheckedQuantity = $form.find('input[type="checkbox"]:checked').length;
        $submit.prop('data-count', checkboxCheckedQuantity);
        $submit.prop('disabled', checkboxCheckedQuantity == 0);
        $submit.text(translate('adSuggestions.sendContacts', {count: checkboxCheckedQuantity}));
    }

    _shouldAnimate() {
        return !this.isMobile;
    }

    shouldPlayOpenAnimation() {
        return this._shouldAnimate();
    }

    shouldPlayCloseAnimation() {
        return this._shouldAnimate();
    }
}

module.exports = SuggestionView;
