const $ = require('jquery');
const {i18n: {translate}} = require('fack');
const uuid = require('uuid/v1');
const template = require('../templates/fields/checkboxField.jade');

module.exports = ConfirmWidget;

function ConfirmWidget($container, data) {
    this.$element = $(template({
        data,
        uuid,
    }));
    this.name = data.name;
    const $input = this.$input = this.$element.find(':input');
    $input.attr({
        name: data.name,
        required: data.required,
        'data-bv-choice': true,
        'data-bv-choice-min': data.required ? 1 : 0,
        'data-bv-choice-message': translate('formErrorMessages.fieldRequired'),
    });
    $container.append(this.$element);
}

const proto = ConfirmWidget.prototype;

proto.clear = function () {
    this.$element.remove();
};

proto.show = function () {
    this.$element.show();
};

proto.hide = function () {
    this.$element.hide();
};

proto.serialize = function (value) {
    if (value === true) {
        return true;
    } else if (value === false) {
        return false;
    }
};

proto.getFieldValue = function () {
    return this.serialize(this.$input.is(':checked'));
};

proto.setFieldValue = function (value) {
    if (value) {
        this.$input.attr('checked', 'checked');
    } else {
        this.$input.attr('checked', null);
    }
};

proto.serializeInto = function (result) {
    const value = this.getFieldValue();
    if (value != null) {
        result[this.name] = value;
    }
};
