const _ = require('lodash');

module.exports = _([
    'SET_STATS',
    'ADD_IN_CURRENT_FAVORITE_ADS',
    'REMOVE_FROM_CURRENT_FAVORITE_ADS',
])
    .map(mutation => [mutation, _.camelCase(mutation)])
    .fromPairs()
    .value();
