const DocumentEditor = require('../DocumentEditor');
const DocumentEditorDescription = require('../DocumentEditorDescription');
const translateMixin = require('../mixins/translate');
const i18nMixin = require('../../../vue/components/mixins/i18n');
const template = require('./ApplicantDocumentEditor.jade');

// @vue/component
module.exports = {
    components: {
        DocumentEditor,
        DocumentEditorDescription,
    },
    mixins: [
        i18nMixin({
            keys: [
                'title',
                'introduction',
            ],
        }),
        translateMixin,
    ],
    props: {
        category: {
            type: String,
            required: true,
        },
        files: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        items() {
            return this.t('items', {returnObjectTrees: true});
        },
    },
    methods: {
        t(key, options) {
            return this.applicantDocumentsTranslate(`${this.category}.${key}`, options);
        },
    },
    template: template(),
};
