const i18nMixin = require('../mixins/i18n');
const CancelableCallback = require('../../../utils/CancelableCallback');
const withInternalValue = require('../../../form/components/mixins/withInternalValue');
const Views = require('../../../views/Views');
const template = require('./ConfirmModal.jade');

// @vue/component
module.exports = {
    mixins: [
        withInternalValue,
        i18nMixin({
            prefix: 'ConfirmModal.',
            keys: [
                'cancelText',
            ],
        }),
    ],
    props: {
        value: Boolean,
        title: {
            type: String,
            required: true,
        },
        pitch: {
            type: String,
            required: true,
        },
        confirmText: {
            type: String,
            required: true,
        },
        onConfirm: {
            type: Function,
            required: true,
        },
        errorTranslationKey: {
            type: String,
            default: undefined,
        },
        confirmTracking: {
            type: String,
            default: undefined,
        },
    },
    data() {
        return {
            submitting: false,
        };
    },
    methods: {
        close() {
            this.internalValue = false;
        },
        onClose() {
            this.submitting = false;
            this.$emit('close');
        },
        confirm() {
            this.submitting = true;
            const cb = CancelableCallback(err => {
                if (err) {
                    console.error(err);
                    Views.volatileFeedback.showError(this.errorTranslationKey);
                }
                this.close();
            });
            this.$once('close', () => cb.cancel());
            this.onConfirm(err => {
                // Wait for DOM update because of possible errorTranslationKey update inside 'onConfirm'
                this.$nextTick(() => {
                    cb(err);
                });
            });
        },
    },
    template: template(),
};
