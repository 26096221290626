const Options = require('../Options');
const ApplicationConfig = require('../app/ApplicationConfig');
const OpenWindow = require('../utils/OpenWindow');
const ServerConfig = require('../ServerConfig');
const querystring = require('querystring');

module.exports = {
    canShowFinancialPartnerCreditSimulationLinks,
    openFinancialPartnerTab,
};

function canShowFinancialPartnerCreditSimulationLinks() {
    return Options.get('financialPartnerCreditSimulationEnabled')
        && !ApplicationConfig.applicationPro;
}

function openFinancialPartnerTab({context, postalCode}) {
    const params = {context};
    if (postalCode) {
        params.postalCode = postalCode;
    }
    const url = `${ServerConfig.config.financialUrl}/redirection-partenaire-financier?` + querystring.stringify(params);
    OpenWindow.open({
        url,
        windowName: 'financialPartnerCreditSimulation',
    });
}
