const $ = require('jquery');
const _ = require('lodash');
const BrowserDetect = require('browser-detect');
const i18nMixin = require('../vue/components/mixins/i18n');
const savedOptions = require('../Options').read();

const Account = require('../authentication/Account');
const TextFormatter = require('../../common/TextFormatter');
const PriceFormatter = require('../../common/PriceFormatter');
const RealEstateAdTitleGenerator = require('../../common/RealEstateAdTitleGenerator');
const AddressFormatter = require('../../common/formatters/AddressFormatter');
const CompositeVueView = require('../vue/CompositeVueView');
const GeocodingPrecisionMatching = require('../GeocodingPrecisionMatching');
const ApplicationConfig = require('../app/ApplicationConfig');
const EventPack = require('../utils/EventPack');
const findAndClampLines = require('../utils/findAndClampLines');
const isSoldRealEstateAd = require('../utils/isSoldRealEstateAd');
const SearchFiltersHelper = require('../../common/SearchFiltersHelper');

const template = require('../templates/listView.jade');
const searchResultsListTemplate = require('../templates/search/results/searchResultsList.jade');
const searchResultsItemTemplate = require('../templates/search/results/searchResultsItem.jade');
const searchResultsHeaderNoResultTemplate = require('../templates/search/results/searchResultsNoResult.jade');
const ProgrammeHelper = require('../../common/ProgrammeHelper');
const AdAnalyzeConf = require('../AdAnalyzeConf');
const ListAdvertisementsHelper = require('../views/utils/ListAdvertisementsHelper');
const AdvertisementsHelper = require('../views/utils/AdvertisementsHelper');
const AppInstallBannerContainer = require('../android/AppInstallBannerContainer');
const {adminRecompute} = require('../admin/adminRecompute');
const {
    updateAdFeaturedProperty,
    canMarkAdsAsLeading,
    canHighlightAds,
} = require('../setFeatured');
const {SEARCH_RESULT_LIST_MODE} = require('../stats/components/constants');
const {updateMarketStatusProperties} = require('../ad/components/toggleMarket/helpers');
const {canBoostAd} = require('../notificationBoost');
const RealEstateAdNotificationBoostButton = require('../notificationBoost/components/RealEstateAdNotificationBoostButton');
const {
    DISPLAY_MODE_GALLERY,
    DISPLAY_MODE_LIST,
    DISPLAY_MODE_MAP,
} = require('./constants');
const Views = require('../views/Views');

const DETAILED_SHEET_ANIM_OPEN_LEFT_DURATION_IN_MS = 500;

module.exports = class ListView extends CompositeVueView {
    constructor({saveSearchAllowed, dataModeListEnabled, searchListModeModel, author, from} = {}) {
        super({
            template,
        });
        this.saveSearchAllowed = saveSearchAllowed;
        this.$searchResultsContainer = null;
        this.sideItemEventsBound = false;
        this._selectedTouchDirection = null;
        this.displayedRealEstateAds = [];
        this.dataModeListEnabled = dataModeListEnabled;
        this._onRemoveMapFilterClickedListener = _.bind(this.onRemoveMapFilterClicked, this);
        this._eventPack = new EventPack();
        this.searchListModeModel = searchListModeModel;
        this.author = author;
        this.from = from;
    }

    show(options) {
        super.show(options);
        this.$searchResultsContainer = this.$element.find('.sideListContainer');
        this._bindSideItemEvents();
        this._bindSearchResultsContainer();
        this._bindSearchListModeModel();
        AppInstallBannerContainer.searchAndUpdateContainer(this.$element);
    }

    _bindSearchListModeModel() {
        this._eventPack.on(this.searchListModeModel, {
            itemsPerRow: _.bind(this._refreshResultsInList, this),
            rowWidthUnderStandardValue: _.bind(this._refreshResultsInList, this),
        });
    }

    _refreshResultsInList() {
        if (!this.isLoading
            && !BrowserDetect.isMobile()
            && this.options) {
            this.hideSearchResults();
            this.showResultsInList(this.options);
        }
    }

    hideSearchResults() {
        if (this.$searchResultsContainer) {
            this.$searchResultsContainer.empty();
        }
        this.displayedRealEstateAds = {};
    }

    _bindSearchResultsContainer() {
        const click = {
            '.showRemoveMapFilter': _.bind(this._onRemoveMapFilterClickedListener, this),
            '.adminRecompute': _.bind(this._onAdminRecompute, this),
        };
        this._eventPack.on(this.$searchResultsContainer, {click});
    }

    _onAdminRecompute(event) {
        const $button = $(event.currentTarget);
        const id = $button.parents('article').attr('data-id');
        const {$searchResultsContainer} = this;
        adminRecompute({
            $button,
            $container: $searchResultsContainer,
            realEstateAdId: id,
        });
        event.stopPropagation();
        event.preventDefault();
    }

    hide(options, cb) {
        this.$searchResultsContainer = null;
        this._unbindSideItemEvents();
        this._eventPack.removeAllListeners();
        AppInstallBannerContainer.searchAndUpdateContainer(null);
        super.hide(options, cb);
    }

    _bindSideItemEvents() {
        if (!BrowserDetect.isIOS()) { // on iOS, the first tap is a hover if mousenter is bound, the second tap is the click
            this._eventPack.on(this.$element, {
                mouseenter: {
                    '.sideListItem': _.bind(this._onItemMouseEnter, this),
                },
                mouseleave: {
                    '.sideListItem': _.bind(this._onItemMouseLeave, this),
                },
            });
        }
    }

    _unbindSideItemEvents() {
        if (this.sideItemEventsBound) {
            this.sideItemEventsBound = false;
            this.$element.off('mouseenter', '.sideListItem');
            this.$element.off('mouseleave', '.sideListItem');
        }
    }

    showMessage($element) {
        this.hideMessage();
        this.$listViewMessageContainer = $('<div>').addClass('sideMessage').append($element);
        this.$element.prepend(this.$listViewMessageContainer);
    }

    hideMessage() {
        if (this.$listViewMessageContainer) {
            this.$listViewMessageContainer.remove();
            this.$listViewMessageContainer = null;
        }
    }

    forEachAdsItems(realEstateAds, callback) {
        if (realEstateAds) {
            _.each(this._findAllRealEstateAdItems(), element => {
                const $element = $(element);
                const id = $element.attr('data-id');
                const realEstateAd = _.find(realEstateAds, {id});
                if (realEstateAd) {
                    callback(realEstateAd, $element);
                }
            });
        }
    }

    updateDatesInList(realEstateAds) {
        this.forEachAdsItems(realEstateAds, (realEstateAd, $element) => {
            this._updateAdDates(realEstateAd, $element);
        });
    }

    _updateAdDates(realEstateAd, $element) {
        if (this._mustUpdateDates) {
            updateDateBySelector($element.find('.photoModificationDate'), 'modificationDate', realEstateAd);
            updateDateBySelector($element.find('.photoPublicationDate'), 'publicationDate', realEstateAd);
        }
    }

    setMustUpdateDates(mustUpdateDates) {
        if (this._mustUpdateDates != mustUpdateDates) {
            this._mustUpdateDates = mustUpdateDates;
            if (this.options) {
                this.updateDatesInList(this.options.realEstateAds);
            }
        }
    }

    _updateAdsElement(displayedAds) {
        this.forEachAdsItems(displayedAds, (realEstateAd, $element) => {
            this._updateAdDates(realEstateAd, $element);
        });
        this.displayedRealEstateAds = _.zipObject(_.map(displayedAds, 'id'), displayedAds);
    }

    setLoading(isLoading) {
        this.isLoading = isLoading;
    }

    showResultsInList(options) {
        this.options = options;
        this.filterType = getForcedFilterType(this.options.searchResults.searchCriteria.filterType);
        const getUrlFunction = options.getUrlFunction;
        const realEstateAds = options.realEstateAds || [];
        const leadingAds = options.leadingAds || [];
        const displayedAds = realEstateAds.concat(leadingAds);
        const searchResults = options.searchResults;
        this.searchResults = searchResults;
        if (displayedAds.length > 0) {
            if (getUrlFunction) {
                this._updateUrl(realEstateAds, getUrlFunction, {inHighlightedBox: false});
                this._updateUrl(leadingAds, getUrlFunction, {inHighlightedBox: true});
            }
            const html = this._renderSearchResultsListTemplate({
                options,
                leadingAds,
                realEstateAds,
            });
            this.$searchResultsContainer.append(html);
            findAndClampLines(html, '.reference', 2);
            //gives focus so keyboard can be used to move in the results fixes #151
            if (options.takeFocus) {
                this.takeFocus();
            }
            this._updateAdsElement(displayedAds);
            const uniqAds = _.unionWith(realEstateAds, leadingAds, (ad1, ad2) => ad1.id == ad2.id);
            this._injectVueInRealEstateAdItems(uniqAds);
        } else if (searchResults.totalResults > 0) {
            const resultsPerPage = searchResults.resultsPerPage;
            const lastPage = Math.ceil(searchResults.totalResults / resultsPerPage);
            _.defer(() => {
                //defer event to avoid listView being displayed twice
                this.emit('changePage', lastPage, {allowCriteriaChange: false});
            });
        } else {
            this._renderSearchResultsHeaderNoResultTemplate(this.searchResults.searchCriteria, options);
        }
        _.each(displayedAds, displayedAd => {
            this.emit('ad_refreshed', displayedAd);
        });
        const hasExtendedResults = searchResults && searchResults.hasExtendedResults();
        const searchCriteria = searchResults.searchCriteria;
        const extendedSearchCriteria = _.extend({}, searchCriteria, searchResults.updatedFilters);
        this.vueData = {
            searchCriteria: hasExtendedResults ? extendedSearchCriteria : searchCriteria,
            translationContext: hasExtendedResults ? 'extended' :
                (realEstateAds.length === 0 ? 'noResult' : undefined),
        };
        // @vue/component
        const vueOptions = {
            mixins: [
                i18nMixin(),
            ],
            data: () => this.vueData,
            computed: {
                openSearchCriteriaLabel() {
                    return this.t('searchResults.openSearchCriteria');
                },
                showOpenSearchBtnIfNoResult() {
                    return options.showOpenSearchBtnIfNoResult;
                },
            },
            methods: {
                saveSearchClick: () => {
                    this.emit('saveSearchClick', hasExtendedResults ? extendedSearchCriteria : searchCriteria);
                },
                removeAlertClick: (matchingSavedSearchId) => {
                    this.emit('removeAlertClick', matchingSavedSearchId);
                },
                openSearchCriteria() {
                    const headerView = Views.header;
                    headerView.openAdvancedSearchView();
                },
            },
        };
        this.injectVueViews(this.$searchResultsContainer, vueOptions);
    }

    _renderSearchResultsHeaderNoResultTemplate(searchCriteria, options) {
        const filterCount = SearchFiltersHelper.getFiltersCount(searchCriteria);
        $(this._renderTemplate(searchResultsHeaderNoResultTemplate, {
            showRemoveMapFilter: options.showRemoveMapFilter,
            authorId: options.authorId,
            filterCount,
            accountType: _.get(this.author, 'accountType'),
        })).appendTo(this.$searchResultsContainer);
    }

    _renderSearchResultsListTemplate({options, leadingAds, realEstateAds}) {
        return this._renderTemplate(searchResultsListTemplate, {
            leadingAds,
            realEstateAds,
            showRemoveMapFilter: options.showRemoveMapFilter,
            filterType: this.filterType,
            numberOfAdItemsPerRow: this._getNumberOfAdItemsPerRow(),
            itemsList: this._getItemsList(leadingAds, realEstateAds, options),
            advertisementData: this._getAdvertisementIntersticeData(),
        });
    }

    _getAdvertisementIntersticeData() {
        const type = this.searchListModeModel.getMode() === DISPLAY_MODE_LIST ? 'banner' : 'block';
        const searchCriteria = _.invoke(this, 'options.searchResults.getFilters') || {};
        return AdvertisementsHelper.getAdsData({page: 'searchResults', type, searchCriteria});
    }

    _getNumberOfAdItemsPerRow() {
        return this.searchListModeModel.getItemsQtyPerRaw();
    }

    onRemoveMapFilterClicked() {
        this.emit('removeMapFilterClicked');
    }

    _updateUrl(realEstateAds, getUrlFunction, inHighlightedBox) {
        _.each(realEstateAds, function (realEstateAd) {
            _.extend(realEstateAd, {
                detailedSheetUrl: getUrlFunction(realEstateAd, inHighlightedBox),
            });
        });
    }

    refreshAdDisplay(realEstateAd) {
        const $items = this._findRealEstateAdItems(realEstateAd);
        if ($items.length > 0) {
            _.each($items, item => {
                const $item = $(item);
                const detailedSheetUrl = $item.find('.detailedSheetLink').attr('href');
                const $newItem = $(this._renderTemplate(searchResultsItemTemplate, {
                    realEstateAd: _.extend({detailedSheetUrl}, realEstateAd),
                }));
                $newItem.replaceAll($item);

                const realEstateAds = this.resultsListContainerVueData.realEstateAds = updateRealEstateAdInArrayWithoutMutation(
                    this.resultsListContainerVueData.realEstateAds,
                    realEstateAd
                );
                this.options.realEstateAds = realEstateAds;
                this._injectVueInRealEstateAdItemsInElement($newItem, realEstateAds);

                findAndClampLines($item, '.reference', 2);
            });
            this.emit('ad_refreshed', realEstateAd);
        }
    }

    _injectVueInRealEstateAdItems(realEstateAds) {
        this._injectVueInRealEstateAdItemsInElement(this.$element.find('.resultsListContainer'), realEstateAds);
    }

    _injectVueInRealEstateAdItemsInElement($element, realEstateAds) {
        const {
            sortBy,
            statsEnabled,
            articleEditionEnabled,
            searchResults,
            lastViewDateForSearch,
        } = this.options;
        const displayMode = this.searchListModeModel.getMode();
        const listView = this;
        const resultsListContainerVueData = this.resultsListContainerVueData = {
            realEstateAds: _.map(realEstateAds, ad => {
                return _.cloneDeep(ad);
            }),
            isListViewShown: listView.isShown(),
        };
        // handle whether listview is visible to user, so as not to pollute DOM and display favorite button only when listView
        // is visible. Otherwise, when going to a detailed sheet page, favorite animation displays on two elements instead of one.
        this._eventPack.on(this, {
            realEstateAdClick: () => {
                const listViewShownTimeoutInMs =
                    displayMode === DISPLAY_MODE_MAP ? DETAILED_SHEET_ANIM_OPEN_LEFT_DURATION_IN_MS : 0;
                setTimeout(() => {
                    resultsListContainerVueData.isListViewShown = false;
                }, listViewShownTimeoutInMs);
            },
            from: (from) => {
                if (from === 'detailedSheetPage') {
                    resultsListContainerVueData.isListViewShown = true;
                }
            },
        });

        const hasSearchAroundExtendedResults = searchResults.hasSearchAroundExtendedResults();
        let ofTheLocationText = null;
        if (hasSearchAroundExtendedResults && _.isArray(searchResults.searchCriteria.locations)) {
            ofTheLocationText = searchResults.searchCriteria.locations[0].getOfTheLocationText();
        }

        // @vue/component
        this.injectVueViews($element, {
            components: {
                RealEstateAdNotificationBoostButton,
            },
            data() {
                return resultsListContainerVueData;
            },
            computed: {
                canBuyTemporaryLeadingAds() {
                    return Account.canBuyTemporaryLeadingAds();
                },
                hasToDisplayFavorites() {
                    return ApplicationConfig.hasToDisplayFavorites && !Account.isShowRoom();
                },
                isInSearchResultsList() {
                    return this.isDisplayModeList && this.isListViewShown;
                },
                isInSearchResultsGalleryOrMap() {
                    return _.includes([DISPLAY_MODE_MAP, DISPLAY_MODE_GALLERY], displayMode) && this.isListViewShown;
                },
                hasToDisplayFavoritesInSearchResultsItem() {
                    return this.hasToDisplayFavorites && this.isInSearchResultsList;
                },
                isDisplayModeList() {
                    return displayMode === DISPLAY_MODE_LIST;
                },
                ofTheLocationText() {
                    return ofTheLocationText;
                },
                disableAlreadySeen() {
                    return ApplicationConfig.disableAlreadySeen || Account.isShowRoom();
                },
                enableLastContactDateTag() {
                    return ApplicationConfig.enableLastContactDateTag;
                },
                sortBy() {
                    return sortBy;
                },
                lastViewDateForSearch() {
                    return lastViewDateForSearch;
                },
                isRowWidthUnderStandardValue() {
                    return listView.searchListModeModel.isRowWidthUnderStandardValue();
                },
                realEstateAdStatsMode() {
                    return displayMode === DISPLAY_MODE_LIST ? SEARCH_RESULT_LIST_MODE : null;
                },
            },
            methods: {
                canBoostAds(realEstateAd) {
                    return canBoostAd(realEstateAd) && this.isNotLot(realEstateAd);
                },
                applyFeaturedPropertyOnAd(realEstateAd, featureName, payload) {
                    this.updateRealEstateAd(updateAdFeaturedProperty(realEstateAd, featureName, payload));
                },
                hideAdUnpublishButton(realEstateAd) {
                    return this.displayHighlightAdButton(realEstateAd) ||
                        this.displayMarkAsLeadingAdButton(realEstateAd) ||
                        this.canBoostAds(realEstateAd);
                },
                hideHighlightAdText(realEstateAd) {
                    return this.displayMarkAsLeadingAdButton(realEstateAd) || this.canBoostAds(realEstateAd);
                },
                hideMarkAsLeadingAdText(realEstateAd) {
                    return this.canBoostAds(realEstateAd);
                },
                canModifyAd(realEstateAd) {
                    return realEstateAd.userRelativeData.canModifyAd;
                },
                canModifyAdBlur(realEstateAd) {
                    return realEstateAd.userRelativeData.canModifyAdBlur;
                },
                displayAdPublicationCertificateLink(realEstateAd) {
                    const {
                        canSeenOwnPublicationCertificate,
                        canSeePublicationCertificatePdf,
                    } = realEstateAd.userRelativeData;
                    return canSeenOwnPublicationCertificate || canSeePublicationCertificatePdf;
                },
                displayAdModificationLink(realEstateAd) {
                    return realEstateAd.status.onTheMarket &&
                        (this.canModifyAd(realEstateAd) || this.canModifyAdBlur(realEstateAd)) &&
                        (displayMode !== DISPLAY_MODE_LIST || !this.canBoostAds(realEstateAd));
                },
                isNotLot(realEstateAd) {
                    const isLot = ProgrammeHelper.isLot(realEstateAd);
                    return !isLot;
                },
                displayMarkAsLeadingAdButton(realEstateAd) {
                    return canMarkAdsAsLeading(realEstateAd) && this.isNotLot(realEstateAd);
                },
                displayHighlightAdButton(realEstateAd) {
                    return canHighlightAds(realEstateAd) && this.isNotLot(realEstateAd);
                },
                displayStats(realEstateAd) {
                    return realEstateAd.userRelativeData.canSeeStats && statsEnabled;
                },
                canChangeOnTheMarket(realEstateAd) {
                    return realEstateAd.userRelativeData.canChangeOnTheMarket;
                },
                displayManageAdsContainer(realEstateAd) {
                    return articleEditionEnabled && realEstateAd.status.onTheMarket;
                },
                applyOnTheMarketChange(realEstateAd, modifiedAd) {
                    const updatedAd = updateMarketStatusProperties(realEstateAd, modifiedAd);
                    this.realEstateAds = updateRealEstateAdInArrayWithoutMutation(
                        this.realEstateAds,
                        updatedAd
                    );
                    listView.refreshAdDisplay(updatedAd);
                },
                updateRealEstateAd(ad) {
                    listView.updateExistingAd(ad);
                },
                onDetailedSheetLinkClick(event) {
                    listView._onDetailedSheetLinkClick(event);
                },
            },
        });
    }

    updateExistingAd(realEstateAd) {
        this.resultsListContainerVueData.realEstateAds = updateRealEstateAdInArrayWithoutMutation(
            this.resultsListContainerVueData.realEstateAds,
            realEstateAd
        );
        const adToUpdateInOptions = _.filter(this.options.realEstateAds, {id: realEstateAd.id});
        const sourceAdForUpdate = _.filter(this.resultsListContainerVueData.realEstateAds, {id: realEstateAd.id});
        _.merge(adToUpdateInOptions, sourceAdForUpdate);
        _.merge(this.displayedRealEstateAds[realEstateAd.id], realEstateAd); // TODO: displayedRealEstateAds shouldn't exist as it should be the same as options.realEstateAds
    }

    updateOptions(options) {
        this.from = options.from;
        this.emit('from', this.from);
    }

    _renderTemplate(template, templateOptions) {
        const options = this.options;
        return super.renderTemplate(template, _.extend(
            {
                TextFormatter,
                RealEstateAdTitleGenerator,
                AddressFormatter,
                PriceFormatter,
                articleEditionEnabled: options.articleEditionEnabled,
                dataModeListEnabled: this.dataModeListEnabled,
                saveSearchAllowed: this.saveSearchAllowed,
                GeocodingPrecisionMatching,
                isAdmin: Account.isAdmin(),
                displayedAsHighlightedAdIds: options.displayedAsHighlightedAdIds,
            },
            AdAnalyzeConf.getConf(),
            templateOptions
        ));
    }

    showNoResults(options) {
        this.hideSearchResults();
        options.$element.appendTo(this.$searchResultsContainer);
    }

    findRealEstateAdItemFromId(id) {
        return this.$element.find('.sideListItem[data-id="' + id + '"]');
    }

    updateCardClass(realEstateAd) {
        const {resultsListContainerVueData} = this;
        if (resultsListContainerVueData) {
            resultsListContainerVueData.realEstateAds = updateRealEstateAdInArrayWithoutMutation(
                resultsListContainerVueData.realEstateAds,
                realEstateAd
            );
        }
    }

    _findRealEstateAdItems(realEstateAd) {
        return this.findRealEstateAdItemFromId(realEstateAd.id);
    }

    _findAllRealEstateAdItems() {
        return this.$element.find('.sideListItem');
    }

    _getRealEstateAdFromEvent($element) {
        const id = $element.attr('data-id');
        return this.displayedRealEstateAds[id];
    }

    _onItemMouseEnter(event) {
        const realEstateAd = this._getRealEstateAdFromEvent($(event.currentTarget));
        if (realEstateAd) {
            this.emit('resultInListHovered', realEstateAd);
        }
    }

    _onItemMouseLeave(event) {
        const realEstateAd = this._getRealEstateAdFromEvent($(event.currentTarget));
        if (realEstateAd) {
            this.emit('resultInListLeft', realEstateAd);
        }
    }

    _onDetailedSheetLinkClick(event, options = {}) {
        const {$item, realEstateAd} = this._getListItemAndAd(event);
        if ((realEstateAd && !isSoldRealEstateAd(realEstateAd)) || Account.isSoldAdsViewer()) {
            const inLeadingAdsBox = $item.attr('data-in-highlighted-box') == 'true';
            this.emit('realEstateAdClick', realEstateAd, {
                inHighlightedBox: inLeadingAdsBox,
                inLeadingAdsBox,
                scrollTo: options.scrollTo,
                contactOptions: options.contactOptions,
            });
            event.stopPropagation();
            event.preventDefault();
        } else {
            console.error('Trying to open realEstateAd :' + $item.attr('data-id')
                + ' from click which is not in displayedRealEstateAds', this.displayedRealEstateAds);
        }

    }

    _getListItemAndAd(event) {
        const $item = $(event.target).parents('.sideListItem');
        const realEstateAd = this._getRealEstateAdFromEvent($item);
        return {$item, realEstateAd};
    }

    scrollToRealEstateAd(realEstateAd, isMoreFiltersVisible) {
        if (this.$element) {
            const $item = this._findRealEstateAdItems(realEstateAd);
            if ($item.length == 0) {
                return;
            }
            //will work as long as the css values and class names won't change.
            let topPosition = isMoreFiltersVisible ? $('.searchFilterView').height() : 0;
            topPosition += $item.position().top;
            const scrollableElement = $('.searchResultsContainerConstantWidth');
            if ($item.length) {
                scrollableElement.scrollTop(topPosition);
            }
        }
    }

    toggleRealEstateAdHighlight(realEstateAd, flag) {
        const $item = this._findRealEstateAdItems(realEstateAd);
        _.each($('.sideListContainer > *'), function (el) {
            $(el).removeClass('highlighted');
        });
        if ($item.length) {
            $item.toggleClass('highlighted', flag);
        }
    }

    hasFocus() {
        return this.$searchResultsContainer ? this.$searchResultsContainer.is(':focus') : null;
    }

    takeFocus() {
        this.$searchResultsContainer.focus();
    }

    _getItemsList(leadingAds = [], realEstateAds = [], options = {}) {
        const enableCommercialAds = !this.searchListModeModel.isRowWidthUnderStandardValue() &&
            options.commercialAdsEnabledInSearchResults;
        return ListAdvertisementsHelper.getItemsList(
            this._getNumberOfAdItemsPerRow(),
            _.clone(leadingAds),
            _.clone(realEstateAds),
            {
                promotedAdsDisplayedOnTwoColumns: savedOptions.promotedAdsDisplayedOnTwoColumns,
                enableCommercialAds,
            }
        );
    }
};

function updateDateBySelector($element, dateKey, realEstateAd) {
    if (realEstateAd[dateKey]) {
        $element.attr('title', TextFormatter.formatDetailedDate(realEstateAd[dateKey]));
        $element.html(TextFormatter.formatAndTranslateField(realEstateAd[dateKey], dateKey, realEstateAd));
    }
}

function getForcedFilterType(filterType) {
    if (_.isArray(filterType)) {
        return _.first(filterType);
    } else {
        return filterType;
    }
}

/**
 * Returns a clone of input array with a single ad updated. This helper is used by Vue.js components to avoid mutation.
 */
function updateRealEstateAdInArrayWithoutMutation(realEstateAds, updatedRealEstateAd) {
    const updatedAds = _.clone(realEstateAds);
    const index = _.findIndex(updatedAds, {id: updatedRealEstateAd.id});
    updatedAds[index] = updatedRealEstateAd;
    return updatedAds;
}
