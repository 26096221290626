const _ = require('lodash');

const {DEFAULT_USER_SEARCH} = require('../../common/PropertyTypes');
const {getUserInputFilters} = require('../../common/SearchFiltersHelper');
const ServerConfig = require('../ServerConfig');
const Ajax = require('./Ajax');

const FAQ_SEO_CONTENT_ADS_COUNT_MINIMUM_THRESHOLD = 24;
const MANDATORY_SEO_CONTENT_CRITERIA = [
    'transactionType',
    'city',
    'postalCode',
];
const BOOLEAN_CRITERIA = [
    'newProperty',
    'hasToBeBuilt',
    'isLifeAnnuitySaleOnly',
    'isFurnished',
];
const ALLOWED_SEO_CONTENT_CRITERIA = [
    ...MANDATORY_SEO_CONTENT_CRITERIA,
    ...BOOLEAN_CRITERIA,
    'maxRooms',
    'propertyType',
    'locationType',
];

module.exports = {
    getSeoContentRequestFunction,
};

function hasOnlySeoContentAllowedCriteria(searchCriteria) {
    return _(searchCriteria)
        .thru(getUserInputFilters)
        .omit([
            'onTheMarket', // always true for unprivileged users
            'extensionType', // filled when admin
            'filterType', // replaced by transactionType
            'locations', // replaced by city & postalCode
            'locationNames', // redundant with 'locations' (present only on mobile)
            'buyNewPropertySetInFilterType', // present only on non-admin accounts. Redundant with 'newProperty'
            ...ALLOWED_SEO_CONTENT_CRITERIA,
        ])
        .isEmpty();
}

function hasAllMandatoryCriteria(query) {
    return _.every(MANDATORY_SEO_CONTENT_CRITERIA, criteriaKey => !_.isEmpty(_.get(query, criteriaKey)));
}

function shouldRequestSeoContent(searchCriteria, query) {
    return _.every([
        hasAllMandatoryCriteria(query),
        hasOnlySeoContentAllowedCriteria(searchCriteria),
    ]);
}

function getLocation(location) {
    if (_.isFunction(location.getLocationProperties)) {
        const {name, postalCodes, type} = location.getLocationProperties();
        return {
            city: name,
            postalCode: _.first(postalCodes),
            locationType: type,
        };
    } else {
        return null;
    }
}

function shouldRequestFaqContent({isExtendedWithSearchAround, totalResults}) {
    const hasEnoughAdsToDisplayFaq = totalResults >= FAQ_SEO_CONTENT_ADS_COUNT_MINIMUM_THRESHOLD;
    return !isExtendedWithSearchAround && hasEnoughAdsToDisplayFaq;
}

function getSeoContentRequestFunction(searchCriteria, withFaqOptions) {
    const {
        filterType,
        propertyType,
        maxRooms,
        locations,
    } = searchCriteria;
    const query = {
        withFaq: shouldRequestFaqContent(withFaqOptions),
    };
    const useDefaultPropertyTypes = _.isEqual(_.sortBy(propertyType), _.sortBy(DEFAULT_USER_SEARCH));
    if (!useDefaultPropertyTypes) {
        _.extend(query, {propertyType});
    }
    if (maxRooms > 0) {
        _.extend(query, {maxRooms});
    }
    if (_.size(locations) === 1) { // it must contain only one city
        _.extend(query, getLocation(_.first(locations)));
    }
    query.transactionType = _.isArray(filterType) ? _.first(filterType) : filterType;
    _.each(BOOLEAN_CRITERIA, name => {
        const value = searchCriteria[name];
        if (value != null) {
            query[name] = value;
        }
    });
    if (shouldRequestSeoContent(searchCriteria, query)) {
        return cb => {
            Ajax.request({
                url: `${ServerConfig.config.seoContentProviderUrl}/seo-content`,
                timeout: 30000,
                data: query,
                disableErrorPage: true,
                callback: cb,
            });
        };
    }
}
