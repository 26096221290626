const HoistedComponent = require('../internal/HoistedComponent');
const BootstrapModal = require('./BootstrapModal');
const template = require('./Modal.jade');

// @vue/component
module.exports = {
    components: {
        HoistedComponent,
        BootstrapModal,
    },
    props: {
        value: {
            type: Boolean,
            default: true,
        },
        title: {
            type: String,
            default: undefined,
        },
        pitch: {
            type: String,
            default: undefined,
        },
        closeButton: {
            type: Boolean,
            default: true,
        },
        dialogClass: {
            type: [String, Array, Object],
            default: undefined,
        },
        imageUrl: {
            type: String,
            default: undefined,
        },
        large: Boolean,
    },
    data() {
        return {
            open: this.value,
        };
    },
    computed: {
        imageStyle() {
            return {
                backgroundImage: `url(${this.imageUrl})`,
            };
        },
        fullDialogClass() {
            return [
                this.dialogClass,
                {
                    'modal-dialog__large': this.large,
                    'with-image': this.imageUrl,
                },
            ];
        },
    },
    watch: {
        value(value) {
            if (value) {
                this.open = value;
            }
        },
    },
    methods: {
        close() {
            this.$emit('input', false);
            this.$emit('close');
            this.open = false;
        },
    },
    template: template(),
};
