const _ = require('lodash');
const FileSaver = require('file-saver');
const i18nMixin = require('../../vue/components/mixins/i18n');
const template = require('./DownloadApplicationContainer.jade');
const Application = require('../Application');
const GoogleTagManager = require('../../stats/GoogleTagManager');
const UNAUTHORIZED_STATUS_CODE = 403;
const NOT_FOUND_STATUS_CODE = 404;
const TRANSLATIONS_PREFIX = 'DownloadApplicationContainer.';

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: TRANSLATIONS_PREFIX,
            keys: [
                'applicationStatus',
                'downloadApplicationButton',
                'modalTitle',
                'modalDescription',
                'confirmMessage',
            ],
        }),
    ],
    props: {
        contact: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            modalDisplayed: false,
            errorTranslationKey: undefined,
        };
    },
    computed: {
        filename() {
            const {displayName, realEstateAd, messageTitle} = this.contact;
            const filenameWithoutExtension = _.compact([
                displayName,
                messageTitle,
                realEstateAd.reference,
            ]).join(' - ');
            return `${filenameWithoutExtension}.pdf`;
        },
        isApplicationAvailable() {
            return Boolean(this.contact.rentalApplication);
        },
        applicationStatus() {
            return this.isApplicationAvailable
                ? this.t('applicationStatus.available.text')
                : this.t('applicationStatus.noApplication.text');
        },
        icon() {
            return this.isApplicationAvailable
                ? 'check-thin'
                : 'close';
        },
    },
    methods: {
        downloadRentalApplication(cb) {
            this.errorTranslationKey = undefined;
            Application.getApplicationAsPdf(
                this.contact.rentalApplication.id,
                this.contact.accountId,
                (err, response) => {
                    if (err) {
                        this.errorTranslationKey = TRANSLATIONS_PREFIX + getErrorMessageKey(err.statusCode);
                    } else {
                        FileSaver.saveAs(response, this.filename);
                        GoogleTagManager.sendEvent('rentalApplicationDownloaded');
                    }
                    cb(err);
                }
            );
        },
        toggleModal(modalDisplayed) {
            this.modalDisplayed = modalDisplayed;
        },
    },
    template: template(),
};

function getErrorMessageKey(errStatusCode) {
    switch (errStatusCode) {
        case UNAUTHORIZED_STATUS_CODE:
            return 'unauthorizedErrorMessage';
        case NOT_FOUND_STATUS_CODE:
            return 'notFoundErrorMessage';
        default:
            return 'defaultErrorMessage';
    }
}
