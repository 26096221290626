const $ = require('jquery');
const _ = require('lodash');
const browserDetect = require('browser-detect');
const template = require('../templates/searchSideView.jade');
const spinnerTemplate = require('../templates/searchSideViewSpinner.jade');
const CompositeVueView = require('../vue/CompositeVueView');
const SearchResumeView = require('./SearchResumeView');
const FooterView = require('../footer/FooterView');
const HideFullSearchView = require('../HideFullSearchView');
const Account = require('../authentication/Account');
const GoogleTagManager = require('../stats/GoogleTagManager');
const ApplicationConfig = require('../app/ApplicationConfig');
const {i18n: {translate}} = require('fack');
const searchExtendedAfterNoResultTemplate = require('../templates/search/results/searchExtendedAfterNoResult.jade');
const EventPack = require('../utils/EventPack');
const ScrollHelper = require('../utils/ScrollHelper');
const CriteriaListFormatter = require('../../common/CriteriaListFormatter');
const SimpleView = require('../views/SimpleView');
const savedSearchAdsFilterTemplate = require('../templates/savedSearchAdsFilterView.jade');
const SaveSearchView = require('./SaveSearchView');
const LocationManager = require('../LocationManager');
const PageManager = require('../pages/PageManager');
const PagesFactories = require('../pages/PagesFactories');
const SavedSearches = require('./SavedSearches');
const SearchFloatingContainerHelper = require('./SearchFloatingContainerHelper');
const {setSearchModeAndUpdateStyle} = require('../utils/safeFrameAdUtils');
const Options = require('../Options');
const {
    DISPLAY_MODE_GALLERY,
    DISPLAY_MODE_LIST,
    DISPLAY_MODE_MAP,
} = require('./constants');
const BreadcrumbHelper = require('../utils/BreadcrumbHelper');

const COLLAPSED_MODE_AD_HEIGHT = 100;

module.exports = class SearchSideView extends CompositeVueView {
    constructor(options) {
        super({
            template,
        });
        const configuration = this.configuration = _.defaults(options, {
            animations: {
                openAnimation: 'anim-open',
                closeAnimation: 'anim-close',
            },
            enableBreadcrumb: false,
            sortingEnabled: true,
        });
        this._saveSearchView = new SaveSearchView({
            disableSuccessPopup: true,
        });
        this.searchListModeModel = options.searchListModeModel;
        this._hideFullSearchView = new HideFullSearchView({
            saveSearchAllowed: options.saveSearchAllowed,
        });
        this.isMobile = browserDetect.isMobile();
        if (!options.disableSearchSideHeader) {
            this.searchListModeSelectionView = options.searchListModeSelectionView;
            this.searchResumeView = options.searchResumeView ||
                new SearchResumeView(_.extend({}, options, {
                    showCatalogueLink: Account.isCatalogueViewer() && !this.isMobile,
                }));
        }
        this.searchFilterView = options.searchFilterView;
        this.listView = options.listView;
        this.moreFiltersVisible = options.moreFiltersVisible;
        this.options = options;
        this.hasFixedCountAndSort = false;
        this._eventsWhileShown = new EventPack();
        this._eventsWhileResultsShown = new EventPack();
        this._eventsAdvancedCriteria = new EventPack();
        if (configuration.savedSearchResumeEnabled) {
            this.savedSearchAdsFilterView = new SimpleView(savedSearchAdsFilterTemplate);
        }
    }

    show(options) {
        this.options = options;
        const configuration = this.configuration;
        const {savedSearch, getPaginationUrl} = options;
        this.vueData = {
            showFloatingButton: false,
            hasNoResults: true,
            searchCriteria: options.search,
            savedSearch,
            sortBy: 'relevance',
            sortOrder: 'desc',
            showSortSelector: false,
            searchResults: null,
            pageIndex: 0,
            showSearchResultsCount: false,
            showPagination: false,
            breadcrumbData: null,
            showBreadcrumb: false,
            showSeoData: false,
            seoLinksCriteria: null,
            seoContent: '',
        };
        // @vue/component
        const vueOptions = {
            data: () => this.vueData,
            computed: {
                canReinitializeSearchesAds() {
                    return this.savedSearch && Options.get('savedSearchAdsPageEnabled');
                },
                isFloatingAlertButtonVisible() {
                    return this.showFloatingButton && !this.hasNoResults && options.saveSearchAllowed;
                },
                isSortSelectorVisible() {
                    return this.showSortSelector && configuration.sortingEnabled;
                },
                isBreadcrumbVisible() {
                    return this.showBreadcrumb && Array.isArray(this.breadcrumbData) && this.breadcrumbData.length;
                },
                shouldShowSeoData() {
                    return _.every([
                        this.seoLinksCriteria || this.seoContent.length,
                        this.showSeoData,
                        configuration.enableSeoContent,
                    ]);
                },
            },
            methods: {
                saveSearchClick: () => {
                    this.emit('saveSearchClick');
                },
                removeAlertClick: (matchingSavedSearchId) => {
                    this.emit('removeAlertClick', matchingSavedSearchId);
                },
                handleSortChanged: (sortBy, sortOrder) => {
                    this.emit('sortChanged', sortBy, sortOrder);
                },
                buildUrlForPage(pageNumber) {
                    return getPaginationUrl(pageNumber);
                },
                handleClickPaginationLink: (pageIndex) => {
                    this.emit('changePage', pageIndex + 1);
                },
            },
        };
        super.show(options, vueOptions);
        const {$element} = this;
        this.hasFixedCountAndSort = false;
        this.$searchSideHeader = $element.find('#searchSideHeader');
        this.$searchResultsContainer = $element.find('#searchResultsContainer');
        this.$searchResultsContainerList = $element.find('#searchResultsContainerList');
        this.$searchResultsContainerConstantWidth = $element.find('.searchResultsContainerConstantWidth');
        this.$searchSideViewCountAndSort = $element.find('#searchSideViewCountAndSort');
        this.$searchSideViewCountAndSortContainer = $element.find('#searchSideViewCountAndSortContainer');
        this.$searchSideViewCountAndSortScrollable = $element.find('.searchSideViewCountAndSortScrollable');
        this.$savedSearchResume = $element.find('.savedSearchResume');
        this._eventsWhileShown.on(this.listView, 'removeMapFilterClicked', () => {
            this.emit('removeMapFilterClicked');
        });
        this.toggleSearchSideViewCountAndSort(false);
        this._showSearchResumeViewIfNeeded(options);
        if (this.searchFilterView) {
            this.searchFilterView.$container = $element.find('.searchFilterFormContainer');
            this.searchFilterView.show(options);
        }
        const savedSearchAdsFilterView = this.savedSearchAdsFilterView;
        if (savedSearchAdsFilterView) {
            savedSearchAdsFilterView.setContainer(this.$savedSearchResume);
            if (options.savedSearch) {
                this._updateSavedSearchAdsFilterView(options.savedSearch);
            }
            this._eventsWhileShown.on(
                $element, 'click', '.search-criteria-summary__modification-button', _.bind(
                    this._showSaveSearchView,
                    this,
                    options.savedSearch
                )
            );
        }
        this._eventsWhileShown.on(this.$searchSideViewCountAndSort, 'moreFilterOpen', () => {
            this.toggleMoreFilters(true);
        });
        this.moreFiltersOpen = false;
        this.listView.$container = this.$searchResultsContainerList; // :(
        this.listView.show(options);
        this.updatePaddingTop();
        this.setMoreFiltersVisible(this.moreFiltersVisible);
        if (!Account.isShowRoom()) {
            this.footerView = this.configuration.footerView || new FooterView();
            this.footerView.setContainer($('.searchSideViewFooter'));
            this.footerView.show({
                isPro: ApplicationConfig.applicationPro,
                htmlFooterContent: options.htmlFooterContent,
            });
        }
        this.$footer = $element.find('footer');
        this.showWaitingForResults();
        const scrollListener = _.bind(this.handleScroll, this);
        this._eventsWhileShown.on(this.$searchResultsContainerConstantWidth, 'scroll', scrollListener);
        if (this.isMobile) {
            // detecte when savedSearches change on mobile
            this._eventsWhileShown.on(SavedSearches, 'savedSearchesLoaded', () => {
                const valueToSet = SearchFloatingContainerHelper
                    .getFloatingAlertButtonVisibility(this.vueData.showFloatingButton, this.searchCriteria, true);
                if (valueToSet != null) {
                    this.vueData.showFloatingButton = valueToSet;
                }
            });
        }
        if (this.searchResumeView && this.searchFilterView) {
            this._eventsWhileShown.on(this.searchResumeView, 'editFiltersClick', _.bind(this.toggleMoreFilters, this));
            this._eventsWhileShown.on(this.searchResumeView, 'saveSearchClick', () => {
                this.emit('saveSearchClick');
            });
            this._eventsWhileShown.on(this.searchResumeView, 'removeAlertClick', id => {
                this.emit('removeAlertClick', id);
            });
        }
        if (this.searchFilterView) {
            this._eventsWhileShown.on(this.searchFilterView, 'moreFilterOpen', () => {
                this.toggleMoreFilters(true);
            });
            this._eventsWhileShown.on(this.searchFilterView, 'moreFilterClose', () => {
                this.toggleMoreFilters(false);
            });
        }
        this._eventsWhileShown.on(this._hideFullSearchView, 'moreFilterClose', () => {
            this.toggleMoreFilters(false);
        });
        this.displayInSelectedMode();
        this._eventsWhileShown.on(this.searchListModeModel, 'mode', _.bind(this.displayInSelectedMode, this));
    }

    _showSaveSearchView(savedSearch) {
        if (savedSearch.accountId === Account.getAuthenticatedAccountId()) {
            LocationManager.addLocationNamesFromItemsInSearchCriteria(savedSearch.searchCriteria, (err) => {
                if (!err) {
                    this._saveSearchView.showSaveSearchModificationPopup(savedSearch, 'saved-search-alertpage');
                }
            });
        } else {
            PageManager.openPage(PagesFactories.searchPageFactory, {search: savedSearch.searchCriteria});
        }
    }

    _showSearchResumeViewIfNeeded(options) {
        if (this.searchResumeView && !this.searchResumeView.isShown()) {
            this.searchResumeView.$container = this.$searchSideHeader; // :(
            this.searchResumeView.show(options);
            this.searchListModeSelectionView.$container = this.$searchSideHeader.find('.searchListModeSelectionViewContainer'); // :(
            this.searchListModeSelectionView.show(options);
            if (options.updateSearchResumeWithSearchCriteria) {
                _.invoke(this, 'searchResumeView.onSearchFiltersChanged', this.searchCriteria, this.catalogueUrl);
            }
        }
    }

    setLoading() {
        this._hideFullSearchView.setLoading();
        this.vueData.showPagination = false;
        this.vueData.showBreadcrumb = false;
        this.vueData.showSeoData = false;
    }

    setResultsListVisible(visible) {
        const {$element} = this;
        if ($element) {
            $element.toggle(visible);
        }
        this.setResultsListAnimEnabled(false);
    }

    setResultsListAnimEnabled(enabled) {
        this.$element.find('#searchResultsContainerList').toggleClass('animEnabled', enabled);
    }

    hide(options, cb) {
        if (!Account.isShowRoom()) {
            this.footerView.hide();
        }
        this.vueData.showBreadcrumb = false;
        this.removeSpinner();
        this.vueData.showPagination = false;
        this.vueData.showFloatingButton = false;
        this.listView.hide();
        this.vueData.showSortSelector = false;
        this.vueData.showSearchResultsCount = false;
        if (this.searchFilterView) {
            this.searchFilterView.hide();
        }
        if (this.searchListModeSelectionView) {
            this.searchListModeSelectionView.hide();
        }
        if (this.searchResumeView) {
            this.searchResumeView.hide();
        }
        this.$searchResultsContainerList = null;
        if (this.moreFiltersOpen) {
            this.disabledResizeButtonCriteriaAdvanced();
        }
        this.vueData.showSeoData = false;
        this.vueData.seoLinksCriteria = null;
        this.vueData.seoContent = '';
        this._eventsWhileShown.removeAllListeners();
        this._eventsWhileResultsShown.removeAllListeners();
        this._eventsAdvancedCriteria.removeAllListeners();
        super.hide(options, cb);
    }

    setMoreFiltersVisible(moreFiltersVisible) {
        const {$element} = this;
        $element.find('.searchFilterView').toggle(moreFiltersVisible);
        $element.find('#searchSideViewCountAndSortContainer').toggleClass('noSearchVisible', !moreFiltersVisible);
        this.handleScroll();
    }

    _updateMoreFiltersVisible(moreFiltersVisible) {
        if (moreFiltersVisible != this.moreFiltersVisible) {
            this.moreFiltersVisible = moreFiltersVisible;
            this.setMoreFiltersVisible(moreFiltersVisible);
        }
    }

    onSearchFiltersChanged(searchCriteria, catalogueUrl) {
        this.searchCriteria = searchCriteria;
        this.catalogueUrl = catalogueUrl;
        this.vueData.searchCriteria = searchCriteria;
        _.invoke(this, 'searchResumeView.onSearchFiltersChanged', searchCriteria, catalogueUrl);
    }

    showResults(options) {
        const {searchResults, sortBy, sortOrder, realEstateAds, pageIndex} = options;
        const hasExtendedResults = searchResults && searchResults.hasExtendedResults();
        const hasSearchAroundExtendedResults = searchResults && searchResults.hasSearchAroundExtendedResults();
        this.vueData.showBreadcrumb = true;
        this.vueData.breadcrumbData = BreadcrumbHelper.getBreadcrumbPartsFromSearchResults(options);
        this._eventsWhileResultsShown.removeAllListeners();
        this.setResultsListAnimEnabled(true);
        this.scrollTop();
        this.removeSpinner();
        this.vueData.showSearchResultsCount = true;
        this.vueData.searchResults = searchResults;
        if (!hasSearchAroundExtendedResults) {
            this.vueData.sortBy = sortBy;
            this.vueData.sortOrder = sortOrder;
            this.vueData.showSortSelector = true;
            this.vueData.showPagination = true;
            this.vueData.showSeoData = true;
            this.vueData.pageIndex = pageIndex || 0;
        } else {
            this.vueData.showSortSelector = false;
        }
        this.handleScroll();
        this.listView.showResultsInList(options);
        this._updateExtendedResultsPitch(hasExtendedResults, hasSearchAroundExtendedResults);
        this.vueData.hasNoResults = hasExtendedResults || searchResults.totalResults === 0;
        if (realEstateAds.length === 0) {
            this.toggleSearchSideViewCountAndSort(false);
        }
        this._hideFullSearchView.updateNbResults(searchResults);
        if (this.searchFilterView) {
            this.searchFilterView.updateAdvancedFilterCounts(searchResults);
        }
        this.vueData.showSeoData = pageIndex === 0;
    }

    _updateExtendedResultsPitch(hasExtendedResults, hasSearchAroundExtendedResults) {
        const $extendedResultsPitch = this.$element.find('.extendedResultsPitch');
        $extendedResultsPitch.empty();
        if (hasExtendedResults) {
            const $resultsExtendedPitch = $(searchExtendedAfterNoResultTemplate({
                saveSearchAllowed: this.options.saveSearchAllowed,
                noResultMessage: this._noResultMessageTranslate(hasSearchAroundExtendedResults),
            }));
            $extendedResultsPitch.append($resultsExtendedPitch);
            // @vue/component
            const vueOptions = {
                data: () => {
                    return {
                        searchCriteria: this.searchCriteria,
                    };
                },
                methods: {
                    saveSearchClick: () => {
                        this.emit('saveSearchClick');
                    },
                    removeAlertClick: (matchingSavedSearchId) => {
                        this.emit('removeAlertClick', matchingSavedSearchId);
                    },
                },
            };
            this.injectVueViews($extendedResultsPitch, vueOptions);
            this.handleScroll();
        }
    }

    _noResultMessageTranslate(hasSearchAroundExtendedResults) {
        const {locations} = this.searchCriteria;
        const inTheLocationText = _.isArray(locations) && locations.length > 0 && locations[0].getInTheLocationText();
        return hasSearchAroundExtendedResults ?
            translate('searchResults.noResultForExtendedSearchAround', {inTheLocationText}) :
            translate('searchResults.noResultForExtendedSearchWithCriteria');
    }

    showNoResults(options) {
        this.removeSpinner();
        this.vueData.showSearchResultsCount = true;
        this.vueData.searchResults = {totalResults: 0};
        this.vueData.showSortSelector = false;
        this.listView.showNoResults(options);
        this._hideFullSearchView.updateNbResults({totalResults: 0});
        this.scrollTop();
        this.toggleSearchSideViewCountAndSort(false);
    }

    showWaitingForResults() {
        this.$footer.hide();
        this.toggleSearchSideViewCountAndSort(false);
        if (!this.$spinner) {
            this.$spinner = $(this.renderTemplate(spinnerTemplate)).appendTo(this.listView.$element);
        }
        this.listView.setLoading(true);
        this.vueData.showSeoData = false;
        this.vueData.seoLinksCriteria = null;
        this.vueData.seoContent = '';
    }

    removeSpinner() {
        this.$footer.show();
        if (this.$spinner) {
            this.$spinner.remove();
            this.$spinner = null;
        }
        this.toggleSearchSideViewCountAndSort(true);
        this.listView.setLoading(false);
    }

    _areMoreFiltersOpen() {
        return this.$element.find('#editFilters').hasClass('allFiltersOpen');
    }

    toggleMoreFilters(open) {
        const googleTagManagerMessage = open ? 'openMoreFilters' : 'closeMoreFilters';
        GoogleTagManager.sendEvent(googleTagManagerMessage);
        this.moreFiltersOpen = open || !this.moreFiltersOpen;
        const {$element} = this;
        this._hideFullSearchView.$container = $element;
        const $searchResultsContainerConstantWidth = $element.find('.searchResultsContainerConstantWidth');
        const $editFilters = $element.find('#editFilters');
        $editFilters.toggleClass('allFiltersOpen', this.moreFiltersOpen);
        const $editFiltersTitle = $editFilters.find('.editFiltersTitle');
        if (this.moreFiltersOpen) {
            this._hideFullSearchView.show();
            this.listView.$element.hide();
            this.searchFilterView.toggleMoreFilters(true);
            $element.find('#searchResultsContainer').toggleClass('moreFilterOpen', true);
            this.searchFilterView.$element.toggleClass('fullHeight', true);
            this.toggleSearchSideViewCountAndSortContainerImportant(false);
            this.searchFilterView.$element.show();
            $editFiltersTitle.html(translate('searchResume.backToResults'));
            $searchResultsContainerConstantWidth.scrollTop(0);
            this.$footer.toggleClass('isHidden', true);
            this.emit('moreFiltersOpen');
            this.enabledResizeButtonCriteriaAdvanced();
        } else {
            this.$footer.toggleClass('isHidden', false);
            $searchResultsContainerConstantWidth.scrollTop(0);
            this._hideFullSearchView.hide();
            this.listView.$element.show();
            $editFiltersTitle.html(translate('searchResume.' +
                (this.options.saveSearchAllowed ? 'editFilters' : 'editFiltersOther')));
            this.searchFilterView.toggleMoreFilters(false);
            this.searchFilterView.$element.toggleClass('fullHeight', false);
            $element.find('#searchResultsContainer').toggleClass('moreFilterOpen', false);
            this.toggleSearchSideViewCountAndSortContainerImportant(true);
            this.disabledResizeButtonCriteriaAdvanced();
            this.setMoreFiltersVisible(this.moreFiltersVisible);
        }
    }

    resizeButtonCriteriaAdvanced() {
        const {$element} = this;
        const $searchResultsContainer = $element.find('#searchResultsContainer');
        const $searchSideViewCountAndSortContainerBg = $element.find('.searchSideViewCountAndSortContainerBg');
        const $hideFullSearchContainer = $element.find('.hideFullSearchContainer');
        const currentHeight = $searchResultsContainer.height();
        const totalHeight = $searchSideViewCountAndSortContainerBg.height() + $hideFullSearchContainer.height();
        let value = currentHeight - totalHeight;
        value = value > 0 ? value : 0;
        $hideFullSearchContainer.css({
            bottom: value + 'px',
        });
    }

    enabledResizeButtonCriteriaAdvanced() {
        if (!this.isMobile) {
            const resizeButtonHandler = _.bind(this.resizeButtonCriteriaAdvanced, this);
            this._eventsAdvancedCriteria.on($(window), 'resize', resizeButtonHandler);
            this._eventsAdvancedCriteria.on(this._hideFullSearchView, 'resizeButtonCriteriaAdvanced', resizeButtonHandler);
            this.resizeButtonCriteriaAdvanced();
        }
    }

    disabledResizeButtonCriteriaAdvanced() {
        if (!this.isMobile) {
            this._eventsAdvancedCriteria.removeAllListeners();
        }
    }

    toggleSearchSideViewCountAndSortContainerImportant(visible) {
        const {$element} = this;
        $element.find('#searchSideViewCountAndSortContainer').toggleClass('hiddenImportant', !visible);
        $element.find('#searchSideViewCountAndSort').toggleClass('hiddenImportant', !visible);
        this.handleScroll();
    }

    displayInSelectedMode() {
        const mode = this.searchListModeModel.getMode();
        const fullWidth = (mode !== DISPLAY_MODE_MAP);
        const galleryResult = _.includes([DISPLAY_MODE_MAP, DISPLAY_MODE_GALLERY], mode);
        const listResults = mode === DISPLAY_MODE_LIST;
        this.listView.setMustUpdateDates(listResults);
        this.$element
            .toggleClass('fullWidth', fullWidth)
            .toggleClass('galleryResults', galleryResult)
            .toggleClass('listResults', listResults)
        ;
        this.scrollTop();
        if (!this.isMobile) {
            this.resizeButtonCriteriaAdvanced();
        }
        // if safeFrame ad is loaded
        setSearchModeAndUpdateStyle(mode);
    }

    handleScroll() {
        const searchSideHeaderBottom = ScrollHelper.getHeaderBottom() + this.$searchSideHeader.height();
        const safeFrameElement = document.getElementById('safeframeAd');
        const collapsedSafeFrameHeight = safeFrameElement ? COLLAPSED_MODE_AD_HEIGHT : 0;
        const countAndSortTop = this.$searchSideViewCountAndSortScrollable.offset().top - collapsedSafeFrameHeight;
        const resultsDisplayed = this.$searchSideViewCountAndSortScrollable.is(':visible');
        const hasFixedCountAndSort = resultsDisplayed && searchSideHeaderBottom > countAndSortTop;
        this.toggleFixedCountAndSort(hasFixedCountAndSort);
        const lastAds = this.$searchResultsContainerList.find('.resultsListContainer > article:nth-last-child(2)');
        const valueToSet = SearchFloatingContainerHelper
            .handleScroll(lastAds, this.searchCriteria, hasFixedCountAndSort, this.isMobile);
        if (valueToSet != null) {
            this.vueData.showFloatingButton = valueToSet;
        }
    }

    toggleFixedCountAndSort(enabled) {
        if (this.options.moreFiltersVisible) {
            this.$searchSideHeader.toggleClass('reducedHeader', enabled);
        } else { //favorites & co
            this.$searchSideHeader.toggleClass('reducedHeader', true);
        }
        if (this.hasFixedCountAndSort === enabled) {
            return;
        }
        this.hasFixedCountAndSort = enabled;
        if (enabled) {
            this.$searchSideViewCountAndSortContainer.css({
                height: this.$searchSideViewCountAndSort.height() + this.$element.find('.extendedResultsPitch').height() + 'px',
            });
            this.$searchSideViewCountAndSort.detach().insertAfter(this.$searchSideHeader);
        } else {
            this.$searchSideViewCountAndSortContainer.css({height: ''});
            this.$searchSideViewCountAndSort.detach().appendTo(this.$searchSideViewCountAndSortContainer);
        }
    }

    toggleSearchSideViewCountAndSort(visible) {
        this.$searchSideViewCountAndSortContainer.toggle(visible);
        this.$searchSideViewCountAndSort.toggle(visible);
    }

    updatePaddingTop() {
        let top = 0;
        if (!this.configuration.disableSearchSideHeader) {
            top = $('#searchSideHeader').outerHeight();
        } //headerHeight
        this.$searchResultsContainer.css({'padding-top': top + 'px'});
        this.scrollTop();
    }

    shouldPlayOpenAnimation() {
        return this.shouldAnimate();
    }

    shouldPlayCloseAnimation() {
        return this.shouldAnimate();
    }

    shouldAnimate() {
        return this.options.doesWindowSupportSplitScreen
            && this.searchListModeModel.getMode() === DISPLAY_MODE_MAP
            && !this.isMobile;
    }

    update(options) {
        const {doesWindowSupportSplitScreen, savedSearch, moreFiltersVisible} = options;
        this.options.doesWindowSupportSplitScreen = doesWindowSupportSplitScreen;
        if (moreFiltersVisible != null) {
            this._updateMoreFiltersVisible(moreFiltersVisible);
        }
        if (options.type === 'detailedSheet') {
            this.playCloseAnimation(_.noop, '.darkCoverElement');
        } else {
            this.playOpenAnimation(_.noop, '.darkCoverElement');
        }
        this._showSearchResumeViewIfNeeded(_.extend(options, {updateSearchResumeWithSearchCriteria: true}));
        if (this.savedSearchAdsFilterView && savedSearch) {
            this._updateSavedSearchAdsFilterView(savedSearch);
        }
    }

    _updateSavedSearchAdsFilterView(savedSearch) {
        const {searchCriteria = {}, saveName = null, emailNotificationMode, pushNotificationMode} = savedSearch;
        this.savedSearchAdsFilterView.hide();
        const criteriaList = CriteriaListFormatter.getCriteria(searchCriteria);
        this.savedSearchAdsFilterView.show();
        // @vue/component
        const vueOptions = {
            computed: {
                criteriaList() {
                    return criteriaList;
                },
                showNotificationState() {
                    return false;
                },
                saveName() {
                    return saveName;
                },
                emailNotificationMode() {
                    return emailNotificationMode;
                },
                pushNotificationMode() {
                    return pushNotificationMode;
                },
                criteriaModificationEnabled() {
                    return true;
                },
                criteriaModificationHidden() {
                    return true;
                },
                isOwner() {
                    return savedSearch.accountId === Account.getAuthenticatedAccountId();
                },
            },
        };
        this.injectVueViews(this.$savedSearchResume, vueOptions);
    }

    scrollTop() {
        if (!this._areMoreFiltersOpen()) {
            //bug http://git.f4-group.com/f4/kimono/issues/734 : scrollTop/scrollTo seems to work only on 'body' for mobile
            const $element = this.$searchResultsContainerConstantWidth;
            $element.prop('scrollTop', 0);
        }
    }
};
