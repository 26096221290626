const template = require('./DossierEditor.jade');
const ApplicantEditor = require('./applicant/ApplicantEditor');
const i18nMixin = require('../../vue/components/mixins/i18n');
const {getDossier, getApplicantDocumentsInDossier} = require('../Dossier');
const {getAuthenticatedAccountId} = require('../../authentication/Account');

// @vue/component
module.exports = {
    components: {
        ApplicantEditor,
    },
    mixins: [
        i18nMixin({
            prefix: 'DossierEditor.',
            keys: [
                'errorMessage',
                'retryButton',
            ],
        }),
    ],
    data() {
        return {
            dossier: undefined,
            hasError: false,
            isGettingDossier: false,
        };
    },
    computed: {
        applicantDocuments() {
            return this.dossier ? getApplicantDocumentsInDossier(this.dossier) : {};
        },
    },
    created() {
        this.fetchDossier();
    },
    methods: {
        fetchDossier() {
            this.isGettingDossier = true;
            this.hasError = false;
            getDossier(getAuthenticatedAccountId(), (err, dossier) => {
                if (err) {
                    this.hasError = true;
                    console.error('Could not get dossier', err);
                } else {
                    this.dossier = dossier;
                }
                this.isGettingDossier = false;
            });
        },
    },
    template: template(),
};
