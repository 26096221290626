const _ = require('lodash');
const {i18n: {translate}} = require('fack');

const rolesByCategory = {
    realEstateAds: [
        'adBlurModifier',
        'adModifier',
        'adPublisher',
        'contactViewer',
        'realDatesViewer',
        'virtualTourTester',
        'adContentWithoutFormViewer',
        'automaticVideosGenerator',
        'soldAdsViewer',
        'adContactDossierViewer',
        'adInfoViewer',
    ],
    blog: [
        'blogger',
    ],
    map: [
        'exactPositionViewer',
    ],
    accounts: [
        'accountDisabler',
        'accountModifier',
        'callTrackingNumberManager',
        'admin',
        'dirViewer',
        'adsRelevanceViewer',
        'adsRelevanceBonusModifier',
        'timedTokenGenerator',
        'searchesAdsReinitializer',
        'rolesViewer',
    ],
    email: [
        'welcomeSender',
    ],
    search: [
        'realSearchResultsCountViewer',
        'onTheMarketFilterViewer',
        'catalogueViewer',
        'queryStringSearcher',
        'referenceSearcher',
        'geocodingSearcher',
        'buildingPlotSearcher',
        'promotedAsExclusiveSearcher',
    ],
    others: [
        'betaTester',
        'accountStatsViewer',
        'publicationCertificateViewer',
        'showRoom',
        'importsViewer',
        'contactProvider',
        'liveTourContactProvider',
        'prospectingMapViewer',
        'adsNotificationBoostsHistoryViewer',
        'onlineMeetingStatsReImporter',
    ],
    emailStatus: [
        'emailStatusChecker',
    ],
    personalData: [
        'personalDataViewer',
    ],
};

module.exports = _.extend({
    getKeysList,
    getRolesByCategory,
    testAccount,
    getDescription,
}, getRoleTesters());

function getKeysList() {
    return _.flatten(_.values(rolesByCategory));
}

function getRolesByCategory() {
    return rolesByCategory;
}

function testAccount(account, role) {
    return Boolean(account && account.roles && _.includes(account.roles, role));
}

function getRoleTesters() {
    const roleTesters = {};
    _.each(getKeysList(), function (role) {
        roleTesters[role] = {
            test: _.partial(testAccount, _, role),
        };
    });
    return roleTesters;
}

function getDescription(role) {
    return translate(`roles.labels.${role}`);
}
