const $ = require('jquery');
require('../jqueryPlugins').load();

const _ = require('lodash');

const async = require('async');
const ServerConfig = require('../ServerConfig');
const Errors = require('../utils/Errors');
const browserDetect = require('browser-detect');
const WindowMessages = require('../utils/events/WindowMessages');
const Account = require('../authentication/Account');
const AdMessageHandler = require('../ads/AdMessageHandler');
const SavedSearches = require('../search/SavedSearches');
const crossroads = require('crossroads');
const History = require('./../utils/History');
const Router = require('../utils/Router');
const ApplicationConfig = require('./ApplicationConfig');
const Page404 = require('../pages/Page404');
const Views = require('../views/Views');
const Pages = require('../pages/Pages');
const PageManager = require('../pages/PageManager');
const GoogleTagManager = require('../stats/GoogleTagManager');
const LinkClick = require('../utils/LinkClick');
const RealtimeServer = require('../RealtimeServer');
const FavoritesManager = require('../favorites/FavoritesManager');
const SatisfactionSurvey = require('../satisfactionSurvey');
const UserAgentHelper = require('../../common/nativeApp/UserAgentHelper');
const BrowserWarningView = require('../BrowserWarningView');
const Options = require('../Options');
const Prerenderer = require('../utils/Prerenderer');
const {onPageLoaded: nativeOnPageLoaded} = require('../utils/NativeInterfaceProxy');
const Cookies = require('../Cookies');
const ServiceWorkerHandler = require('../notifications/ServiceWorkerHandler');
const LinkClickHandler = require('./LinkClickHandler');
const WebglBenchmark = require('../utils/WebglBenchmark');
const TokenAuthentication = require('../authentication/TokenAuthentication');
const LoadingTime = require('../stats/LoadingTime');
const bootstrapValidator = require('../fields/bootstrapValidator');
const FormPluginsHelper = require('../fields/FormPluginsHelper');
const SearchFieldsHelper = require('../searchFields/SearchFieldsHelper');
const Referrers = require('./Referrers');
const DirectOffersAndNewsLetterPopupHandler = require('../newsLetter/DirectOffersAndNewsLetterPopupHandler');
const {registerVueComponents} = require('../vue/components');
const AppInstallBannerContainer = require('../android/AppInstallBannerContainer');
const TrackedOrigin = require('./TrackedOrigin');
const {MIN_WINDOW_WIDTH_TO_DISPLAY_MAP} = require('../map/Constants');
const {detect: detectWebPSupport} = require('../utils/WebPSupport');
const TEXT_INPUT_FIELDS_SELECTOR = require('../utils/textInputFieldsSelector');
const store = require('../store');
const {detectNewUserFromI18nCookie} = require('../utils/NewUserDetector');
const {init: initDidomi} = require('../cmp/DidomiHelper');

const gtmContainerId = 'GTM-N8CZ9B';

AppInstallBannerContainer.bind();
detectNewUserFromI18nCookie(); // Must be done prior to i18n initialization and GTM initialization
initGTM();
LinkClick.registerJQueryPlugin();
fixIOS();

Prerenderer.init();

module.exports = {
    run,
};

function initGTM() {
    WindowMessages.bind(); // required for GTM jail
    GoogleTagManager.init(gtmContainerId);
    const {jailInjectionDeferredOncePageLoaded} = Options.read();
    if (jailInjectionDeferredOncePageLoaded) {
        PageManager.once('pageLoaded', GoogleTagManager.injectTrackingJail);
    } else {
        GoogleTagManager.injectTrackingJail();
    }
}

/**
 * fixes some iOS 7 issues
 * http://stackoverflow.com/questions/22391157/gray-area-visible-when-switching-from-portrait-to-landscape-using-ios-7-1-minima
 * http://stackoverflow.com/questions/12102235/how-to-fit-to-screen-after-changing-viewport-width-on-orientation-change
 */
function fixIOS() {
    const buggyIOS = /\b(?:iPhone|iPod|iPad).*?\bOS 7_[01]/.test(window.navigator.userAgent);
    if (buggyIOS) {
        $(window).on('orientationchange resize', applyFix);
        //http://stackoverflow.com/questions/2593139/ipad-web-app-detect-virtual-keyboard-using-javascript-in-safari
        //TODO find another event, check the blurred element, or anything else better
        // do NOT applyFix onFocusOut or it will really break long forms (on iPad it scrolls back to top every time you switch between inputs)
        // jQuery doc : "The focusout event is sent to an element when it, or any element inside of it, loses focus."
        //$(window).on('focusout', applyFix);
        // do NOT applyFix onBlur or it will break long forms (on iPad it scrolls back to top every time you switch between inputs)
        //$(window).on('blur', applyFix);

        $('meta[name=viewport]').attr('content', 'width=device-width,'
            + 'maximum-scale=1.0,'
            + 'minimum-scale=1.0,'
            + 'initial-scale=1.0,'
            + 'user-scalable=no'
        );
    }

    function applyFix() {
        window.scrollTo(0, 0);
    }
}

function run(applicationConfiguration) {
    _.each(applicationConfiguration.options, (value, key) => {
        Options.setForThisSessionOnly(key, value);
    });
    applicationConfiguration.savedOptions = Options.read();
    Referrers.init(applicationConfiguration);
    async.auto({
        serverConfig: _.partial(ServerConfig.load, {}),
        serviceWorker: [
            'serverConfig', cb => {
                ServiceWorkerHandler.init();
                _.defer(cb);
            },
        ],
        authentication: ['serverConfig', 'serviceWorker', _.partial(initAccount, applicationConfiguration)],
        detectWebPSupport,
        i18n: initI18n,
    }, function (err) {
        if (err) {
            Errors.showError(err);
        } else {
            //add referrer to account from url parameter or embeddedMode referrer
            const referrers = Referrers.getSessionReferrers();
            if (referrers.length > 0) {
                Account.addReferrers(referrers);
            }

            GoogleTagManager.setHost(applicationConfiguration.applicationPro);
            init(applicationConfiguration);
            WebglBenchmark.run();
        }
    });
}

function initI18n(cb) {
    const {i18n} = require('fack');
    i18n.init('fr', function () {
        cb(null);
    });
}

function initAccount(applicationConfiguration, initCallback) {
    Account.setGuestAccountEnabled(applicationConfiguration.guestAccountEnabled);
    Account.addAccountFilter(function (data, cb) {
        const isPro = Boolean(data && data.account && data.account.company);
        if (isPro && applicationConfiguration.requiresPublicAccount) {
            cb(null, {error: new Error('requiresPublicAccount')});
        } else if (!isPro && applicationConfiguration.requiresProAccount) {
            cb(null, {error: new Error('requiresProAccount')});
        } else {
            cb(null, data);
        }
    });
    Account.addAccountFilter(function (data, cb) {
        const isClosedOrDisabled = Boolean(data && data.account && (data.account.closed || data.account.disabled));
        if (isClosedOrDisabled) {
            cb(null, {error: new Error('closedOrDisabled')});
        } else {
            cb(null, data);
        }
    });
    //special case for reset password page which authenticate users from reset password token
    //History not yet initialized
    const pathname = location.pathname || '';
    if (_.startsWith(pathname, '/reinitialiser-mot-de-passe/')) {
        initCallback();
    } else {
        TokenAuthentication(isAuthenticated => {
            if (isAuthenticated) {
                initCallback();
            } else {
                Account.authenticateFromCookie(initCallback);
            }
        });
    }
}

// eslint-disable-next-line complexity
function init(applicationConfiguration) {
    bootstrapValidator.init();
    FormPluginsHelper.init();
    SearchFieldsHelper.init();
    RealtimeServer.connect();
    sendGoogleTagManagerLoadedInfo();
    SavedSearches.init();
    FavoritesManager.init();
    registerVueComponents();
    //Hack to fix size of app when keyboard visible
    const isMobile = browserDetect.isMobile();
    const isFromApp = UserAgentHelper.isFromNativeApp();
    const isTablet = browserDetect.isTablet();
    const isIOS = browserDetect.isIOS();
    const $body = $('body');
    const $document = $(document);
    const savedOptions = applicationConfiguration.savedOptions;

    SatisfactionSurvey.setActive();

    if (isFromApp) {
        require('./../android/AndroidNativeGate').init();
    }

    if (savedOptions.isInEmbeddedMode) {
        const overflowAuto = {overflow: 'auto'};
        $('html').css(overflowAuto);
        $body
            .css(isMobile ? overflowAuto : {'overflow-x': 'hidden'})
            .addClass('isInEmbeddedMode');
    }

    $body.toggleClass('hpContentEnabled', Options.get('isHomePageContentEnabled'));

    initDidomi();

    if ((!Cookies.hasDismissedNotSupportedDisclaimer()) && (!isFromApp) &&
        (!applicationConfiguration.isUnsupportedBrowser)) {
        const supportedOSCheck = require('../utils/SupportedOSCheck');
        const supportedBrowserCheck = require('../utils/SupportedBrowserCheck');
        supportedOSCheck.check(function (supportedOSCheckResult) {
            if (supportedOSCheckResult == 'osSupported') {
                supportedBrowserCheck.check(function (supportedBrowserCheckResult, advisableBrowserList) {
                    if (supportedBrowserCheckResult != 'browserSupported') {
                        const BrowserWarning = new BrowserWarningView();
                        const isBrowserSupported = supportedBrowserCheckResult == 'browserNotSupported';
                        BrowserWarning.show({
                            browserMessage: supportedBrowserCheckResult,
                            advisableBrowserList: isBrowserSupported ? advisableBrowserList : null,
                        });
                    }
                });
            } else {
                const BrowserWarning = new BrowserWarningView();
                BrowserWarning.show({browserMessage: supportedOSCheckResult});
            }
        });
    }

    $body
        .toggleClass('ios', isIOS)
        .toggleClass('app', isFromApp)
        .toggleClass('mobile', isMobile)
        .toggleClass('tablet', isTablet);
    if (isMobile || isTablet) {
        $body.on('focus', TEXT_INPUT_FIELDS_SELECTOR, function (e) {
            $body.css('height', $body.height());
            setTactileKeyboardVisible(true);
            $(e.target).on('blur', function () {
                $body.css('height', '100%');
                setTactileKeyboardVisible(false);
            });
        });
    }

    if (isTablet) {
        initMessageToChangeOrientation();
    }

    const AuthenticationViewController = require('../authentication/AuthenticationViewController');
    if (applicationConfiguration.requiresProAccount) {
        _.extend(AuthenticationViewController.options, {
            showCompanySection: true,
            showProContactSection: true,
            showOtherContactsSection: true,
            showApprovalQuestionsSection: true,
            showSocialConnection: false,
        });
    }

    const $mainPageContainer = $('.mainPageContainer');

    _.extend(ApplicationConfig, applicationConfiguration, {
        hasToDisplayFavorites: !applicationConfiguration.applicationPro && !savedOptions.isInEmbeddedMode
        && !applicationConfiguration.isOnPartnersDomain,
        showNeighborhoodDescriptionLinksInAdDetailedSheet:
            applicationConfiguration.showNeighborhoodDescriptionLinksInAdDetailedSheet && !savedOptions.isInEmbeddedMode,
    });

    const AuthenticationView = require('../authentication/AuthenticationView');
    const AuthenticationPopup = require('../authentication/AuthenticationPopup');
    const authenticationView = new AuthenticationView({
        $container: $mainPageContainer,
        origin: 'authenticationRequired',
    });
    const authenticationPopup = new AuthenticationPopup();

    TrackedOrigin.init();
    PageManager.init($mainPageContainer);
    LoadingTime.init();
    const VolatileFeedbackView = require('../views/VolatileFeedbackView');
    _.extend(Views, {
        authentication: authenticationView,
        volatileFeedback: new VolatileFeedbackView(),
        authenticationPopup,
    });
    const page404 = new Page404();
    Router.addRoute('/logout', function () {
        if (Account.isRegistered()) {
            Account.forgetAuthentication();
        }
        PageManager.restoreHomePageRoute();
        Router.redirectToUrl('/');
    });
    _.extend(Pages, {
        page404,
    });

    Account.bindAuthenticationMessageHandler();
    AdMessageHandler.bind();
    DirectOffersAndNewsLetterPopupHandler.bind();

    //ignoreState to ensure all calls to parseUrl triggers all events
    crossroads.ignoreState = true;

    crossroads.bypassed.add(function () {
        PageManager.openPage(page404);
    });

    $document.linkClick('a, area', LinkClickHandler.handleJqueryLinkClick);
    History.Adapter.bind(window, 'statechange', function () {
        //hide bootstrap modals
        $('.modal').modal('hide');

        const {url} = History.getState();
        if (_.includes(url, '#')) {
            LinkClickHandler.scrollToAnchor();
        }
        Router.parseCurrentUrl({parseOnlyIfChanged: true});
    });

    PageManager.on('pageLoaded', onPageLoaded);
    History.Adapter.bind(window, 'anchorchange', LinkClickHandler.scrollToAnchor);

    if (browserDetect.supportIosNativeScroll()) {
        $body.addClass('nativeScrollIos');
    }
    applicationConfiguration.configureRoutes(savedOptions);
    PageManager.overrideHomePageRoute();

    //todo move to pagehandler ?
    $document.on('click', '.linkToLogin', _.bind(authenticationPopup.showLogin, authenticationPopup, null));
    $document.on('click', '.linkToCreateAccount', _.bind(authenticationPopup.showCreateAccount, authenticationPopup, null));

    const OptionsPopup = require('../OptionsPopup');
    const optionsPopup = new OptionsPopup();
    $document.on('click', '.linkToOptions', _.bind(optionsPopup.showOptions, optionsPopup, null));

    // start application
    const $statusCode = $('.statusCode');
    let skipCurrentUrl = false;
    if ($statusCode.length > 0) {
        const statusCodeValue = $statusCode.attr('data-statusCode');
        if (statusCodeValue == '404') {
            skipCurrentUrl = true;
            PageManager.openPage(page404);
        } else {
            console.warn('unhandled statusCode', statusCodeValue);
        }
        $statusCode.remove();
    }
    if (!skipCurrentUrl) {
        Router.parseCurrentUrl();
    }
}

function setTactileKeyboardVisible(visible) {
    store.get().commit('setTactileKeyboardVisible', visible);
}

function onPageLoaded() {
    if (nativeOnPageLoaded) {
        nativeOnPageLoaded();
    }
    LinkClickHandler.scrollToAnchor();
}

function sendGoogleTagManagerLoadedInfo() {
    const eventName = 'googleTagManager:loaded';
    const info = {
        account: {
            id: Account.getAuthenticatedAccountId(),
        },
    };
    GoogleTagManager.setLoadedCallbacks({
        successCallback: function () {
            RealtimeServer.emit(eventName, _.extend({}, info, {
                success: true,
            }));
        },
        errorCallback: function () {
            RealtimeServer.emit(eventName, _.extend({}, info, {
                success: false,
            }));
        },
    });
}

function initMessageToChangeOrientation() {
    const COOKIE_NAME = 'ignore_orientation_warning';
    const CHANGE_ORIENTATION_CLASS = 'changeOrientation';
    const $window = $(window);
    const $body = $('body');
    const $changeOrientationBlock = $('.changeOrientationBlock');
    checkAndDisplayMessageToChangeOrientation();
    $changeOrientationBlock.on('click', function () {
        $.cookie(COOKIE_NAME, true, {
            path: '/',
            expires: 365, //one year
        });
        $body.toggleClass(CHANGE_ORIENTATION_CLASS, false);
    });
    $window.on('resize', checkAndDisplayMessageToChangeOrientation);

    function checkAndDisplayMessageToChangeOrientation() {
        const ignoreOrientationWarning = $.cookie(COOKIE_NAME);
        if (!ignoreOrientationWarning) {
            const isVerticalOrientation = $window.width() < $window.height();
            const minAndMaxSize = $window.width() < MIN_WINDOW_WIDTH_TO_DISPLAY_MAP
                && $window.height() > MIN_WINDOW_WIDTH_TO_DISPLAY_MAP;
            const isDisplayed = isVerticalOrientation && minAndMaxSize;
            $body.toggleClass(CHANGE_ORIENTATION_CLASS, isDisplayed);
        }
    }
}
