const _ = require('lodash');
const $ = require('jquery');
const {EventEmitter} = require('events');
const {i18n: {translate}} = require('fack');

const SearchPlacesHelper = require('../SearchPlacesHelper');

const template = require('../templates/components/addressInputWidgetWithoutMap.jade');
const templateSuggestionItem = require('../templates/citySuggestionItem.jade');

module.exports = class AddressInputWidgetWithoutMap extends EventEmitter {
    constructor(options) {
        super();
        options = options || {};
        options = _.defaults(options, {
            template,
            required: true,
            disabled: false,
            mergeCityAndPostalCode: false,
            useCityName: true,
            usePostalCode: true,
            useStreetName: true,
            useFlowPosition: true,
            useCustomBlurType: false,
            cityNameBeforeStreetName: true,
            parentName: null,
        });
        this._hasSuggestion = false;
        this._disabled = options.disabled;
        this._required = options.required;
        this._mergeCityAndPostalCode = options.mergeCityAndPostalCode;
        this._useCityName = options.useCityName;
        this._usePostalCode = options.usePostalCode;
        this._useStreetName = options.useStreetName;
        this._useFlowPosition = options.useFlowPosition;
        this._cityNameBeforeStreetName = options.cityNameBeforeStreetName;
        this._parentName = options.parentName;

        this.requiredFields = {};
        if (this._useCityName) {
            this.requiredFields.city = '.addressCity';
        }
        if (this._usePostalCode) {
            this.requiredFields.postalCode = '.postalCode';
        }
        if (this._useStreetName) {
            this.requiredFields.street = '.street';
        }

        this.$element = this._renderTemplate(options);
        this.$element.appendTo(options.$container);

        this.getSuggestions = _.throttle(function (text, callback) {
            if (text) {
                this.$inputBlock.addClass('loading');
                this._suggestionRequestsCount++;
                SearchPlacesHelper.querySuggestions(text, (error, suggestions) => {
                    --this._suggestionRequestsCount;
                    if (this._suggestionRequestsCount === 0) {
                        this.$inputBlock.removeClass('loading');
                    }
                    if (error) {
                        console.error(error);
                        callback(null);
                    } else {
                        callback(suggestions);
                    }
                    this._onRequestsChanged();
                });
            } else {
                callback([]);
            }
        }, 300);
        this._city = null;
        this._suggestionRequestsCount = 0;
        this._clearOnReadyCallbacks();
        this._initInputs();
        this._toggleFlowLocation(false);
        this._toggleCustomBlurType(false);
    }

    _getTemplateOptions(options) {
        return {
            t: translate,
            useCityName: options.useCityName,
            usePostalCode: options.usePostalCode,
            useStreetName: options.useStreetName,
            useFlowPosition: options.useFlowPosition,
            useCustomBlurType: options.useCustomBlurType,
            getNameFromParent: _.bind(this._getNameFromParent, this),
            cityNameBeforeStreetName: options.cityNameBeforeStreetName,
            cityLabel: options.cityLabel != null ? options.cityLabel : translate('city'),
            postalCodeLabel: options.postalCodeLabel != null ? options.postalCodeLabel : translate('postalCode'),
            streetLabel: options.streetLabel != null ? options.streetLabel : translate('address'),
            cityPlaceholder: options.cityPlaceholder != null ? options.cityPlaceholder : translate('cityPlaceholder'),
            postalCodePlaceholder: options.postalCodePlaceholder != null ? options.postalCodePlaceholder :
                translate('postalCodePlaceholder'),
            streetPlaceholder: options.streetPlaceholder != null ? options.streetPlaceholder :
                translate('addressPlaceholder'),
        };
    }

    _renderTemplate(options) {
        return $(options.template(this._getTemplateOptions(options)));
    }

    _getNameFromParent(typeName) {
        if (this._parentName) {
            return this._parentName + '.' + typeName;
        }
        return typeName;
    }

    _initInputs() {
        const datasetsConf = [
            {
                source: _.bind(this.getSuggestions, this),
                templates: {
                    suggestion: templateSuggestionItem,
                    empty: "<div class='noSuggestions'>" + translate('noCityFound') + '</div>',
                },
            },
        ];
        this.$cityInput = this.$element.find('input[name="' + this._getNameFromParent('city') + '"]');
        this.$postalCodeInput = this.$element.find('input[name="' + this._getNameFromParent('postalCode') + '"]');
        this.$streetInput = this.$element.find('input[name="' + this._getNameFromParent('street') + '"]');
        this.$inputBlock = this.$cityInput.closest('.form-group');

        this.$cityInput.f4typeahead({
            hint: true,
            highlight: true,
            minLength: 1,
            autoselect: 'first',
        }, datasetsConf)
            .bind('f4typeahead:selected', (event, suggestion) => {
                this.setSuggestion(suggestion);
                this.revalidate();
            })
            .bind('f4typeahead:queryChanged', $.proxy(() => {
                this._hasSuggestion = false;
            }))
            .bind('change', () => {
                this.revalidate();
                const value = this.$cityInput.val();
                const city = this.getCity();
                let expectedValue = '';
                if (city) {
                    const cityName = city.name;
                    const postalCode = city.postalCodes && city.postalCodes[0];
                    expectedValue = this._getCityFieldValue(cityName, postalCode);
                }
                if (expectedValue !== value) {
                    this._city = null;
                    this.$postalCodeInput
                        .attr('placeholder', '')
                        .val('')
                        .trigger('input')
                        .trigger('change');
                    if (this._showMap) {
                        this.updateLocation();
                    }
                }
            });
        if (this._required) {
            this.setRequiredField();
        } else if (this._disabled) {
            this.setDisabledField();
        }

        this.$cityInput.on('change', () => {
            this._hasSuggestion = false;
        });
    }

    setRequiredField() {
        _.each(this.requiredFields, (container, fieldName) => {
            const $container = this.$element.find(container);
            $container.find('input[name="' + fieldName + '"]').attr('required', true);
            let title = $container.find('label').html();
            title += '*';
            $container.find('label').html(title);
        });
    }

    setDisabledField() {
        _.each(this.requiredFields, (container, fieldName) => {
            const $container = this.$element.find(container);
            let disabledValue = true;
            if (_.isObject(this._disabled) && this._disabled[fieldName] != null) {
                disabledValue = this._disabled[fieldName];
            }
            $container.find('input[name="' + fieldName + '"]').attr('disabled', disabledValue);
        });
    }

    setSuggestion(suggestion) {
        if (suggestion && suggestion.city && suggestion.city.postalCodes) {
            const city = suggestion.city;
            if (this._mergeCityAndPostalCode && city.postalCodes && city.postalCodes.length) {
                this.setCityName(city.name, city.postalCodes[0]);
            }
            this.$postalCodeInput
                .attr('placeholder', city.postalCodes.join(','))
                .focus()
                .val(city.postalCodes[0])
                .trigger('input')
                .trigger('change');
            this._city = city;
            this._hasSuggestion = true;
        }
    }

    revalidate() {
        const $form = this.$cityInput.closest('form');
        if (this._cityValidatorInitialized) {
            $form.bootstrapValidator('revalidateField', this._getNameFromParent('city'));
        }
    }

    initValidators() {
        this._cityValidatorInitialized = true;
        const $form = this.$cityInput.closest('form');
        const bootstrapValidator = $form.data('bootstrapValidator');
        const suggestionValidators = {
            validators: {
                callback: {
                    callback: () => {
                        if (this._hasSuggestion) {
                            return true;
                        }
                        return {
                            message: translate('Veuillez choisir une suggestion.'),
                            value: false,
                        };
                    },
                },
            },
        };
        bootstrapValidator.addField(this._getNameFromParent('city'), suggestionValidators);
        bootstrapValidator.enableFieldValidators(this._getNameFromParent('city'), false, 'notEmpty');
    }

    getCity() {
        return this._city;
    }

    getCityName() {
        const city = this.getCity();
        let cityName = city && city.name || this.$cityInput.val() || '';
        if (cityName) {
            cityName = cityName.replace(/\s*[0-9].*$/, '');
        }
        return cityName;
    }

    getPostalCode() {
        const city = this.getCity();
        return _.trim(this.$postalCodeInput.val()) || (city && city.postalCodes && city.postalCodes[0]) || null;
    }

    getFieldValue() {
        return {
            street: this.$streetInput.val() || '',
            city: this.$cityInput.val() || '',
            postalCode: this.$postalCodeInput.val() || '',
        };
    }

    serializeInto(result) {
        //todo keys ends up being hardcoded, should use name
        _.extend(result, this.getFieldValue());
    }

    setCityName(cityName, postalCode) {
        this.$cityInput.val(this._getCityFieldValue(cityName, postalCode));
    }

    _getCityFieldValue(cityName, postalCode) {
        let name = cityName;
        if (this._mergeCityAndPostalCode && postalCode) {
            name += ' ' + postalCode;
        }
        return name;
    }

    setFieldValue(value) {
        if (!value) {
            value = {};
        }
        this.$streetInput.val(value.street);
        this.$postalCodeInput.val(value.postalCode);
        this.setCityName(value.city, value.postalCode);
        if (value.position && value.position.origin == 'flow') {
            this._toggleFlowLocation(true, value.position);
        }
        if (value.flowBlurType) {
            this._toggleCustomBlurType(true, value.flowBlurType);
        }
    }

    clear() {
        this.$element.remove();
    }

    show() {
        this.$element.show();
    }

    hide() {
        this.$element.hide();
        this._clearOnReadyCallbacks();
    }

    _isReady() {
        return this._suggestionRequestsCount === 0;
    }

    _onRequestsChanged() {
        if (this._isReady()) {
            _.each(this._onReadyCallbacks, function (callback) {
                callback();
            });
            this._clearOnReadyCallbacks();
        }
    }

    _clearOnReadyCallbacks() {
        this._onReadyCallbacks = [];
    }

    whenReady(cb) {
        if (this._isReady()) {
            cb();
        } else {
            this._onReadyCallbacks.push(cb);
        }
    }

    _toggleFlowLocation(show, position) {
        this.$element.find('.flowPosition').toggle(show);
        if (show) {
            position = position || {};
            const positionText = 'Latitude : ' + position.lat + ' / Longitude : ' + position.lng;
            this.$element.find('.flowPositionText').text(positionText);
        }
    }

    _toggleCustomBlurType(show, type) {
        this.$element.find('.customBlurType').toggle(show);
        if (show) {
            this.$element.find('.customBlurTypeText').text(translate(`accountForm.blurType.${type}`));
        }
    }
};
