const template = require('./SeeMoreDescription.jade');
const ContainerQueries = require('../../mixins/ContainerQueries');

// 10px less the actual height to avoid flickering
const MAX_DESCRIPTION_HEIGHT_IN_PX = 390;

// @vue/component
module.exports = {
    mixins: [
        ContainerQueries({
            shouldTruncateDescription: {
                minHeight: MAX_DESCRIPTION_HEIGHT_IN_PX,
            },
        }),
    ],
    props: {
        descriptionText: {
            type: String,
            default: '',
        },
        descriptionHtml: {
            type: String,
            default: '',
        },
        seeMoreButtonText: {
            type: String,
            default: '',
        },
        seeMoreButtonEnabled: Boolean,
    },
    data() {
        return {
            shouldTruncate: true,
        };
    },
    computed: {
        shouldTruncateDescription() {
            return this.shouldTruncate && this.breakpoints.shouldTruncateDescription;
        },
        isSeeMoreButtonDisplayed() {
            return this.seeMoreButtonEnabled && this.shouldTruncateDescription;
        },
        descriptionModifiers() {
            return {
                partial: this.shouldTruncateDescription,
            };
        },
        trimmedDescriptionText() {
            return this.descriptionText.trim();
        },
        trimmedDescriptionHtml() {
            return this.descriptionHtml.trim();
        },
    },
    methods: {
        disableExcerpt() {
            this.shouldTruncate = false;
        },
    },
    template: template(),
};
