const _ = require('lodash');
const FileUploaderWidget = require('../../../FileUploaderWidget');
const template = require('./fileUploaderMixin.jade');

// @vue/component
module.exports = {
    mixins: [
        require('./baseFieldMixin'),
    ],
    props: {
        name: {
            type: String,
            default: () => _.uniqueId('file-uploader-'),
        },
        multiple: Boolean,
        maxFiles: {
            type: Number,
            default: null,
        },
        loaderEnabled: Boolean,
    },
    data() {
        return {
            isProcessing: false,
            autoProcessQueue: true,
            parallelUploads: false,
        };
    },
    computed: {
        dataValueJson() {
            return JSON.stringify(externalValue(this.multiple, this.files));
        },
        files() {
            return valueAsArray(this.value);
        },
        showLoader() {
            return this.loaderEnabled && this.isProcessing;
        },
    },
    watch: {
        files(files) {
            this.uploader.setFiles(files);
        },
    },
    mounted() {
        const uploader = this.uploader = this.getUploader();
        uploader.on('loading-files', isProcessing => { // keep before uploader.init
            this.isProcessing = isProcessing;
        });
        uploader.init({
            container: this.$refs.container, // for the validator
            element: this.$refs.dropzone,
            name: this.name,
            addHiddenField: false,
            maxFiles: this.multiple ? this.maxFiles : 1,
            files: this.files,
            required: this.required,
            autoProcessQueue: this.autoProcessQueue,
            parallelUploads: this.parallelUploads,
            customTranslationFunction: this.customTranslationFunction,
        });
        uploader.on('filesChanged', files => {
            if (!_.isEqual(files, this.files)) {
                this.$emit('input', externalValue(this.multiple, files));
            }
            this.$emit('update:files-count', files.length);
        });
        uploader.on('uploading', () => {
            this.$emit('uploading');
        });
        uploader.on('uploads-finished', () => {
            this.isProcessing = false;
            this.$emit('uploads-finished');
        });
        uploader.myDropzone.on('errormultiple', (files, message) => {
            this.isProcessing = false;
            this.$emit('errormultiple', {files, message});
        });
        uploader.myDropzone.on('completemultiple', (data) => {
            const {xhr} = data[0];
            const files = _.map(_.reject(JSON.parse(xhr.response), ['status', 'error']), 'file');
            this.isProcessing = false;
            this.$emit('completemultiple', {files});
        });
        uploader.myDropzone.on('addedfile', () => {
            this.$emit('addedfile', uploader.myDropzone.files.length);
        });
        uploader.myDropzone.on('removedfile', (file) => {
            if (!file.status || ['error', 'queued'].indexOf(file.status) === -1) {
                this.isProcessing = true;
            }
            this.$emit('removedfile', file);
        });
        uploader.on('removeFinished', (file) => {
            this.isProcessing = false;
            this.$emit('removeFinished', file);
        });
        uploader.on('complete', (file) => {
            this.isProcessing = false;
            this.$emit('complete', file);
        });
        uploader.myDropzone.on('processing', (file) => {
            this.isProcessing = true;
            this.$emit('processing', file);
        });
    },
    destroyed() {
        this.uploader.clear();
    },
    methods: {
        getUploader() {
            return new FileUploaderWidget();
        },
        getQueuedFiles() {
            return this.uploader.myDropzone.getQueuedFiles();
        },
        processQueue() {
            return this.uploader.myDropzone.processQueue();
        },
    },
    template: template(),
};

function valueAsArray(value) {
    return _.isString(value) ? [value] : value;
}

function externalValue(multiple, files) {
    return multiple ? files : _.first(files);
}
