const util = require('util');
const _ = require('lodash');
const FieldInputWidget = require('./FieldInputWidget');
const ImageUploaderWidget = require('../ImageUploaderWidget');

module.exports = PhotoWidget;

function PhotoWidget($container, data) {
    this.data = data;
    FieldInputWidget.call(this, $container, data);
    this.imageUploaderWidget = new ImageUploaderWidget();
    this.imageUploaderWidget.init({
        $container: this.getElement().find('#logoUpload'),
        maxFiles: null,
        required: data.required,
        name: data.name,
    });

    return this;
}

util.inherits(PhotoWidget, FieldInputWidget);

PhotoWidget.prototype.whenReady = function (cb) {
    if (this.imageUploaderWidget.isUploadingFile()) {
        //this won't work with multiple simultaneous calls :(
        this.imageUploaderWidget.setUploadFinishedCallback(cb);
    } else {
        _.defer(cb);
    }
};

PhotoWidget.prototype.getFieldValue = function () {
    return this.imageUploaderWidget.getFiles();
};

PhotoWidget.prototype.setFieldValue = function (value) {
    return this.imageUploaderWidget.setFiles(value);
};

PhotoWidget.prototype.serializeInto = function (result) {
    const value = this.getFieldValue();
    const photos = [];
    _.each(value, function (image) {
        photos.push({
            photo: image,
        });
    });

    if (value != null) {
        result[this.name] = photos;
    }
};
