const _ = require('lodash');
const ApplicationPage = require('./ApplicationPage');
const PlaceDiscoveryView = require('../views/PlaceDiscoveryView');
const AsyncHelper = require('../utils/AsyncHelper');
const Error404 = require('../Error404');
const placeDetailsHelper = require('../PlaceDetailsHelper');
const UrlHelper = require('../../common/UrlHelper');
const {i18n: {translate}} = require('fack');
const PageManager = require('../pages/PageManager');
const CityNameFormatter = require('@bienici/city-name-formatter');
const PlaceNameFormatter = require('../../common/PlaceNameFormatter');
const UrlFormatter = require('../../common/UrlFormatter');
const urlUtil = require('url');

const MAX_META_DESCRIPTION_LENGTH = 160;

module.exports = class PlaceDiscoveryPage extends ApplicationPage {
    constructor() {
        super({
            name: 'placeDiscoveryPage',
            title: 'Découvrez',
            bodyClass: 'decouvrez-votre-futur-quartier',
            gtmCategory: 'DiscoveryContent',
        });
        this.asyncHelper = new AsyncHelper();
        this.placeDiscoverView = new PlaceDiscoveryView();
    }

    open(options) {
        const commonParams = {
            departmentTranslationKey: 'department.in.',
            cityFormatter: CityNameFormatter.cityNameToInTheCity,
        };
        this.options = _.extend(this.options, options, {
            previousUrl: this._getPreviousUrl(),
            placeTitle: PlaceNameFormatter.getPlaceTitle(options.placeDetails, {
                ...commonParams,
                showCodes: true,
            }),
            placeTitleWithoutCode: PlaceNameFormatter.getPlaceTitle(options.placeDetails, {
                ...commonParams,
                showCodes: false,
            }),
            placeMapTitle: PlaceNameFormatter.getPlaceTitle(options.placeDetails, {
                departmentTranslationKey: 'department.of.',
                cityFormatter: CityNameFormatter.cityNameToOfTheCity,
                showCodes: true,
            }),
            parentTitleWithoutCode: PlaceNameFormatter.getPlaceTitle(options.placeDetails, {
                ...commonParams,
                useParentTitle: true,
            }),
        });
        super.open(this.options);
    }

    _getPreviousUrl() {
        const previousUrl = PageManager.getPreviousUrl();
        if (previousUrl) {
            const pathname = urlUtil.parse(previousUrl).pathname;
            return UrlFormatter.getDetailedSheetRegexp().test(pathname) ? pathname : null;
        }
    }

    getMiddleViews() {
        return this.placeDiscoverView;
    }

    getUrlPattern() {
        return '/immobilier/{locationName*}';
    }

    parseUrl(url, locationName) {
        if (locationName) {
            const parts = locationName.split('/');
            const department = UrlHelper.slugify(parts.shift());
            let city = UrlHelper.slugify(parts.shift());
            let arrondissement;
            if (department == 'paris' && city != 'paris') { // handle /paris/arrondissement...
                arrondissement = city;
                city = department;
            } else if (city == 'paris' || city == 'lyon' || city == 'marseille') { // handle /dept/city/arrondissement...
                arrondissement = UrlHelper.slugify(parts.shift());
            }
            const district = UrlHelper.slugify(parts.shift());
            return {
                department,
                city,
                arrondissement,
                district,
            };
        } else {
            return {};
        }
    }

    getTitle() {
        return translate('placeDiscoveryPage.title', {placeTitle: this.options.placeTitle});
    }

    _loadData(options, callback) {
        if (options && options.department) {
            this.getPlaceDetails(options, (err, placeDetails) => {
                if (err) {
                    callback(err);
                } else if (placeDetails) {
                    callback(err, _.extend(options, {placeDetails}));
                } else {
                    callback(new Error404('place not found'));
                }
            });
        } else {
            callback(new Error404('no place'));
        }
    }

    getMetaDescription() {
        const placeTitle = PlaceNameFormatter.getPlaceTitle(this.options.placeDetails, {
            departmentTranslationKey: 'department.in.',
            cityFormatter: CityNameFormatter.cityNameToInTheCity,
            showCodes: true,
        });
        const metaDescription = super.getMetaDescription({
            placeTitle1: placeTitle,
            placeTitle2: ' ' + placeTitle,
        });
        if (metaDescription.length < MAX_META_DESCRIPTION_LENGTH) {
            return metaDescription;
        } else {
            return super.getMetaDescription({
                placeTitle1: placeTitle,
                placeTitle2: '',
            });
        }
    }

    getPlaceDetails(options, cb) {
        this.asyncHelper.doAsync({
            func: cb => placeDetailsHelper.getPlaceDetails(options, cb),
            callback: cb,
            name: 'getPlaceDetails',
        });
    }
};
