const _ = require('lodash');
const CompositeVueView = require('../vue/CompositeVueView');
const Account = require('../authentication/Account');
const template = require('../templates/userDirectory/user.jade');
const {zoho: {makeZohoAccountUrl}} = require('../Urls');
const PhoneNumberFormatter = require('@bienici/phone-number-formatter');
const phoneNumberHelper = require('../utils/PhoneNumberHelper');
const ContactAgencyButton = require('../agency/components/ContactAgencyButton');
const i18nMixin = require('../vue/components/mixins/i18n');

module.exports = {
    insertUserInfoView,
};

const EMPHASIZED_ROLES = ['admin'];

function insertUserInfoView($container, options) {
    const userInfoView = new CompositeVueView({
        $container,
        template,
    });
    const canSeeAccountStats = _.has(options, 'canSeeAccountStats') ? options.canSeeAccountStats : Account.isAccountStatsViewer();
    const canSeeAdsNotificationBoostsHistory = _.has(options, 'canSeeAdsNotificationBoostsHistory') ?
        options.canSeeAdsNotificationBoostsHistory : Account.isAdsNotificationBoostsHistoryViewer();
    const showAdminActions = canSeeAccountStats || Account.isAccountModifier() || Account.isAdmin() || Account.isWelcomeSender()
        || Account.isAccountDisabler() || canSeeAdsNotificationBoostsHistory;
    const templateOptions = _.extend({
        Account,
        makeZohoAccountUrl,
        phoneNumberHelper,
        PhoneNumberFormatter,
        showAdminActions,
    }, options);
    // @vue/component
    userInfoView.show(templateOptions, {
        constants: {
            ROLES_SEPARATOR: ', ',
        },
        components: {
            ContactAgencyButton,
        },
        mixins: [
            i18nMixin({
                prefix: 'UserInfoView.',
                keys: [
                    'rolesTitle',
                ],
            }),
        ],
        computed: {
            account: () => templateOptions.user,
            agencyForContact() {
                return {
                    id: this.account.id,
                };
            },
            displayName() {
                return this.account.display_name;
            },
            roles() {
                return this.account.roles;
            },
            contactGtmSource: () => options.contactGtmSource,
        },
        methods: {
            isRoleEmphasized(role) {
                return _.includes(EMPHASIZED_ROLES, role);
            },
            onAccountUpdated: () => {
                if (options.onAccountUpdated) {
                    options.onAccountUpdated();
                }
            },
        },
    });
}
