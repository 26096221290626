const _ = require('lodash');

module.exports = FieldsGroupWidget;

function FieldsGroupWidget() {
    this.fields = {};
}

const proto = FieldsGroupWidget.prototype;

proto.setFieldValue = function (value) {
    _.each(this.fields, field => {
        field.setFieldValue(value[field.name]);
    });
};

proto.getFieldValue = function () {
    const result = {};
    _.each(this.fields, field => {
        result[field.name] = field.getFieldValue();
    });
    return result;
};

proto.serializeInto = function (result) {
    _.extend(result, this.getFieldValue());
};

proto.addField = function (field) {
    this.fields[field.name] = field;
};
