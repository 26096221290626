const _ = require('lodash');
const urlUtils = require('url');
const ServerConfig = require('../ServerConfig');
const Errors = require('../utils/Errors');
const Router = require('../utils/Router');
const History = require('./../utils/History');
const OpenWindow = require('../utils/OpenWindow');
const ScrollHelper = require('../utils/ScrollHelper');
const ApplicationConfig = require('./ApplicationConfig');

let shouldOpenAllRoutesWithoutRouter = false;

module.exports = {
    openInSameWindow,
    handleLinkClick,
    handleJqueryLinkClick,
    scrollToAnchor,
    openAllRoutesWithoutRouter, // for tests
};

function openAllRoutesWithoutRouter() {
    shouldOpenAllRoutesWithoutRouter = true;
}

function handleJqueryLinkClick(event) {
    return handleLinkClick(this, event);
}

function handleLinkClick(link, event) {
    // event can be a jQuery event or a native event
    const isDefaultPreventedEvent = event.isDefaultPrevented ? event.isDefaultPrevented() : event.defaultPrevented;
    if (!isDefaultPreventedEvent) {
        const href = urlWithRedirection(link.getAttribute('href') || '');
        if (link.getAttribute('target') == '_blank') {
            openInOtherWindow(href, event);
        } else if (href) {
            if (href === '#') {
                // stop the 'popstate' event of history running
                // so that, 'statechange' event would not run and hide the modal
                event.preventDefault();
            } else {
                openInSameWindow(href, event);
            }
        }
    }
}

function openInSameWindow(href, event) {
    if (mustOpenWithoutRouter(href)) {
        // use default
    } else if (/^mailto:/.test(href) || /^tel:/.test(href)) {
        Errors.ignoreNextOnbeforeunload();
    } else if (/^#/.test(href)) {
        //anchor change, manual scrolling
        History.pushState(null, '', href);
        scrollToAnchor();
        if (event) {
            event.preventDefault();
        }
    } else if (isExternalLink(href)) {
        // pro is an external link on the same vhost
        if (!isOnWwwOrProSite(href) || ApplicationConfig.isOnPartnersDomain) {
            openInOtherWindow(href, event);
        }
    } else {
        History.pushState(null, '', href);
        Router.parseUrl(href);
        if (event) {
            event.preventDefault();
        }
    }
}

function isOnWwwOrProSite(url) {
    const {wwwUrl, proUrl} = ServerConfig.config;
    return _.some([wwwUrl, proUrl], targetUrl => {
        return _.startsWith(url, targetUrl);
    });
}

function mustOpenWithoutRouter(href) {
    const isPartnerLink = _.invoke(ApplicationConfig, 'shouldOpenInTheSameTab', href);
    const isScript = /^javascript:/.test(href);
    const isSkypeLink = /^skype:/.test(href);
    const isVerboliaLink = /^\/prix-immobilier/.test(href);
    return isPartnerLink || isScript || isSkypeLink || isVerboliaLink || shouldOpenAllRoutesWithoutRouter;
}

function urlWithRedirection(url) {
    return url.replace(/\/decouvrez\//, '/immobilier/');
}

function isExternalLink(href) {
    const linkUrl = urlUtils.resolve(location.href, href);
    const linkHost = urlUtils.parse(linkUrl).host;
    const currentHost = location.host;
    return linkHost != currentHost;
}

function scrollToAnchor() {
    const targetHash = History.getHash();
    if (targetHash && /^[-\w]+$/.test(targetHash)) {
        const target = document.getElementById(targetHash);
        if (target) {
            ScrollHelper.scrollToElement(target);
        }
    }
}

function openInOtherWindow(href, event) {
    OpenWindow.open({url: href});
    if (event) {
        event.preventDefault();
        event.stopPropagation();
    }
}
