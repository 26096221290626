const _ = require('lodash');
const Cookies = require('./Cookies');
const {EventEmitter} = require('events');
const eventEmitter = new EventEmitter();
const AbTesting = require('./AbTesting');
const UserAgentHelper = require('../common/nativeApp/UserAgentHelper');
const Ajax = require('./utils/Ajax');
const ServerConfig = require('./ServerConfig');
const EmbeddedModes = require('./app/embeddedModes/index');

const ONE_DAY_IN_MS = 24 * 3600 * 1e3;

module.exports = _.extend({
    read,
    save,
    getDefaults,
    get,
    setForThisSessionOnly,
}, eventEmitter);

const OPTIONS_COOKIE_NAME = 'kimonoOptions';
const isEmbedded = window.top != window.self;

const now = new Date();
const currentYear = now.getFullYear();
const christmasYear = getChristmasYear();

const bastilleDayStartDate = new Date(currentYear, 6, 14);
const bastilleDayEndDate = new Date(+bastilleDayStartDate + ONE_DAY_IN_MS);

const rugbyModeStartDate = new Date(2023, 8, 8);
const rugbyModeEndDate = new Date(2023, 9, 29);
const halloweenModeStartDate = new Date(currentYear, 9, 25);
const halloweenModeEndDate = new Date(currentYear, 10, 5);
const christmasModeStartDate = new Date(christmasYear, 11, 1);
const christmasModeEndDate = new Date(christmasYear + 1, 0, 6);

const defaultValues = {
    mapKeyboard: false,
    cutProgramme: false,
    showAllModels: false,
    facadeCameraEditorEnabled: false,
    alwaysLowBuilding: false,
    elevationsEnabled: false,
    displaySimilarAdsInDetailedSheet: false,
    christmasDesignEnabled: now > christmasModeStartDate && now < christmasModeEndDate,
    franceDesignEnabled: now > bastilleDayStartDate && now < bastilleDayEndDate,
    halloweenDesignEnabled: now > halloweenModeStartDate && now < halloweenModeEndDate,
    rugbyDesignEnabled: now > rugbyModeStartDate && now < rugbyModeEndDate,
    travelTimeLandingPageEnabled: false,
    googleLoginEnabled: UserAgentHelper.shouldEnableFeatureForAndroid('loginGoogle'),
    facebookLoginEnabled: UserAgentHelper.shouldEnableFeatureForAndroid('loginFacebook'),
    appleLoginEnabled: false,
    adSharingEnabled: UserAgentHelper.shouldEnableFeatureForAndroid('adSharing'),
    showPartnerServices: true,
    commercialAdsEnabled: true,
    // debug
    catchLogs: false,
    GoogleTagManagerDebugMode: false,
    devToolsFriendlySentryMode: false,
    // end debug flags
    savedSearchAdsPageEnabled: false,
    isInEmbeddedMode: isEmbedded,
    embeddedModeOverride: null,
    isHomePageFeaturesEnabled: true,
    isHomePageContentEnabled: true,
    commercialAdsSlideOverride: null,
    jailInjectionDeferredOncePageLoaded: false,
    financialPartnerCreditSimulationEnabled: true,
    chargingStationsEnabled: true,
    insuranceEstimationEnabled: false,
    rentalApplication: true,
    relatedAdsPosition: [
        'relatedAdsAfterDocumentationServices',
        'relatedAdsAfterDescription',
        'relatedAdsAfterSlideShow',
    ],
};

if (isEmbedded) {
    applyEmbeddedMode(document.referrer);
}

_.extend(defaultValues, AbTesting.init());

let previousOptions = read();

if (!isEmbedded && previousOptions.embeddedModeOverride) {
    applyEmbeddedMode(previousOptions.embeddedModeOverride);
    previousOptions = read();
}

let currentOptions = previousOptions;

function applyEmbeddedMode(url) {
    _.extend(defaultValues, EmbeddedModes.getOptions(url));
}

function save(options, cb) {
    const changedOptions = getChangedOptions(options);

    Cookies.setJsonCookie(OPTIONS_COOKIE_NAME, getChangedFromDefaultOptions(options), {
        path: '/',
        expires: 365,
    });

    // Fire events
    _.each(changedOptions, function (value, key) {
        eventEmitter.emit(key + '_changed', value);
    });

    // Save current state of all options
    previousOptions = read();

    saveCookieOnProDomain(OPTIONS_COOKIE_NAME, getChangedFromDefaultOptions(options), cb);
    currentOptions = changedOptions;
}

function saveCookieOnProDomain(name, value, cb) {
    Ajax.request({
        url: ServerConfig.config.proUrl + '/set_cookie',
        type: 'POST',
        data: {
            name,
            value: JSON.stringify(value),
        },
        xhrFields: {withCredentials: true},
        callback: function (err) {
            if (cb) {
                cb(err);
            }
        },
        disableErrorPage: true,
    });
}

// reduce cookie size by removing default values
function getChangedFromDefaultOptions(options) {
    const changedOptions = {};
    _.each(options, function (value, key) {
        if (!_.isEqual(value, defaultValues[key])) {
            changedOptions[key] = value;
        }
    });
    return changedOptions;
}

function getChangedOptions(options) {
    const changedOptions = {};
    _.each(options, function (value, key) {
        if (!_.isEqual(value, previousOptions[key])) {
            changedOptions[key] = value;
        }
    });
    return changedOptions;
}

function read() {
    const options = Cookies.getJsonCookie(OPTIONS_COOKIE_NAME) || {};
    return _.defaults(options, defaultValues);
}

function getDefaults() {
    return defaultValues;
}

function getChristmasYear() {
    return now.getMonth() > 6 ? currentYear : currentYear - 1;
}

function get(name) {
    return currentOptions[name];
}

function setForThisSessionOnly(name, value) {
    currentOptions[name] = value;
}
