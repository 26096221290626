const {i18n: {translate}} = require('fack');

const template = require('./HomePageContent.jade');
const i18nMixin = require('../../../vue/components/mixins/i18n');
const CommercialAd = require('../CommercialAd/CommercialAd');
const seoLinksBlocksLines = require('../../../../lib/seoLinksBlocksLines');
const ApplicationConfig = require('../../../app/ApplicationConfig');
const Options = require('../../../../js/Options');
const {PUBLIC_BLOG_PATH} = require('../../../../common/data/blogData');
const {
    PROJECTS_LINKS,
    USE_FULL_LINKS,
    FUTURE_LINKS,
    REAL_ESTATE_PRICE_IN_FRANCE_LINKS,
} = require('./links');

// @vue/component
module.exports = {
    components: {
        'kimono-hp-commercial-ad': CommercialAd,
    },
    mixins: [
        i18nMixin({
            prefix: 'HomePageContent.',
            keys: [
                'usefullLinksTitle',
                'newsAndAdviceTitle',
                'allOurNewsAndAdviceToStartTitle',
                'buyingNewTitle',
                'buyingNewButton',
                'buyingNewHtml',
                'findYourFutureLogementTitle',
                'yourSearchSimplifiedTitle',
                'yourSearchSimplifiedText',
                'franceIn3DTitle',
                'franceIn3DText',
                'yourNighborhoodTitle',
                'yourNighborhoodText',
                'realEstatePriceInFranceTitle',
                'homepageTitle',
            ],
        }),
    ],
    data() {
        return {
            projectsLinks: PROJECTS_LINKS,
            usefullLinks: USE_FULL_LINKS,
            futureLinks: FUTURE_LINKS,
            realEstatePriceInFranceLinks: REAL_ESTATE_PRICE_IN_FRANCE_LINKS,
            seoLinksBlocksLines,
            publicBlogPath: PUBLIC_BLOG_PATH,
        };
    },
    computed: {
        adType() {
            return this.isMobile ? 'block' : 'halfPage';
        },
        isEmbeddedMode() {
            return ApplicationConfig.isOnPartnersDomain || Options.get('isInEmbeddedMode');
        },
        exploreTheMarketBlogLink() {
            return `${PUBLIC_BLOG_PATH}/j-explore-le-marche`;
        },
    },
    methods: {
        translateKey(key) {
            return translate(key);
        },
    },
    template: template(),
};
