const _ = require('lodash');
const $ = require('jquery');
const FieldSerialization = require('./FieldSerialization');
const {i18n: {translate}} = require('fack');

module.exports = FieldInputWidget;

function FieldInputWidget($container, data) {
    this.template = data.template || this.template;
    this.$container = $container;
    //TODO data should really only contain data,
    //translationContext and the like should be split
    this.$element = $(this.template({
        title: data.title,
        data,
        required: data.required,
        t: translate,
        noLabel: data.noLabel,
        _,
    }));
    this.name = data.name;
    this.data = data;
    this.title = data.title;
    this.required = data.required;
    this.disabled = data.disabled;
    this.inputId = data.inputId;
    this.serialize = FieldSerialization.getSerializeFunction(data);
    this.initInput();
}

const proto = FieldInputWidget.prototype;
FieldInputWidget.prototype.template = require('../templates/fields/field.jade');

proto.initInput = function () {
    this.$container.append(this.$element);
    this.$input = this.$element.find(':input');
    if (this.required) {
        this.$input.attr('required', true);
    }
    if (this.disabled) {
        this.$input.attr('disabled', true);
    }
    if (this.inputId) {
        this.$input.attr('id', this.inputId);
    }
};

proto.clear = function () {
    this.$input.off();
    this.$element.remove();
};

proto.setFieldValue = function (value) {
    const $input = this.$input;
    if ($input.length > 1) {
        //for radio inputs
        _.each($input, function (singleInput) {
            const $singleInput = $(singleInput);
            if ($singleInput.attr('value') === ('' + value)) {
                $singleInput.attr('checked', 'checked');
            } else if ($singleInput.is(':checked')) {
                $singleInput.attr('checked', null);
            }
        });
    } else {
        $input.val(value);
    }
};

proto.getFieldValue = function () {
    let $input = this.$input;
    if ($input.length > 1) {
        //todo move radio inputs to special booleanWidget
        //for radio inputs
        $input = $(_.find($input, function (input) {
            return $(input).is(':checked');
        }));
    }
    return this.serialize($input.val());
};

proto.serializeInto = function (result) {
    const value = this.getFieldValue();
    if (value != null) {
        result[this.name] = value;
    }
};

proto.getElement = function () {
    return this.$element;
};

proto.show = function () {
    this.$element.show();
};

proto.hide = function () {
    this.$element.hide();
};
