const _ = require('lodash');
const _toSentence = require('underscore.string/toSentence');
const BaseTitleGenerator = require('./BaseTitleGenerator');
const CityLocationItem = require('../js/locationItems/CityLocationItem');
const {getCriteria} = require('./CriteriaListFormatter');
const {formatRangeForProperty} = require('./RangeTextFormatter');
const ApplicationConfig = require('../js/app/ApplicationConfig');
const NumberFormatter = require('./NumberFormatter');
const {FLAT_AND_HOUSE} = require('./PropertyTypes');
const intersects = require('./intersects');

const MAX_DISPLAYED_LOCATIONS_COUNT = 3;

const DEFAULT_TITLE_OPTIONS = {
    title: {
        showLocationCode: true,
    },
    h1: {
        showLocationCode: true,
    },
    resultsCount: {
        allowMultipleLocations: false,
    },
    breadcrumb: {
        showLocationCode: true,
    },
};

module.exports = {
    getTitle,
    formatLocationsNames,
};

function getTitle(searchOptions, titleKey, options) {
    const {
        showLocationCode,
        locationCodeFormat,
        allowMultipleLocations,
    } = _.defaults({}, options, DEFAULT_TITLE_OPTIONS[titleKey]);

    const searchOptionsForTitle = _.extend(
        {},
        searchOptions,
        {
            transactionType: getSingleTransactionType(searchOptions.filterType) || 'multiple',
            propertyType: getSinglePropertyType(searchOptions.propertyType),
        }
    );

    const titlePartsKeys = [
        'transactionTypePropertyType',
        'propertyTypeTransactionType',
    ];
    if (shouldReverseParts(searchOptionsForTitle)) {
        titlePartsKeys.reverse();
    }

    const contexts = [];
    const {pageTranslationContext, locations, page} = searchOptions;
    if (ApplicationConfig.applicationPro && searchOptionsForTitle.authorName) {
        contexts.push('userRealEstateAdsPro');
    }
    if (pageTranslationContext && pageTranslationContext != 'search') {
        contexts.push(pageTranslationContext);
    }
    const hiddenCriteria = ['propertyType'];
    if (!searchOptionsForTitle.propertyType) {
        if (isNewHousingBuySearch(searchOptions)) {
            contexts.push('newHousing');
            hiddenCriteria.push('newProperty');
        } else if (isLifeAnnuityBuySearch(searchOptions)) {
            hiddenCriteria.push('isLifeAnnuitySaleOnly');
        } else if (isPropertyWithoutUsufructSearch(searchOptions)) {
            hiddenCriteria.push('isPropertyWithoutUsufructOnly');
        } else {
            contexts.push('withoutPropertyType');
        }
    } else {
        hiddenCriteria.push('newProperty', 'isFurnished'); // already in propertyTypeWithAdjectives
    }
    if (searchOptionsForTitle.transactionType == 'multiple') {
        contexts.push('multipleTransactionTypes');
    }
    const searchCriteriaAsText = getCriteria(searchOptionsForTitle, {hiddenCriteria}).join(', ');
    if (searchOptionsForTitle.authorName || searchCriteriaAsText) {
        titlePartsKeys.pop(); // no alternative version
    }

    return BaseTitleGenerator.translateAndCleanup(
        'searchTitle.' + titleKey,
        _.extend(
            searchOptionsForTitle,
            {
                titlePartsKeys,
                locationsText: formatLocationsNames(locations, {
                    showLocationCode,
                    allowMultipleLocations,
                    locationCodeFormat,
                }),
                inTheLocationsText: formatLocationsNames(
                    locations, {
                        formatter: 'getInTheLocationText',
                        showLocationCode,
                        allowMultipleLocations,
                    }),
                ofTheLocationText: formatLocationsNames(
                    locations, {
                        formatter: 'getOfTheLocationText',
                        showLocationCode,
                        allowMultipleLocations,
                    }),
                hasLocation: Boolean(!_.isEmpty(locations) || searchOptions.limit),
                contexts,
                searchCriteriaAsText,
                withAuthorName: Boolean(searchOptions.authorName),
                formattedCount: NumberFormatter.formatNumber(searchOptionsForTitle.count),
                roomsQuantityAsText: getRoomsQuantityAsText(searchOptionsForTitle),
                shouldDisplayPage: page > 1,
            }
        )
    );
}

function getSingleTransactionType(transactionTypes) {
    transactionTypes = _.castArray(transactionTypes);
    return transactionTypes.length == 1 ? transactionTypes[0] : null;
}

function getSinglePropertyType(propertyTypes = []) {
    if (!_.isArray(propertyTypes)) {
        propertyTypes = propertyTypes.split(',');
    }
    return propertyTypes.length == 1 ? propertyTypes[0] : null;
}

function shouldReverseParts(criteria) {
    const {transactionType, newProperty} = criteria;
    const propertyTypeForTitle = BaseTitleGenerator.getPropertyTypeForTitle(criteria);
    return (transactionType == 'rent' && propertyTypeForTitle == 'shop')
        || (transactionType == 'buy'
            && !newProperty
            && _.includes(['house', 'terrain', 'building'], propertyTypeForTitle)
        );
}

function formatLocationsNames(locations, {formatter, showLocationCode, locationCodeFormat, allowMultipleLocations = true} = {}) {
    showLocationCode = showLocationCode && _.size(locations) == 1;
    if (_.size(locations) == 1 || allowMultipleLocations) {
        const locationNames = _.map(locations, _.partial(formatLocationName, _, {formatter, showLocationCode, locationCodeFormat}));
        if (locationNames.length > MAX_DISPLAYED_LOCATIONS_COUNT) {
            return locationNames.slice(0, MAX_DISPLAYED_LOCATIONS_COUNT).join(', ') + '…';
        } else {
            return _toSentence(locationNames, ', ', ' ou ');
        }
    }
}

function formatLocationName(location, {formatter = 'getText', showLocationCode, locationCodeFormat} = {}) {
    if (!(location instanceof CityLocationItem)) {
        formatter = 'getInTheLocationText';
    }
    let formattedLocationName = location[formatter]();
    const locationCode = _.first(_.result(location, 'getPostalCodes'));
    if (locationCode && showLocationCode && !_.includes(formattedLocationName, locationCode)) {
        if (locationCodeFormat && locationCodeFormat === 'department') {
            formattedLocationName += ` (${locationCode.substr(0, 2)})`;
        } else {
            formattedLocationName += ` (${locationCode})`;
        }
    }
    return formattedLocationName;
}

function getRoomsQuantityAsText(criteria) {
    const propertyTypeForTitle = BaseTitleGenerator.getPropertyTypeForTitle(criteria);
    if (_.includes(['twoRoomsFlat', 'threeRoomsFlat'], propertyTypeForTitle)) {
        return formatRangeForProperty('rooms', criteria);
    }
}

function isNewHousingBuySearch({filterType, newProperty, propertyType}) {
    return getSingleTransactionType(filterType) === 'buy'
        && newProperty
        && intersects(FLAT_AND_HOUSE, propertyType);
}

function isLifeAnnuityBuySearch({filterType, isLifeAnnuitySaleOnly, propertyType}) {
    return getSingleTransactionType(filterType) === 'buy'
        && isLifeAnnuitySaleOnly
        && intersects(FLAT_AND_HOUSE, propertyType);
}

function isPropertyWithoutUsufructSearch({filterType, isPropertyWithoutUsufructOnly, propertyType}) {
    return getSingleTransactionType(filterType) === 'buy'
        && isPropertyWithoutUsufructOnly
        && intersects(FLAT_AND_HOUSE, propertyType);
}
