const _ = require('lodash');

const UrlHelper = require('../../common/UrlHelper');
const LocalStorage = require('../utils/localStorage/LocalStorage');
const Cookies = require('../Cookies');

const TRACKING_CODE_STORAGE_KEY = 'trackingCode';
let trackingCode;
let firstVisitForTrackingCode = false;

module.exports = {
    init,
    getTrackedOriginValues,
};

function init() {
    const urlValue = UrlHelper.getURLTrackingParameters(location.href);
    const cookieValue = Cookies.getTrackingCode();
    const localStorageValue = LocalStorage.getValue(TRACKING_CODE_STORAGE_KEY);

    const hasUrlValue = !_.isEmpty(urlValue);
    const hasLocalStorageValue = !_.isEmpty(localStorageValue);
    const hasCookieValue = !_.isEmpty(cookieValue);

    trackingCode = (hasUrlValue && urlValue) ||
        (hasLocalStorageValue && localStorageValue) ||
        (hasCookieValue && cookieValue);

    const trackingCodeChanged = !_.isEqual(trackingCode, localStorageValue);

    if (trackingCodeChanged && trackingCode) {
        LocalStorage.setValue(TRACKING_CODE_STORAGE_KEY, trackingCode);
    }

    if (trackingCode) {
        firstVisitForTrackingCode = Boolean(urlValue);
    }
}

function getTrackedOriginValues() {
    if (trackingCode) {
        return {
            code: trackingCode,
            firstVisitForTrackingCode,
        };
    }
}
