const {isPriceValidForCreditSimulation} = require('../../common/PriceFormatter');
const {canSimulateCreditForPropertyType} = require('../../common/PropertyTypes');
const {isBuyTransactionAd} = require('../../common/AdTypeHelper');
const {canShowFinancialPartnerCreditSimulationLinks} = require('../financialPartners');

module.exports = function (realEstateAd) {
    const {price, propertyType} = realEstateAd;
    return canShowFinancialPartnerCreditSimulationLinks()
        && isBuyTransactionAd(realEstateAd)
        && isPriceValidForCreditSimulation(price)
        && canSimulateCreditForPropertyType(propertyType);
};
