const _ = require('lodash');
const template = require('./ContactApplicationSwitch.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');
const Account = require('../../authentication/Account');
const {getDossier} = require('../Dossier');
const DossierEditor = require('./DossierEditor');
const PresentationModal = require('./PresentationModal');
const VerificationModal = require('./VerificationModal');

// @vue/component
module.exports = {
    components: {
        DossierEditor,
        PresentationModal,
        VerificationModal,
    },
    mixins: [
        i18nMixin({
            prefix: 'ContactApplicationSwitch.',
            keys: [
                'title',
                'newBadge',
                'text',
                'errorMessage',
                'retryButton',
                'dossierLoading',
                'modalValidationButtonText',
                'modalIncompleteButtonText',
                'modalLoadingButtonText',
                'wantsToSubmitRentalApplicationCheckboxText',
                'errorToSubmitRentalApplicationText',
                'presentationButton',
                'createDossier',
                'noDossiers',
            ],
        }),
        require('../../form/components/mixins/withInternalValue'),
    ],
    props: {
        value: Boolean,
        hadAnErrorCreatingTheRentalApplication: Boolean,
        accountType: {
            type: String,
            default: undefined,
        },
    },
    data() {
        return {
            isGettingDossier: false,
            dossier: undefined,
            hasError: false,
            showDossierEditorModal: false,
            showPresentationModal: false,
            showVerificationModal: false,
            DocumentsErrorsByCat: [],
        };
    },
    computed: {
        dossierStatusMessage() {
            return this.getTranslationBasedOnDossierStatus(
                'completeDossierMessage',
                'incompleteDossierMessage',
                'noDossiers'
            );
        },
        dossierStatusActionMessage() {
            return this.getTranslationBasedOnDossierStatus(
                'completeDossierActionMessage',
                'incompleteDossierActionMessage',
                'createDossier'
            );
        },
        hasCompleteDossier() {
            return _.get(this.dossier, 'complete', false);
        },
        isRegistered() {
            return Account.isRegistered();
        },
        text() {
            return this.t('text', {accountType: this.accountType});
        },
        documentsCategories() {
            return this.$store.getters['rentalApplication/documentsCategories'];
        },
        uploadsFinished() {
            return this.$store.getters['rentalApplication/uploadsFinished'];
        },
        errors() {
            return this.$store.getters['rentalApplication/errors'];
        },
        isProcessing() {
            return this.$store.getters['rentalApplication/isProcessing'];
        },
        documents() {
            return this.$store.getters['rentalApplication/documents'];
        },
        documentsToUpload() {
            return this.$store.getters['rentalApplication/documentsToUpload'];
        },
        submitText() {
            if (this.isProcessing) {
                return this.modalLoadingButtonText;
            } else {
                return this.modalValidationButtonText;
            }
        },
        otherSubTypes() {
            return this.$store.getters['rentalApplication/otherSubTypes'];
        },
        stateIdSubType() {
            return this.$store.getters['rentalApplication/stateIdSubType'];
        },
        documentWithRectoVerso() {
            return this.$store.getters['rentalApplication/documentWithRectoVerso'];
        },
        isFirstTimeRentalApplication() {
            return this.dossier ? this.dossier.firstTime : true;
        },
    },
    watch: {
        isProcessing(processing) {
            if (!processing) {
                this.addRectoVersoErrors();
                this.DocumentsErrorsByCat = this.getDocumentsErrorsByCat();
                if (this.DocumentsErrorsByCat.length === 0
                    && this.documentsToUpload.length === 0) {
                    // The dossier is complete
                    this.showDossierEditorModal = false;
                }
            }
        },
    },
    mounted() {
        if (this.isRegistered) {
            this.fetchDossier();
        }
    },
    methods: {
        fetchDossier() {
            this.$store.commit('rentalApplication/reset');
            this.$store.commit('rentalApplication/resetDocuments');

            this.hasError = false;
            this.isGettingDossier = true;
            getDossier(Account.getAuthenticatedAccountId(), (err, dossier) => {
                if (err) {
                    this.hasError = true;
                } else {
                    this.dossier = dossier;
                }
                this.isGettingDossier = false;
            });
        },
        getTranslationBasedOnDossierStatus(completeDossierKey, incompleteDossierKey, createDossier) {
            const {dossier} = this;
            let message;
            if (this.isFirstTimeRentalApplication) {
                message = this.t(createDossier);
            } else if (dossier) {
                message = this.t(dossier.complete ? completeDossierKey : incompleteDossierKey);
            }
            return message;
        },
        openPresentationModal() {
            this.showPresentationModal = true;
        },
        openNextStep() {
            if (this.isFirstTimeRentalApplication) {
                this.showVerificationModal = true;
            } else {
                this.showDossierEditorModal = true;
            }
        },
        submit() {
            this.$store.commit('rentalApplication/reset');
            this.$store.commit('rentalApplication/startProcessing');
        },
        getDocumentsErrorsByCat() {
            const documentsCategoriesErrors
                = _(this.errors)
                    .groupBy('category')
                    .map((errors, category) => ({
                        category,
                        errors: _.uniq(_.map(errors, 'error')),
                    }))
                    .value();
            _.forEach(this.documentsCategories, (category) => {
                if (!_.some(this.documents, {documentType: category})) {
                    documentsCategoriesErrors.push({
                        category,
                        errors: ['unAvailable'],
                    });
                }
            });
            this.$store.commit('rentalApplication/addDocumentsCategoriesErrors', documentsCategoriesErrors);
            return documentsCategoriesErrors;
        },
        addRectoVersoErrors() {
            _.forEach(this.documents, (file) => {
                if (
                    _.includes(file.metadata.subType, this.stateIdSubType) &&
                    _.includes(this.documentWithRectoVerso, file.documentType)
                ) {
                    const subType =
                        _.replace(file.metadata.subType, `${this.stateIdSubType}_`, '');
                    const hasRectoOrVerso = _.find(this.documents, (document) => {
                        const otherSidOfDocSubType =
                            `${this.stateIdSubType}_${this.otherSubTypes[subType]}`;
                        return document.metadata.subType === otherSidOfDocSubType;
                    });
                    if (_.isEmpty(hasRectoOrVerso)) {
                        this.$store.commit(
                            'rentalApplication/addError',
                            {
                                category: this.getDocumentErrorCategory(file.fileName),
                                error: 'messingVersoOrRecto',
                                fileId: file.id,
                                oldFilename: file.oldFilename,
                            }
                        );
                    } else {
                        this.$store.commit('rentalApplication/removeError', file);
                    }
                }
            });
        },
        getDocumentErrorCategory(fileName) {
            return _.find(
                this.$store.getters['rentalApplication/documentsCategories'],
                (docCategory) => _.includes(fileName, docCategory)
            );
        },
    },
    template: template(),
};
