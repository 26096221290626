const SearchTitleGenerator = require('../../../common/SearchTitleGenerator');
const template = require('./SearchResultsCount.jade');

// @vue/component
module.exports = {
    props: {
        searchResults: {
            type: Object,
            default: null,
        },
        isFullWidth: Boolean,
    },
    computed: {
        hasResults() {
            return this.searchResults && this.searchResults.totalResults > 0;
        },
        titleKey() {
            const {isExtendedWithSearchAround} = this.searchResults;
            return isExtendedWithSearchAround ? 'resultsCountSearchAround' : 'resultsCount';
        },
        transactionTypeInSentence() {
            const {titleOptions: {filterType}} = this.searchResults;
            if (['rent', 'buy'].includes(filterType)) {
                return `${filterType}_inSentence`;
            }
            return null;
        },
        titleOptions() {
            const {titleOptions} = this.searchResults;
            return {
                ...titleOptions,
                transactionTypeInSentence: this.transactionTypeInSentence,
            };
        },
        resultCountTitle() {
            return SearchTitleGenerator.getTitle(this.titleOptions, this.titleKey);
        },
        searchResultsModifier() {
            return {halfWidth: !this.isFullWidth};
        },
        searchResultsTitleModifier() {
            return {noResults: !this.hasResults};
        },
    },
    template: template(),
};
