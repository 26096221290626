const template = require('./VerificationModal.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');
const internalValue = require('../../form/components/mixins/withInternalValue');
const {createDossier} = require('../Dossier');
const Account = require('../../authentication/Account');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'VerificationModal.',
            keys: [
                'title',
                'firstParagraph',
                'secondParagraph',
                'thirdParagraph',
                'buttonContinue',
                'buttonCancel',
            ],
        }),
        internalValue,
    ],
    props: {
        value: Boolean,
    },
    data() {
        return {
            checkedQuestion: false,
            isCreatingDossier: false,
            hasError: false,
            isSubmitted: false,
        };
    },
    computed: {
        documentList() {
            return this.t('documents', {
                returnObjectTrees: true,
            });
        },
        isCheckboxError() {
            return this.isSubmitted && !this.checkedQuestion;
        },
        checkboxModifiers() {
            return {
                error: this.isCheckboxError,
            };
        },
    },
    methods: {
        createDossier() {
            this.hasError = false;
            this.isCreatingDossier = true;
            const userAccount = Account.getAuthenticatedAccount();
            createDossier({id: userAccount.id}, (err, dossier) => {
                if (err) {
                    this.hasError = true;
                    console.error(err);
                } else {
                    this.dossier = dossier;
                }
                this.isCreatingDossier = false;
                this.internalValue = false;
                this.$emit('change');
            });
        },
        onOk() {
            this.isSubmitted = true;
            if (this.checkedQuestion) {
                this.createDossier();
            }
        },
    },
    template: template(),
};
