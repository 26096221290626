const template = require('./OverviewGallery.jade');
const OverviewMixin = require('./OverviewMixin');
const i18nMixin = require('../../../vue/components/mixins/i18n');
const ApplicationConfig = require('../../../app/ApplicationConfig');

// @vue/component
module.exports = {
    mixins: [
        OverviewMixin,
        i18nMixin({
            prefix: 'OverviewGallery.',
        }),
    ],
    props: {
        noLink: Boolean,
        displayMatchingDate: Boolean,
        displayPhotoCount: Boolean,
        displayFavorite: Boolean,
        displayDescription: Boolean,
    },
    data() {
        return {
            showDescription: false,
        };
    },
    computed: {
        overviewModifier() {
            return {
                'with-toggle-description': this.displayToggleDescriptionButton,
            };
        },
        badgesModifier() {
            return {
                highlighted: this.displayedAsHighlighted,
            };
        },
        realEstateAdLinkForGallery() {
            return (!this.noLink && this.realEstateAdLink) || '';
        },
        displayToggleDescriptionButton() {
            return Boolean(this.realEstateAd.description) &&
                this.isMobile &&
                this.displayDescription &&
                !ApplicationConfig.applicationPro;
        },
        showDescriptionState() {
            return this.showDescription ? 'hide' : 'show';
        },
        toggleDescriptionButtonIcon() {
            return this.showDescription ? 'closed' : 'open';
        },
        toggleDescriptionButtonLabel() {
            return this.translate(`OverviewGallery.${this.showDescriptionState}AdDescription`);
        },
        toggleDescriptionButtonTrackLabel() {
            return `${this.showDescriptionState}AdPreviewDescriptionInGallery`;
        },
    },
    methods: {
        toggleShowDescription() {
            this.showDescription = !this.showDescription;
        },
    },
    template: template(),
};
