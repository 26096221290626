const Account = require('../authentication/Account');
const buildUrl = require('./buildUrl');

module.exports = {
    getApplicationAsPdf,
};

function getApplicationAsPdf(applicationId, accountId, callback) {
    Account.authAjax({
        url: buildUrl(`/dossier/${accountId}/download/${applicationId}`),
        disableErrorPage: true,
        method: 'GET',
        xhrFields: {
            responseType: 'blob',
        },
        callback,
    });
}
