const template = require('./MandatoryFieldsExplanation.jade');
const i18nMixin = require('../mixins/i18n');

module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'MandatoryFieldsExplanation.',
            keys: ['explanation'],
        }),
    ],
    template: template(),
};
