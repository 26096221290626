const _ = require('lodash');
const template = require('./PhoneAndContactForm.jade');
const ContactPhoneBox = require('./ContactPhoneBox');
const FormAndSpinner = require('./mixins/FormAndSpinner');
const CommonProps = require('./mixins/CommonProps');
const i18nMixin = require('../../vue/components/mixins/i18n');
const {TrackingClasses} = require('../../stats/TrackingClasses');
const ContactFormSender = require('../../utils/ContactFormSender');
const Account = require('../../authentication/Account');
const {get: getDetailedSheetStatsContext} = require('../../stats/DetailedSheetStatsContext');
const EventPack = require('../../utils/EventPack');
const ProgrammeHelper = require('../../../common/ProgrammeHelper');

const ERROR_DISPLAY_DURATION_IN_MS = 3e3;
const ALLOWED_ASKING_FOR_FIELDS = ['photos', 'meeting', 'documentation'];

// @vue/component
module.exports = {
    components: {
        // @vue/component
        FormAndSpinner: {
            mixins: [
                FormAndSpinner,
            ],
            props: {
                openedFrom: {
                    type: String,
                    default: undefined,
                },
                askingFor: {
                    type: Array,
                    default: null,
                },
                authorId: {
                    type: String,
                    default: undefined,
                },
            },
            data() {
                return {
                    internalAskingFor: this.askingFor,
                    message: '',
                };
            },
            computed: {
                sentFrom() {
                    const sentFromOfContactForm = this.openedFrom || 'mail';
                    return this.isCompactContactForm ? 'compactContactFormAfterDescription' : sentFromOfContactForm;
                },
                isAlreadyAskingMore() {
                    return Boolean(_.find(ALLOWED_ASKING_FOR_FIELDS, field => {
                        return this.message.indexOf(this.t('moreInfo.' + field)) >= 0;
                    }));
                },
                askingForOptions() {
                    const isProgrammeOrResidenceOrLot = ProgrammeHelper.isProgrammeOrResidenceOrLot(this.realEstateAd);
                    const allowedAskingForFields = isProgrammeOrResidenceOrLot ?
                        _.without(ALLOWED_ASKING_FOR_FIELDS, 'photos') :
                        _.without(ALLOWED_ASKING_FOR_FIELDS, 'documentation');
                    return _.map(allowedAskingForFields, value => {
                        return {
                            label: this.t('askingForOptions.' + value),
                            value,
                        };
                    });
                },
                isUserMessageLengthLimited() {
                    return Boolean(this.userMessageMaxLength);
                },
            },
            watch: {
                internalAskingFor(newInternalAskingFor) {
                    if (this.message === '') {
                        this.initMessageContent(newInternalAskingFor);
                    } else if (!this.isUserMessageLengthLimited) {
                        _.each(ALLOWED_ASKING_FOR_FIELDS, askingFor => {
                            const askingForText = this.t('moreInfo.' + askingFor);
                            const foundAskingForText = this.message.indexOf(askingForText);
                            if (_.includes(newInternalAskingFor, askingFor)) {
                                if (foundAskingForText < 0) {
                                    this.addTextToMessage(askingForText);
                                }
                            } else if (foundAskingForText >= 0) {
                                this.removeTextFromMessage(askingForText);
                            }
                        });
                    }
                },
                askingFor(value) {
                    this.internalAskingFor = value;
                },
            },
            mounted() {
                if (!this.isCompactContactForm) {
                    const event = new EventPack();
                    event.on(Account, 'change', () => {
                        this.setUserAccount();
                    });
                    this.initMessageContent(this.internalAskingFor);
                }
            },
            methods: {
                sendContact(options, cb) {
                    ContactFormSender.sendAdContactAndLoadSimilarAds(options, cb);
                },
                initMessageContent(askingFor) {
                    const {
                        realEstateAd: {newProperty, programmeRef, propertyType},
                        isUserMessageLengthLimited,
                        programmePropertyMessage,
                        defaultMessage,
                        endMessage,
                        t,
                    } = this;
                    if (this.$refs.message) {
                        let content = '';
                        if (newProperty && (programmeRef || propertyType === 'programme')) {
                            content = programmePropertyMessage;
                        } else {
                            content = defaultMessage;
                        }
                        if (askingFor && askingFor.length && !isUserMessageLengthLimited) {
                            content += '\n';
                            _.each(askingFor, asking => {
                                content += t('moreInfo.' + asking) + '\n';
                            });
                        }
                        content += endMessage;
                        this.message = content;
                    }
                },
                addTextToMessage(text) {
                    const {message, endMessage} = this;
                    const position = message.indexOf(endMessage);
                    let updatedText;
                    if (position < 0) {
                        updatedText = message + '\n' + text;
                    } else {
                        if (!this.isAlreadyAskingMore) {
                            text = '\n' + text;
                        }
                        updatedText = [message.slice(0, position), text + '\n', message.slice(position)].join('');
                    }
                    this.message = updatedText;
                },
                removeTextFromMessage(text) {
                    const {message, endMessage} = this;
                    let updatedText = message.replace(new RegExp('[\n]' + _.escapeRegExp(text)), '');
                    updatedText = updatedText.replace(new RegExp('\n\n' + _.escapeRegExp(endMessage)), '\n' + endMessage);
                    this.message = updatedText;
                },
            },
        },
        ContactPhoneBox,
    },
    mixins: [
        CommonProps,
        i18nMixin({
            prefix: 'PhoneAndContactForm.',
            keys: [
                {
                    error: 'errorText',
                    success: 'successText',
                },
                'openContactFormButton',
                'successShowFormAgain',
            ],
        }),
    ],
    constants: {
        SUBMIT_BUTTON_TRACKING_CLASSES: [TrackingClasses.submitCompactContactForm],
    },
    props: {
        contactSentToPage: Boolean,
        isCompactContactForm: Boolean,
        contactFormOptions: {
            type: Object,
            default: undefined,
        },
    },
    data() {
        return {
            hasSubmitSucceeded: null,
            adAlreadyContacted: false,
        };
    },
    computed: {
        modifiers() {
            return {
                smallSizeSuccess: this.hasSubmitSucceeded,
                adAlreadyContacted: this.adAlreadyContacted,
                compact: this.isCompactContactForm,
            };
        },
        showFormAndSpinner() {
            const {adAlreadyContacted, hasSubmitSucceeded, isCompactContactForm, hasSubmitFailed} = this;
            return !(adAlreadyContacted || hasSubmitSucceeded || (!isCompactContactForm && hasSubmitFailed));
        },
        hasSubmitFailed() {
            return this.hasSubmitSucceeded === false;
        },
        authenticatedUserContactRequests() {
            return _.filter(this.realEstateAd.contactRequests, {
                sender: {
                    accountId: Account.getAuthenticatedAccountId(),
                },
            });
        },
        submitButtonTrackingClasses() {
            return this.isCompactContactForm ? [TrackingClasses.submitCompactContactForm] : undefined;
        },
        askingFor() {
            return _.get(this.contactFormOptions, 'askingFor') || [];
        },
        openedFrom() {
            return _.get(this.contactFormOptions, 'openedFrom');
        },
        contactFormSource() {
            return _.get(this.contactFormOptions, 'source');
        },
        detailedSheetData() {
            const {realEstateAd: {id, reference}} = this;
            return {
                realEstateAdId: id,
                realEstateAdReference: reference,
                statsContext: getDetailedSheetStatsContext(),
            };
        },
        displayPhoneNumber() {
            return _.get(this.contactFormOptions, 'displayPhoneNumber');
        },
        showOpenContactFormButton() {
            return !(this.isCompactContactForm || this.showFormAndSpinner || this.hasSubmitFailed);
        },
    },
    watch: {
        contactSentToPage(value) {
            if (value && !this.hasSubmitSucceeded && !this.adAlreadyContacted) {
                this.hasSubmitSucceeded = true;
            }
        },
        authenticatedUserContactRequests(newAuthenticatedUserContactRequests) {
            this.adAlreadyContacted = !_.isEmpty(newAuthenticatedUserContactRequests);
        },
    },
    mounted() {
        this.adAlreadyContacted = !_.isEmpty(this.authenticatedUserContactRequests);
    },
    methods: {
        onError() {
            this.hasSubmitSucceeded = false;
            if (this.isCompactContactForm) {
                setTimeout(() => {
                    this.hasSubmitSucceeded = null;
                }, ERROR_DISPLAY_DURATION_IN_MS);
            }
        },
        onSuccess() {
            this.hasSubmitSucceeded = true;
            this.adAlreadyContacted = true;
        },
        showContactForm() {
            this.hasSubmitSucceeded = null;
            this.adAlreadyContacted = false;
        },
    },
    template: template(),
};
