const i18nMixin = require('../../vue/components/mixins/i18n');
const template = require('./CreditSimulationCompactLink.jade');
const {canShowFinancialPartnerCreditSimulationLinks, openFinancialPartnerTab} = require('../../financialPartners');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'creditSimulation.',
            keys: [
                'homePageButtonMessage',
            ],
        }),
    ],
    computed: {
        visible() {
            return canShowFinancialPartnerCreditSimulationLinks();
        },
    },
    methods: {
        openFinancialPartnerTab() {
            return openFinancialPartnerTab({context: 'homePage'});
        },
    },
    template: template(),
};
