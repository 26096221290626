const template = require('./DocumentEditorDescription.jade');

// @vue/component
module.exports = {
    props: {
        introduction: {
            type: String,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
    },
    template: template(),
};
