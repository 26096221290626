module.exports = {
    'account-id': 'md-vpn-key',
    'add': 'fa-plus',
    'alert': 'md-notifications-on',
    'alert-neutral': 'md-notifications',
    'alert-off': 'md-notifications-none',
    'back': 'md-chevron-left',
    'block': 'md-pause',
    'boost': 'md-send',
    'calendar': 'fa-calendar',
    'cancel': 'md-cancel',
    'check': 'fa-check',
    'check-thin': 'md-check',
    'close': 'md-close',
    'closed': 'fa-angle-up',
    'contact-type-account-contacts': 'md-book',
    'contact-type-ad-submission': 'md-home',
    'contact-type-call': 'md-phone-in-talk',
    'contact-type-call-missed': 'md-phone-missed',
    'contact-type-contact-request': 'md-mail',
    'contact-type-estimation-request': 'md-mail',
    'contact-us-email': 'fa-paper-plane',
    'contact-us-form': 'fa-comment-o',
    'contact-us-phone': 'fa-phone',
    'done': 'md-done',
    'edit': 'fa-pencil',
    'email': 'md-email',
    'error': 'md-error',
    'favorite': 'md-favorite',
    'favorite-off': 'md-favorite-outline',
    'highlighted': 'fa-heart',
    'highlighted-off': 'fa-heart-o',
    'import': 'fa-cloud-download',
    'info': 'md-info',
    'interrupt': 'md-replay',
    'invalidate': 'md-block',
    'leading': 'fa-star',
    'leading-off': 'fa-star-o',
    'mail': 'md-mail',
    'notification-asap': 'md-alarm',
    'notification-day': 'md-event-available',
    'notification-week': 'md-event',
    'online-booking': 'md-shopping-cart',
    'open': 'fa-angle-down',
    'phone': 'md-phone',
    'photo': 'fa-camera',
    'publication-certificate': 'fa-upload',
    'published': 'fa-eye',
    'retry': 'md-autorenew',
    'share': 'fa-share-square-o',
    'spinner': ['md-rotate-right', 'md-spin'],
    'statistics': 'md-dashboard',
    'warning': 'md-warning',
    'website': 'fa-globe',
};
