const _ = require('lodash');
const Entities = require('html-entities').AllHtmlEntities;

const ProgrammeHelper = require('./ProgrammeHelper');
const PriceFormatter = require('./PriceFormatter');
const TextFiltersFormatter = require('./TextFiltersFormatter');
const {cityNameToInTheCity} = require('@bienici/city-name-formatter');
const BaseTitleGenerator = require('./BaseTitleGenerator');
const {singleBuilt} = require('./PropertyTypes');

let {i18n: {translate}} = require('fack');

module.exports = {
    init,
    getTitle,
};

function init({t}) {
    translate = t;
}

function getRoomsQuantityText(realEstateAd, translationContext) {
    const {roomsQuantity, isStudio} = realEstateAd;
    return isStudio ? null : TextFiltersFormatter.formatQuantity(roomsQuantity, 'room', {translationContext});
}

function getSurfaceAreaText({surfaceArea, landSurfaceArea, propertyType}) {
    return TextFiltersFormatter.formatSurfaceArea(surfaceArea, landSurfaceArea, propertyType);
}

function getTitle(realEstateAd, titleKey) {
    const roomsQtyText = getRoomsQuantityText(realEstateAd, titleKey);
    const surfaceText = getSurfaceAreaText(realEstateAd);
    const singleSurfaceText = hasSingleSurface(realEstateAd) ? surfaceText : null;

    let roomsQtyAndSurfaceText;
    if (roomsQtyText || surfaceText) {
        roomsQtyAndSurfaceText = 'de ' + _.compact([roomsQtyText, surfaceText]).join(' et ');
    }

    //TODO get city from import (not from ad : it can be : "Marseille (chateau Gombert)")
    const cityOrPostalCode = realEstateAd.city || realEstateAd.postalCode;
    const inCityText = cityNameToInTheCity(realEstateAd.city);
    const hasDistrictInfo = _.get(realEstateAd, 'district.type_id') === 1;

    let context;
    let programmeSolePropertyTypeText;
    if (ProgrammeHelper.isProgrammeOrResidence(realEstateAd)) {
        context = 'programmeOrResidence';
    } else if (realEstateAd.isPropertyWithoutUsufruct) {
        context = 'propertyWithoutUsufruct';
    } else {
        context = realEstateAd.adType;
    }

    if (ProgrammeHelper.isProgrammeWithSolePropertyType(realEstateAd)) {
        const firstPropertyType = realEstateAd.relatedAdsIds[0].propertyType;
        programmeSolePropertyTypeText = setTextForProgrammesWithSolePropertyType(realEstateAd, firstPropertyType);
    }

    let lotsQuantityText;
    let ofLotsQuantityText;
    if (ProgrammeHelper.isProgramme(realEstateAd) && realEstateAd.condominiumPartsQuantity) {
        lotsQuantityText = translate('titleParts.lotsQuantityText', {
            count: realEstateAd.condominiumPartsQuantity,
            context: realEstateAd.newProperty ? 'newProperty' : '',
        });
        ofLotsQuantityText = `de ${lotsQuantityText}`;
    }

    const translationKeys = ['adTitle.' + titleKey];
    const title = realEstateAd.title && Entities.decode(realEstateAd.title);

    let programmeReplacementAdOverviewTitle;
    if (ProgrammeHelper.isProgrammeWithoutTitle(realEstateAd)) {
        programmeReplacementAdOverviewTitle = 'Programme immobilier neuf';
    }

    if (ProgrammeHelper.isProgrammeOrResidence(realEstateAd) && titleContainsPropertyType(title, realEstateAd.propertyType)) {
        translationKeys.unshift(`adTitle.${titleKey}_titleContainsPropertyType`);
    }

    return BaseTitleGenerator.translateAndCleanup(translationKeys, _.extend(
        _.pick(realEstateAd, [
            'newProperty',
            'transactionType',
            'adType',
            'isFurnished',
            'address',
            'propertyType',
            'isStudio',
            'isDuplex',
            'isTriplex',
            'relatedAdsIds',
        ]),
        {
            title,
            programmeReplacementAdOverviewTitle,
            roomsQtyText,
            surfaceText,
            singleSurfaceText,
            roomsQtyAndSurfaceText,
            lotsQuantityText,
            ofLotsQuantityText,
            cityOrPostalCode,
            inCityText,
            district: _.get(realEstateAd, 'district.libelle'),
            hasDistrictInfo,
            priceText: PriceFormatter.formatForHtml(realEstateAd.price),
            context,
            programmeSolePropertyTypeText,
        }
    ));
}

function hasSingleSurface({surfaceArea, landSurfaceArea, propertyType}) {
    return !TextFiltersFormatter.isSurfaceRange(surfaceArea, landSurfaceArea, propertyType);
}

function titleContainsPropertyType(title, propertyType) {
    const propertyTypeText = _.deburr(translate(`enum.propertyType.${propertyType}`));
    const valuesToTest = _([title, propertyTypeText])
        .map(_.deburr)
        .map(_.toLower)
        .value();
    return _.includes(...valuesToTest);
}

function hasOnlyOneRelatedLot({relatedAdsIds}) {
    const {isLotType, availableLotQuantity} = relatedAdsIds[0];
    return relatedAdsIds.length <= 1
        && (!isLotType || availableLotQuantity === 1);
}

function setTextForProgrammesWithSolePropertyType(realEstateAd, firstPropertyType) {
    if (shouldDisplayRelatedAdTitle(firstPropertyType)) {
        return translate('titleParts.propertyTypeWithAdjectives', {
            propertyType: firstPropertyType,
            count: hasOnlyOneRelatedLot(realEstateAd) ? 1 : 2,
            newProperty: true,
        });
    }
}

function shouldDisplayRelatedAdTitle(adPropertyType) {
    // "unknown" est actuellement inclus dans toutes les catégories de biens (PropertyTypes.js)
    return _.includes(singleBuilt, adPropertyType) && adPropertyType !== 'unknown';
}
