const _ = require('lodash');

const template = require('./ReportAdForm.jade');
const Account = require('../../authentication/Account');
const Views = require('../../views/Views');
const sendFormContactMailRequest = require('../../formContact/sendFormContactMailRequest');
const i18nMixin = require('../../vue/components/mixins/i18n');

const FORM_TYPE = 'reportAd';
const OPTIONAL = 'optional';
const MANDATORY = 'mandatory';
const REASONS_KEY_ROOT = 'reasons';
const MESSAGE_FIELD_CONFIGURATIONS = {
    wrongLocation: {
        neighborhood: OPTIONAL,
        city: OPTIONAL,
    },
    errorInAd: {
        taggedAsOld: OPTIONAL,
        rentControl: OPTIONAL,
        energyDiagnostic: OPTIONAL,
        description: MANDATORY,
    },
    other: MANDATORY,
};
const ERROR_IN_AD_REASON = 'errorInAd';
const TAGGED_AS_OLD_SUB_REASON = 'taggedAsOld';

// @vue/component
module.exports = {
    mixins: [
        require('../../fields/vue/validationMixin')(),
        i18nMixin({
            prefix: 'ReportAdForm.',
            keys: [
                'emailFieldLabel',
                'messageLabel',
                'messagePlaceholder',
                'reasonLabel',
                'reasonPlaceholder',
                'submitButton',
                'anotherProblemComment',
                'mandatoryFields',
            ],
        }),
    ],
    props: {
        realEstateAd: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            isSubmitting: false,
            formData: {
                reason: null,
                subReason: null,
                messageField: null,
                mailField: getUserEmail(),
            },
        };
    },
    computed: {
        agencyId() {
            return _.first(_.get(this.realEstateAd, 'userRelativeData.accountIds'));
        },
        reasonsConfiguration() {
            return this.t(REASONS_KEY_ROOT, {returnObjectTrees: true});
        },
        reasonOptions() {
            const reasonsKey = _.keys(this.reasonsConfiguration);
            return _.map(reasonsKey, value => {
                return {
                    label: this.getLabel(value),
                    value,
                };
            });
        },
        hasSubReasonOptions() {
            return !_.isEmpty(this.subReasonOptions);
        },
        subReasonLabel() {
            return this.getLabel(`${this.formData.reason}.subReasons`);
        },
        subReasonOptions() {
            const subReasons = _.omit(
                _.get(this.reasonsConfiguration, [this.formData.reason, 'subReasons']),
                this.fieldsToOmitForSubReasonOptions
            );
            return _.map(subReasons, (label, key) => {
                return {
                    label,
                    value: key,
                };
            });
        },
        fieldsToOmitForSubReasonOptions() {
            const fieldsToOmit = ['label'];
            const {formData, realEstateAd} = this;
            if (formData.reason === ERROR_IN_AD_REASON && realEstateAd.newProperty) {
                fieldsToOmit.push(TAGGED_AS_OLD_SUB_REASON);
            }
            return fieldsToOmit;
        },
        messageFieldConfigurationKey() {
            const {reason, subReason} = this.formData;
            return this.hasSubReasonOptions ? `${reason}.${subReason}` : reason;
        },
        messageFieldConfiguration() {
            return _.get(MESSAGE_FIELD_CONFIGURATIONS, this.messageFieldConfigurationKey);
        },
        hasMessageField() {
            return this.hasMandatoryMessageField || this.messageFieldConfiguration === OPTIONAL;
        },
        hasMandatoryMessageField() {
            return this.messageFieldConfiguration === MANDATORY;
        },
        isReasonOther() {
            return this.formData.reason === 'other';
        },
    },
    methods: {
        submit() {
            if (!this.isSubmitting && this.validateForm()) {
                this.isSubmitting = true;
                const {formData, realEstateAd: {id: realEstateAdId}, agencyId} = this;
                const {reason, subReason} = formData;
                const recipientMailKey = this.hasSubReasonOptions ? `${reason}.${subReason}` : reason;
                const subjectTranslationKey = this.hasSubReasonOptions ? `${reason}.subReasons.${subReason}` : `${reason}.label`;
                sendFormContactMailRequest({
                    formType: FORM_TYPE,
                    values: _.extend(_.pick(formData, ['mailField', 'messageField']), {
                        realEstateAdId,
                        agencyId,
                        advertiserAccountType: this.realEstateAd.accountType,
                    }),
                    recipientMailKey,
                    subjectTranslationKey,
                    pageContext: 'public',
                }, (err, data) => {
                    if (!err && data && data.success) {
                        Views.volatileFeedback.showSuccess('ReportAdForm.volatileFeedback.success');
                        this.closeModal();
                    } else {
                        Views.volatileFeedback.showError('ReportAdForm.volatileFeedback.error');
                    }
                    this.isSubmitting = false;
                });
            }
        },
        closeModal() {
            this.$emit('close');
        },
        getLabel(key) {
            const {accountType} = this.realEstateAd;
            return _.upperFirst(this.t(`${REASONS_KEY_ROOT}.${key}.label`, {accountType}));
        },
    },
    template: template(),
};

function getUserEmail() {
    const account = Account.getAuthenticatedAccount();
    return _.get(account, 'email');
}
